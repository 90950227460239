import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import logo from "../../components/pages/logo.png";
import { RowAlignedContainer } from "../ui/BaseComponents";
import { SmallImageContainer, SmallProfileImg } from "../ui/BaseComponents";

interface MenteeData {
  first_name: string;
  last_name: string;
  profile_image?: string;
}

export default function MenteeStateConnection(props: { mentee_id: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MenteeData>();

  const handleFetchMenteeStateConnection = async (id: number) => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);

      setData(data);
      console.log(JSON.stringify(data, null, 2));
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    handleFetchMenteeStateConnection(props.mentee_id);
  }, []);

  const handleClick = (userId) => {
    window.location.href = `/profile/${userId}`;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <RowAlignedContainer style={{ cursor: "pointer"}} onClick={() => {
        handleClick(props.mentee_id);
      }}>
      {" "}
      <SmallImageContainer>
        <SmallProfileImg src={data?.profile_image ?? logo} alt="frontend-app" />
      </SmallImageContainer>
      <div style={{ alignContent: "center" }}>
        <p style={{ fontWeight: '500'}}>
          {data?.first_name} {data?.last_name}
        </p>
      </div>
    </RowAlignedContainer>
  );
}
