import axios from "axios";

/***
 * Get interests for a user
 */
const getUserInterests = async (userId: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/user/interest/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

/***
 * Create an interests for a user
 */
const postUserInterests = async (userId: string, name: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/user/interest`,
    {
      method: "POST",
      data: { name, user_id: userId },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

/***
 * Remove an interests for a user
 */
const deleteUserInterests = async (id: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/user/interest/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export { getUserInterests, postUserInterests, deleteUserInterests };
