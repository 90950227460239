/**
 * Title Component
 *
 * created by Ty D'Angelo 9/9/2023
 */

import React from "react";
import { DescriptionProps } from "./interfaces";

export default function Description(p: DescriptionProps) {
  return (
    <div
      style={{
        fontSize: p.fontSize,
        marginTop: ".75em"
      }}
    >
      {p.message}
    </div>
  );
}
