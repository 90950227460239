import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default function ConsentForm({ updateFormData, setKey }) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <Form>
        <h3 style={{ margin: "30px 0px", textAlign: "center" }}>
          Mentoring Partnership Agreement
        </h3>
        <h5>
          To ensure that our relationship is a mutually rewarding and satisfying
          experience for both of us, we agree to:
        </h5>
        <div style={{ margin: "30px 30px" }}>
          <h4 style={{ margin: "50px 0px" }}>
            1. Meet regularly. Our specific schedule of contact and meetings,
            including additional meetings.
          </h4>
          <div style={{ margin: "50px 0px" }}>
            <h4 style={{ margin: "30px 0px" }}>
              2. Look for multiple opportunities and experiences to enhance the
              mentee’s learning.
            </h4>
            <h5>
              We have identified and will commit to the following specific
              opportunities and venues for learning:
            </h5>
          </div>
          <div style={{ margin: "50px 0px" }}>
            <h4 style={{ margin: "30px 0px" }}>
              3. Maintain confidentiality of our relationship.
            </h4>
            <h5>
              Confidentiality for us means that what we discuss remains between
              us. The mentor and mentee will agree ahead of time if specific
              information is to be shared with anyone else.
            </h5>
          </div>
          <div style={{ margin: "50px 0px" }}>
            <h4 style={{ margin: "30px 0px" }}>
              4. Provide regular feedback to each other and evaluate progress.
              We will accomplish this by:
            </h4>
            <h5 style={{ margin: "30px 0px" }}>
              Reviewing career goals regularly, discussing progress, and
              checking in with each other to ensure our individual needs are met
              in the relationship and periodically after that.
            </h5>
            <h5>
              We agree to meet regularly until we have accomplished our
              predefined goals or for a minimum of twelve months. At the end of
              this period, we will review this agreement, evaluate our progress,
              and determine the continuance of the relationship.
            </h5>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <Button
              style={{
                width: "10em",
                height: "3.75em",
                backgroundColor: "#FFFFFF",
                color: "#2091F9",
              }}
              onClick={() => setKey("school")}
            >
              Back
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
              marginLeft: "auto",
            }}
          >
            <div>
              <input
                type='checkbox'
                id='consent_check'
                name='consent_check'
                defaultChecked={isChecked}
                style={{ marginRight: 10 }}
                onClick={() => {
                  setIsChecked(!isChecked);
                }}
              />
              <label
                htmlFor='consent_check'
                style={{ marginRight: 10, fontSize: 20 }}
              >
                I agree and consent to the message above
              </label>
            </div>
            <div>
              <Button
                style={{
                  width: "10em",
                  height: "3.75em",
                  backgroundColor: "#2091F9",
                }}
                onClick={updateFormData}
                disabled={!isChecked}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
