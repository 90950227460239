import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import { Container, Row } from "react-bootstrap";
import logo from "../navbar/logo.png";
import logo2 from "../navbar/main-logo.png";
import { deleteCookie } from "../../utils/cookies";

const NavbarLink = ({ page, selected }) => {
  const title = (page.charAt(0).toUpperCase() + page.slice(1)) as string;

  return (
    <NavLink style={{ color: "black" }} to={`/${page}`}>
      {title}
    </NavLink>
  );
};

export function NavbarAnalytics() {
  return (
    <StyledDashboardNavbar>
      <Container fluid>
        <a
          href="/"
          style={{
            padding: "0.1875em",
            marginLeft: 100,
          }}
        >
          <img src={logo2} width="125" height="70" alt="frontend-app" />
        </a>

        <NavbarDiv className="header"></NavbarDiv>
      </Container>
    </StyledDashboardNavbar>
  );
}

// Styled Components
const NavbarDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const NavLink = styled(Link)`
  color: #f4f4f4;
  font-weight: 900;
  text-decoration: none;
  padding: 0 5px;
`;

const StyledNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  // width: 100%;
  background-color: red;
`;

const StyledDashboardNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #eee;
  height: 80px;
  box-shadow: -1px 1px 3px 3px #aaa;
  -webkit-box-shadow: -1px 1px 3px 3px #aaa;
  -moz-box-shadow: -1px 1px 3px 3px #aaa;
`;
