/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  ColumnAlignedContainer,
  SpaceBetweenContainer,
} from "../ui/BaseComponents";
import Title from "../ui/title/Title";
import { useData } from "../utils/context/DataContext";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import {
  resetPasswordInit,
  resetPasswordWithConfirmation,
} from "../../apis/User";
import styled from "styled-components";
import logo from "./main-logo.png";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [hasToken, setHasToken] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState<null | { message: string }>(null);
  const [success, setSuccess] = useState<null | { message: string }>(null);

  const handlePasswordResetInit = async () => {
    setError(null);
    if (email) {
      try {
        setIsLoading(true);
        const data = await resetPasswordInit(email);

        setHasToken(true);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (isAxiosError(e)) {
          const error: AxiosError = e;

          if (error.response?.status === 404) {
            setError({ message: "User Not Found" });
          } else if (error.response?.status === 500) {
            setError({ message: "server issue, try again later" });
          } else {
            setError({ message: "network error" });
          }
        }
      }
    }
  };

  const handlePasswordReset = async () => {
    setError(null);
    if (email && password && token) {
      try {
        setIsLoading(true);
        const data = await resetPasswordWithConfirmation(
          password,
          email,
          token
        );

        setIsLoading(false);
        setSuccess({ message: "Password Successfully Reset" });
        setDone(true);
      } catch (e) {
        setIsLoading(false);
        if (isAxiosError(e)) {
          const error: AxiosError = e;
          if (error.response?.status === 404) {
            setError({ message: "User Not Found" });
          } else if (error.response?.status === 500) {
            setError({ message: "server issue, try again later" });
          } else if (error.response?.status === 409) {
            setError({ message: "Invalid user token" });
          } else {
            setError({ message: "network error" });
          }
        }
      }
    }
  };

  return (
    <PageContainer>
      <Card
        className='main-card'
        style={{
          marginTop: "10em",
          overflow: "visible",
          borderRadius: "5%",
          border: "none",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
      >
        <Card.Body>
          <div style={{ textAlign: "center", marginBottom: "50px" }}>
            <img
              style={{ marginTop: 10 }}
              src={logo}
              width={"25%"}
              height={"25%"}
            />
          </div>
          <SpaceBetweenContainer>
            <Card.Title style={{ marginLeft: "1em" }}>
              <h3>Complete the form below to reset your password</h3>
            </Card.Title>
            <ColumnAlignedContainer style={{ marginRight: "1em" }}>
              <Card.Subtitle
                className='mb-2 text-muted'
                style={{
                  marginTop: "1em",
                  color: "#8D8D8D",
                  fontWeight: "400",
                  fontSize: "0.8125em",
                }}
              >
                <Card.Link href='/'>Bronco Mentor</Card.Link>
              </Card.Subtitle>
            </ColumnAlignedContainer>
          </SpaceBetweenContainer>

          <>
            <Form>
              <Col lg={12} style={{ marginTop: "2em" }}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label
                    style={{
                      fontSize: "1em",
                      fontWeight: "400",
                      lineHeight: "1.5em",
                    }}
                  >
                    Enter the email address used to create your account
                  </Form.Label>
                  <Form.Control
                    type='email'
                    name='email'
                    disabled={hasToken}
                    placeholder='email address'
                    style={{ height: "3.5em" }}
                    spellCheck={false}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {hasToken && (
                    <>
                      <Form.Label
                        style={{
                          fontSize: "1.5em",
                          fontWeight: "400",
                          lineHeight: "1.5em",
                          marginTop: "15px",
                        }}
                      >
                        Use the confirmation code provided via email
                      </Form.Label>
                      <br />
                      <Form.Label
                        style={{
                          fontSize: "1em",
                          fontWeight: "400",
                          lineHeight: "1.5em",
                        }}
                      >
                        Confirmation Code
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='token'
                        disabled={done}
                        style={{ height: "3.5em" }}
                        spellCheck={false}
                        onChange={(e) => {
                          setToken(e.target.value);
                        }}
                      />
                      <Form.Label
                        style={{
                          fontSize: "1em",
                          fontWeight: "400",
                          lineHeight: "1.5em",
                        }}
                      >
                        New Password
                      </Form.Label>
                      <Form.Control
                        name='password'
                        type='text'
                        disabled={done}
                        style={{ height: "3.5em" }}
                        spellCheck={false}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                    </>
                  )}
                </Form.Group>
              </Col>
              {success && (
                <Col
                  lg={12}
                  style={{ marginTop: "3.5em", textAlign: "center" }}
                >
                  <Form.Group className='mb-3'>
                    <Form.Label
                      style={{
                        fontSize: "1em",
                        fontWeight: "700",
                        lineHeight: "1.5em",
                        color: "Green",
                      }}
                    >
                      {success?.message}
                      <br />
                      Go <Link to={"/"}>home</Link> to Sign in
                    </Form.Label>
                  </Form.Group>
                </Col>
              )}

              <Col lg={12} style={{ marginTop: "3.5em", textAlign: "center" }}>
                <Form.Group className='mb-3'>
                  <Form.Label
                    style={{
                      fontSize: "1em",
                      fontWeight: "400",
                      lineHeight: "1.5em",
                      color: "red",
                    }}
                  >
                    {error?.message}
                    {isLoading && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div className='spinner-grow text-primary'></div>
                        <div className='spinner-grow text-primary'></div>
                        <div className='spinner-grow text-primary'></div>
                      </div>
                    )}
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col lg={12} style={{ marginTop: "1em", textAlign: "right" }}>
                {!hasToken && (
                  <Button onClick={handlePasswordResetInit}>
                    Send Verification Token
                  </Button>
                )}
                {hasToken && (
                  <Button onClick={handlePasswordReset}>Reset Password</Button>
                )}
              </Col>
            </Form>
          </>
        </Card.Body>
      </Card>
    </PageContainer>
  );
}

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;
