/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import logo from "./logo.png";
import { MentorCardProps, UserData } from "./interfaces";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useData } from "../utils/context/DataContext";
import axios, { AxiosError } from "axios";
import {
  DisplayClassification,
  DisplayClassificationText,
} from "../Common/DisplayClassification";
import { getCookie, setCookie } from "../utils/cookies";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const ConnectButton = ({
  connectionObject,
  mentee_id,
  mentor_id,
  setConnectionObject,
}: {
  connectionObject: {
    connection_request_id: string | null;
    status: string | null;
  };
  mentee_id: string;
  mentor_id: string;
  setConnectionObject: Function;
}) => {
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });

  const handleConnectionRequest = async (): Promise<{
    id: string;
    mentor_id: string;
    mentee_id: string;
    created_date: Date;
    last_edited_date: Date;
    status: string;
  } | null> => {
    try {
      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest`,
        {
          method: "POST",
          data: { mentor_id, mentee_id },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return connectionPostData;
      // setConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
      return null;
    }
  };

  const deleteConnectionRequest = async (id: string) => {
    try {
      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // setConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  const handleClick = async (add = true, id) => {
    if (!add && id) {
      const answer = confirm(
        "Are you sure you want to remove this connection?"
      );
      if (answer) {
        setConnectionObject({ status: null, connection_request_id: null });
        deleteConnectionRequest(id);
      }
      return;
    } else {
      setConnectionObject({ status: "REQUESTED", connection_request_id: null });
      const requestData = await handleConnectionRequest();
      setConnectionObject({
        ...connectionObject,
        status: requestData?.status,
        connection_request_id: requestData?.id,
      });
    }
  };

  if (!connectionObject.connection_request_id) {
    return (
      <Button
        onClick={() => {
          handleClick(false, null);
        }}
        style={{ backgroundColor: "rgba(55, 133, 191, 0.94)" }}
      >
        Request Connection
      </Button>
    );
  }

  if (connectionObject.status === "DENIED") {
    return (
      <Button variant="outline-danger" disabled>
        Connection Denied
      </Button>
    );
  }

  if (connectionObject.status === "ACCEPTED") {
    return (
      <div>
        <Button
          variant="outline-warning"
          className="text-dark"
          onClick={() => {
            handleClick(false, connectionObject.connection_request_id ?? "");
          }}
        >
          Disconnect
        </Button>
      </div>
    );
  }

  if (
    connectionObject.status === "REQUESTED" &&
    connectionObject.connection_request_id
  ) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="text-success mr-2">Connection Requested</span>
        <Button
          onClick={() => {
            handleClick(false, connectionObject.connection_request_id);
          }}
          variant="outline-danger"
        >
          undo
        </Button>
      </div>
    );
  }
};

const ApprovalButton = ({ mentorData }: { mentorData: UserData }) => {
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });
  const [approved, setApproved] = useState(mentorData.approved);

  const approveAccount = async (id: string, status: boolean) => {
    try {
      setApproved(status);
      const { data } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${id}`,
        {
          method: "PATCH",
          data: {
            approved: status.toString(),
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  if (approved) {
    return (
      <>
        <span className="text-dark mr-2">Approved</span>
        <Button
          onClick={() => {
            approveAccount(mentorData.id, false);
          }}
          variant="danger"
        >
          undo
        </Button>
      </>
    );
  }

  return (
    <Button
      onClick={() => {
        approveAccount(mentorData.id, true);
      }}
      variant="warning"
    >
      Approve
    </Button>
  );
};

export default function MentorCard({ mentorData, isAdmin }: MentorCardProps) {
  const { data } = useData();
  const handleClick = () => {
    window.location.href = `/profile/${mentorData.id}`;
  };

  const [fetchedImageUrl, setFetchedImageUrl] = useState("");

  const [showMoreBio, setShowMoreBio] = useState(false);

  const [connectionObject, setConnectionObject] = useState({
    status: mentorData.status,
    connection_request_id: mentorData.connection_request_id,
  });

  const truncateText = (text, maxLength) => {
    if (text) {
      return text.length <= maxLength
        ? text
        : text.slice(0, maxLength - 3) + "...";
    }
    return text;
  };

  const getImageUrl = async () => {
    try {
      const imagesCached = await getCookie("cache-images");
      if (imagesCached) {
        setFetchedImageUrl(mentorData.profile_image);
      } else {
        const expirationTime = new Date(Date.now() + 30 * 60 * 1000); // 30 minutes from now
        await setCookie("cache-images", "cached", expirationTime);
        setFetchedImageUrl(`${mentorData.profile_image}?${Math.random()}`);
      }
    } catch (e) {
      console.log("cache-images error");
      console.log(e);
      setFetchedImageUrl(`${mentorData.profile_image}?${Math.random()}}`);
    }
  };

  useEffect(() => {
    getImageUrl();
  }, []);

  return (
    <div style={{ overflowX: "hidden", padding: "0px 0px" }}>
      <Container>
        <Row style={{ margin: "0px -20px" }}>
          <Col md={12}>
            <StyledRow>
              <ImageCol sm={3}>
                <ImageContainer onClick={handleClick}>
                  {mentorData.profile_image ? (
                    <ProfileImg
                      src={fetchedImageUrl || mentorData.profile_image}
                    />
                  ) : (
                    <img
                      style={{ marginTop: 10 }}
                      src={logo}
                      width={"75%"}
                      height={"75%"}
                    />
                  )}
                </ImageContainer>
                <div style={{ maxWidth: 251, height: 90 }}>
                  <p style={{ fontSize: "1em", marginTop: 10 }}>
                    {truncateText(DisplayClassificationText(mentorData), 80)}
                  </p>
                </div>
              </ImageCol>
              <ContentCol sm={isAdmin && mentorData.approved ? 7 : 9}>
                {/* Dashboard intro */}
                <div className="ml-2 pt-4" style={{ fontWeight: 700 }}>
                  <HeaderTitle
                    onClick={handleClick}
                  >{`${mentorData.first_name} ${mentorData.last_name}`}</HeaderTitle>
                  <div
                    style={{
                      borderStyle: "solid",
                      backgroundColor: "white",
                      borderWidth: 1,
                      padding: 10,
                      borderRadius: 9,
                    }}
                  >
                    {/* <StyledBio
                      onClick={() => {
                        setShowMoreBio(!showMoreBio);
                      }}
                    >
                      {mentorData.bio
                        ? showMoreBio
                          ? mentorData.bio
                          : truncateText(mentorData.bio, 230)
                        : "No bio available."}
                    </StyledBio> */}
                    <StyledBio> 
                      {mentorData.blurb
                        ? mentorData.blurb
                        : DisplayClassificationText(mentorData)}
                    </StyledBio>
                  </div>

                  <div>
                    <div
                      style={{
                        borderStyle: "solid",
                        backgroundColor: "white",
                        borderWidth: 1,
                        // overflow: "hidden",
                        padding: 10,
                        borderRadius: 9,
                        marginTop: 20,
                      }}
                    >
                      <StyledBio
                        onClick={() => {
                          setShowMoreBio(!showMoreBio);
                        }}
                      >
                        {mentorData.bio
                          ? showMoreBio
                            ? mentorData.bio
                            : truncateText(mentorData.bio, 250)
                          : "No bio available."}
                      </StyledBio>
                    </div>
                    {mentorData?.bio && mentorData?.bio.length > 250 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <StyledMoreButton
                          onClick={() => {
                            setShowMoreBio(!showMoreBio);
                          }}
                        >
                          {showMoreBio ? "Hide" : "More"}
                        </StyledMoreButton>
                      </div>
                    )}
                  </div>
                  
                  {/* <div>
                    <StyledBio
                      onClick={() => {
                        setShowMoreBio(!showMoreBio);
                      }}
                    >
                      {mentorData.bio
                        ? showMoreBio
                          ? mentorData.bio
                          : truncateText(mentorData.bio, 230)
                        : "No bio available."}
                    </StyledBio>
                  </div> */}
               </div>
               <div className='mt-auto'>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        margin: "10px 0px",
                        marginLeft: "auto",
                        marginRight: 20,
                        borderRight:
                          mentorData?.military ||
                          mentorData?.first_generation ||
                          mentorData?.adult_learner
                            ? "2px solid rgba(55, 133, 191, 0.94)"
                            : "",
                      }}
                    >
                      {mentorData?.state && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            borderColor: "rgb(0, 119, 181)",
                            color: "rgb(0, 119, 181)",
                            backgroundColor: "white",
                            padding: 3,
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{mentorData.state}</h6>
                        </Button>
                      )}
                      {mentorData?.military && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            backgroundColor: "#ff7701",
                            padding: 3,
                            color: "white",
                            borderColor: "#eee",
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{`Military Affiliate`}</h6>
                        </Button>
                      )}
                      {mentorData?.first_generation && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            backgroundColor: "#eee",
                            color: "#0077b5",
                            padding: 5,
                          }}
                        >
                          <h6
                            style={{ margin: 0 }}
                          >{`First Generation Student`}</h6>
                        </Button>
                      )}
                      {mentorData?.adult_learner && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            color: "white",
                            backgroundColor: "#0077b5",
                            borderColor: "#0077b5",
                            padding: 5,
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{`21+`}</h6>
                        </Button>
                      )}
                    </div>

                    {isAdmin ? (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginLeft: "auto",
                          margin: "10px 0px",
                        }}
                      >
                        <ApprovalButton mentorData={mentorData} />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginLeft: "auto",
                          margin: "10px 0px",
                          height: 40,
                        }}
                      >
                        <ConnectButton
                          connectionObject={connectionObject}
                          mentee_id={data?.id}
                          mentor_id={mentorData.id}
                          setConnectionObject={setConnectionObject}
                        />
                      </div>
                    )}
                  </div>
                </div>
              
              </ContentCol>
              {isAdmin && mentorData.approved && mentorData.completed_count && (
                <ContentCol sm={2}>
                  <StyledTable>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableHeaderStyle>
                            Sessions Completed
                          </TableHeaderStyle>
                        </TableCell>
                        <TableCell>
                          <TableHeaderStyle>
                            Scheduled Sessions
                          </TableHeaderStyle>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <TableDataStyle>
                            {mentorData.completed_count}
                          </TableDataStyle>
                        </TableCell>
                        <TableCell>
                          <TableDataStyle>
                            {mentorData.accepted_count}
                          </TableDataStyle>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </StyledTable>
                </ContentCol>
              )}
            </StyledRow>
          </Col> 
        </Row>
      </Container>
    </div>
  );
}

// Styled Components

const StyledContainer = styled(Container)`
  margin: 50px 0px;
  padding: 50px;
  border-style: solid;
  border-color: #333;
  border-radius: 9px;
  background-color: #eee;
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
  /* Increase box shadow on hover */
  &:hover {
    box-shadow: -1px 2px 4px 4px #aaa;
    -webkit-box-shadow: -1px 2px 4px 4px #aaa;
    -moz-box-shadow: -1px 2px 4px 4px #aaa;
  }
`;
const StyledRow = styled(Row)`
  margin: 20px 0px;
  padding: 10px;
  border-style: solid;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 92%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
  /* Increase box shadow on hover */
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  &:hover {
    border-width: 2.2px;
    box-shadow: -1px 2px 4px 4px #aaa;
    -webkit-box-shadow: -1px 2px 4px 4px #aaa;
    -moz-box-shadow: -1px 2px 4px 4px #aaa;
  }
`;

const HeaderTitle = styled("h4")`
  cursor: pointer;
  color: rgba(55, 133, 191, 0.94);
  font-size: 2em;
  height: 40px;
  &:hover {
    color: #448dc3 !important;
  }
  @media (max-width: 768px) {
    height: auto;
  }
`;
// max-width: 650px;
// border-style: solid;
// border-width: 0.5px;
// padding: 15px;
// border-radius: 9px;
// background-color: whitesmoke;
const ImageCol = styled(Col)`
  justify-content: center;
  margin-left: -10px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const ImageContainer = styled("div")`
  height: 250px;
  width: 250px;
  background-color: #eee;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border-style: solid;
  border-color: black;
  border-width: 1.5px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-left: 0px !important;
  }
`;

const ContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const ProfileImg = styled("img")`
  height: 100%;
  min-height: 275px;
  min-width: 249px;
  object-fit: cover;
`;

const StyledBio = styled("p")`
  cursor: pointer;
  min-height: 10px;
  &:hover {
    color: #448dc3 !important;
  }
`;

const StyledTable = styled(Table)``;

const TableHeaderStyle = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
`;

const TableDataStyle = styled("p")`
  text-align: center;
  margin: 0px;
  font-size: 1.6em;
  padding: 0px !important;
`;

const StyledMoreButton = styled("h6")`
  border-style: solid;
  background-color: white;
  border-width: 1px;
  padding: 5px;
  border-radius: 9px;
  cursor: pointer;
  &:hover {
    background-color: #448dc3 !important;
    color: white;
  }
`;
