import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { SpaceBetweenContainer } from "../../ui/BaseComponents";
import { EditSchoolFormProps } from "./interfaces";
import { Button } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function SchoolFormEdit(p: EditSchoolFormProps) {
  const [userData, setUserData] = useState(p.data);
  const isMentor = userData.type === "MENTOR";
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const [showEntrepreneur, setShowEntrepreneur] = useState(
    userData.classification === "Mentor"
  );

  useEffect(() => {
    setShowEntrepreneur(userData.classification === "Mentor");
  }, [userData.classification]);

  const [showSMESubject, setShowSMESubject] = useState(userData.subject);
  // UPDATE
  const updateFormData = async () => {
    // Validate required felids
    try {
      setIsLoading(true);
      const { data: patchData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${userData.id}`,
        {
          method: "PATCH",
          data: {
            major: userData.major,
            classification: userData.classification,
            first_generation: userData.first_generation,
            military: userData.military,
            adult_learner: userData.adult_learner,
            alumni: userData.classification === "Alumni" ? true : false,
            bio: userData.bio,
            blurb: userData.blurb,
            entrepreneur: userData.entrepreneur,
            subject: userData.subject,
            subject_expert: userData.subject_expert,
            linkedin_url: userData.linkedin_url,
            stem: userData.stem,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      p.handleUserUpdate(patchData);

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Form>
        <Row>
          <Col lg={6}>
            <Form.Group className='mb-3' controlId='formBasicClassification'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Classification
              </Form.Label>
              <Form.Select
                style={{ height: "3.5em" }}
                defaultValue={userData.classification}
                name='classification'
                onChange={(e) => {
                  handleChange(e);
                  setShowEntrepreneur(e.target.value === "Mentor");
                }}
                disabled={isLoading}
              >
                <option value='Freshman'>Freshman</option>
                <option value='Sophomore'>Sophomore</option>
                <option value='Junior'>Junior</option>
                <option value='Senior'>Senior</option>
                <option value='Graduate'>Graduate</option>
                <option value='Alumni'>Alumni</option>
                <option value='Mentor'>Mentor</option>
              </Form.Select>
            </Form.Group>
          </Col>

          {!showEntrepreneur && (
            <Col lg={6}>
              <Form.Group className='mb-3' controlId='major'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Major
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Major'
                  name='major'
                  style={{ height: "3.5em" }}
                  defaultValue={userData.major}
                  onChange={handleChange}
                  disabled={isLoading}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
        {showEntrepreneur && (
          <Row>
            <Col lg={6}>
              <Form.Group className='mb-3' controlId='formEntrepreneur'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Are you an entrepreneur?
                </Form.Label>
                <div style={{ display: "flex", marginLeft: 10 }}>
                  <div style={{ marginLeft: 10, marginRight: 50 }}>
                    <label htmlFor='yes_e' style={{ marginRight: 10 }}>
                      Yes
                    </label>
                    <input
                      type='radio'
                      id='yes_e'
                      name='entrepreneur'
                      value='true'
                      onChange={handleChange}
                      defaultChecked={userData.entrepreneur === true}
                      disabled={isLoading}
                    />
                  </div>
                  <div>
                    <label htmlFor='no_e' style={{ marginRight: 10 }}>
                      No
                    </label>
                    <input
                      type='radio'
                      id='no_e'
                      name='entrepreneur'
                      value='false'
                      onChange={handleChange}
                      defaultChecked={userData.entrepreneur === false}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='mb-3' controlId='formSubject_expert'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Are you a subject mater expert?
                </Form.Label>
                <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                  <div style={{ marginLeft: 10, marginRight: 50 }}>
                    <label htmlFor='yes_s' style={{ marginRight: 10 }}>
                      Yes
                    </label>
                    <input
                      type='radio'
                      id='yes_s'
                      name='subject_expert'
                      value='true'
                      onChange={(e) => {
                        handleChange(e);
                        setShowSMESubject(true);
                      }}
                      defaultChecked={userData.subject_expert === true}
                      disabled={isLoading}
                    />
                  </div>
                  <div>
                    <label htmlFor='no_s' style={{ marginRight: 10 }}>
                      No
                    </label>
                    <input
                      type='radio'
                      id='no_s'
                      name='subject_expert'
                      value='false'
                      onChange={(e) => {
                        handleChange(e);
                        setShowSMESubject(false);
                      }}
                      defaultChecked={userData.subject_expert === false}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
        {showSMESubject && showEntrepreneur && (
          <Row>
            <Col lg={6}>
              <Form.Group className='mb-3' controlId='major'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Below, please provide the subject of expertise
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  name='subject'
                  style={{ height: "3.5em" }}
                  defaultValue={userData.subject}
                  onChange={handleChange}
                  disabled={isLoading}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formAdultLearner'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Adult Learner (are you over the age of 21?)
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_a' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_a'
                    name='adult_learner'
                    value='true'
                    onChange={handleChange}
                    defaultChecked={userData.adult_learner === true}
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <label htmlFor='no_a' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_a'
                    name='adult_learner'
                    value='false'
                    onChange={handleChange}
                    defaultChecked={userData.adult_learner === false}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formAdultLearner'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you Active Military or a Military Dependant?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes'
                    name='military'
                    value='true'
                    onChange={handleChange}
                    defaultChecked={userData.military === true}
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <label htmlFor='no' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no'
                    name='military'
                    value='false'
                    onChange={handleChange}
                    defaultChecked={userData.military === false}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formAdultLearner'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you a first generation student?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_f' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_f'
                    name='first_generation'
                    value='true'
                    onChange={handleChange}
                    defaultChecked={userData.first_generation === true}
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <label htmlFor='no_f' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_f'
                    name='first_generation'
                    value='false'
                    onChange={handleChange}
                    defaultChecked={userData.first_generation === false}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formStem'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you in any STEM related major or program?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_stem' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_stem'
                    name='stem'
                    value='true'
                    onChange={handleChange}
                    defaultChecked={userData.stem === true}
                    disabled={isLoading}
                  />
                </div>
                <div>
                  <label htmlFor='no_stem' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_stem'
                    name='stem'
                    value='false'
                    onChange={handleChange}
                    defaultChecked={userData.stem === false}
                    disabled={isLoading}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-5' controlId='formLinkedIn'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                LinkedIn Profile
                <FontAwesomeIcon color='#0077b5' icon={faLinkedin} />
              </Form.Label>
              <Form.Control
                type='text'
                id='formLinkedIn'
                placeholder=''
                name='linkedin_url'
                style={{ height: "3.5em" }}
                defaultValue={userData.linkedin_url}
                onChange={handleChange}
                disabled={isLoading}
              />
            </Form.Group>
          </Col>
        </Row>
        {isMentor && (
          <Row>
            <Form.Group className='mb-3' controlId='formBio'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Short Blurb
              </Form.Label>
              <br />
              <Form.Label
                style={{
                  fontSize: ".8em",
                  fontWeight: "700",
                  lineHeight: "1.5em",
                }}
              >
                Please provide a short elevator pitch describing yourself.
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={2}
                type='text'
                placeholder=''
                name='blurb'
                defaultValue={userData.blurb}
                disabled={isLoading}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
        )}

        <Row>
          <Form.Group className='mb-3' controlId='formBio'>
            <Form.Label
              style={{
                fontSize: "1em",
                fontWeight: "400",
                lineHeight: "1.5em",
              }}
            >
              Bio
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              type='text'
              placeholder='bio'
              name='bio'
              defaultValue={userData.bio}
              onChange={handleChange}
              disabled={isLoading}
            />
          </Form.Group>
        </Row>
      </Form>
      <SpaceBetweenContainer>
        <Button
          onClick={updateFormData}
          variant='primary'
          className='ml-auto pr-5 pl-5'
        >
          Update
        </Button>
      </SpaceBetweenContainer>
    </>
  );
}
