export const decodeJWTBearer = (t: any) => {
  if (!t.includes("Bearer")) return false;
  t = t.replace(/Bearer /gi, "");
  if (!t) return false;
  let token: any = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split(".")[0]));
  token.payload = JSON.parse(window.atob(t.split(".")[1]));

  return token.payload;
};
