export interface ConnectionByState {
  id: number;
  mentor_id: number;
  mentee_id: number;
  status: string;
  state: string;
}

export const usStates = [
  { id: "01", state: "Alabama" },
  { id: "02", state: "Alaska" },
  { id: "04", state: "Arizona" },
  { id: "05", state: "Arkansas" },
  { id: "06", state: "California" },
  { id: "08", state: "Colorado" },
  { id: "09", state: "Connecticut" },
  { id: "10", state: "Delaware" },
  { id: "11", state: "District of Columbia" },
  { id: "12", state: "Florida" },
  { id: "13", state: "Georgia" },
  { id: "15", state: "Hawaii" },
  { id: "16", state: "Idaho" },
  { id: "17", state: "Illinois" },
  { id: "18", state: "Indiana" },
  { id: "19", state: "Iowa" },
  { id: "20", state: "Kansas" },
  { id: "21", state: "Kentucky" },
  { id: "22", state: "Louisiana" },
  { id: "23", state: "Maine" },
  { id: "24", state: "Maryland" },
  { id: "25", state: "Massachusetts" },
  { id: "26", state: "Michigan" },
  { id: "27", state: "Minnesota" },
  { id: "28", state: "Mississippi" },
  { id: "29", state: "Missouri" },
  { id: "30", state: "Montana" },
  { id: "31", state: "Nebraska" },
  { id: "32", state: "Nevada" },
  { id: "33", state: "New Hampshire" },
  { id: "34", state: "New Jersey" },
  { id: "35", state: "New Mexico" },
  { id: "36", state: "New York" },
  { id: "37", state: "North Carolina" },
  { id: "38", state: "North Dakota" },
  { id: "39", state: "Ohio" },
  { id: "40", state: "Oklahoma" },
  { id: "41", state: "Oregon" },
  { id: "42", state: "Pennsylvania" },
  { id: "44", state: "Rhode Island" },
  { id: "45", state: "South Carolina" },
  { id: "46", state: "South Dakota" },
  { id: "47", state: "Tennessee" },
  { id: "48", state: "Texas" },
  { id: "49", state: "Utah" },
  { id: "50", state: "Vermont" },
  { id: "51", state: "Virginia" },
  { id: "53", state: "Washington" },
  { id: "54", state: "West Virginia" },
  { id: "55", state: "Wisconsin" },
  { id: "56", state: "Wyoming" }
];

export function getStateIds(connections:ConnectionByState[], states: {
  id: string;
  state: string;
}[]) {
  return connections.map(connection => {
    const state = states.find(stateObj => stateObj.state === connection.state);
    return state ? state.id : null;
  });
}

// Manually calculated centroids for each state
export const stateCentroids = {
  "01": [-86.9023, 32.3182], // Alabama
  "02": [-154.4931, 63.5888], // Alaska
  "04": [-111.0937, 34.0489], // Arizona
  "05": [-92.3731, 34.9697], // Arkansas
  "06": [-119.4179, 36.7783], // California
  "08": [-105.7821, 39.5501], // Colorado
  "09": [-72.7554, 41.6032], // Connecticut
  "10": [-75.5277, 38.9108], // Delaware
  "11": [-77.0369, 38.9072], // District of Columbia
  "12": [-81.5158, 27.6648], // Florida
  "13": [-83.6431, 32.1656], // Georgia
  "15": [-155.5828, 19.8968], // Hawaii
  "16": [-114.742, 44.0682], // Idaho
  "17": [-89.3985, 40.6331], // Illinois
  "18": [-86.1349, 40.2672], // Indiana
  "19": [-93.0977, 41.878], // Iowa
  "20": [-98.4842, 39.0119], // Kansas
  "21": [-84.27, 37.8393], // Kentucky
  "22": [-91.9623, 30.9843], // Louisiana
  "23": [-69.4455, 45.2538], // Maine
  "24": [-76.6413, 39.0458], // Maryland
  "25": [-71.3824, 42.4072], // Massachusetts
  "26": [-84.536, 44.3148], // Michigan
  "27": [-94.6859, 46.7296], // Minnesota
  "28": [-89.3985, 32.3547], // Mississippi
  "29": [-91.8318, 37.9643], // Missouri
  "30": [-110.3626, 46.8797], // Montana
  "31": [-99.9018, 41.4925], // Nebraska
  "32": [-116.4194, 38.8026], // Nevada
  "33": [-71.5724, 43.1939], // New Hampshire
  "34": [-74.4057, 40.0583], // New Jersey
  "35": [-106.0181, 34.5199], // New Mexico
  "36": [-74.2179, 43.2994], // New York
  "37": [-79.0193, 35.7596], // North Carolina
  "38": [-101.002, 47.5515], // North Dakota
  "39": [-82.9071, 40.4173], // Ohio
  "40": [-97.0929, 35.0078], // Oklahoma
  "41": [-120.5542, 43.8041], // Oregon
  "42": [-77.1945, 41.2033], // Pennsylvania
  "44": [-71.4774, 41.5801], // Rhode Island
  "45": [-81.1637, 33.8361], // South Carolina
  "46": [-99.9018, 43.9695], // South Dakota
  "47": [-86.5804, 35.5175], // Tennessee
  "48": [-99.9018, 31.9686], // Texas
  "49": [-111.0937, 39.32], // Utah
  "50": [-72.5778, 44.5588], // Vermont
  "51": [-78.6569, 37.4316], // Virginia
  "53": [-120.7401, 47.7511], // Washington
  "54": [-80.4549, 38.5976], // West Virginia
  "55": [-89.6165, 43.7844], // Wisconsin
  "56": [-107.2903, 43.0759], // Wyoming
};
