import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import axios, { AxiosError } from "axios";
import { Link, useNavigate } from "react-router-dom";
import NavigationButton from "../button/NavigationButton";
import Col from "react-bootstrap/esm/Col";
import { useData } from "../../utils/context/DataContext";
import { setCookie } from "../../utils/cookies";
import HelpForm from "../../Help/HelpForm";

export default function SignInForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setData } = useData();

  // TODO =>
  // Add in some validation and defensive coding to email/password
  // Figure out what to do with confirm password and figure out how to validate each field
  // Add in Loading Icon for isLoading
  // Possibly make api global call

  const handleSignIn = async () => {
    if (!email || !password) return;
    try {
      setIsLoading(true);
      setErrorMessage("");
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/login",
        {
          method: "POST",
          data: {
            email: email,
            password: password,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (headers.authorization) {
        const authHeader = headers.authorization;
        await setCookie("mentor-token", authHeader);
      }
      setData(data);
      if (data.isAdmin) {
        navigate("/admin");
        return;
      }
      if (data.account_verified) {
        navigate("/dashboard");
      } else {
        navigate("/verification");
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 404) {
          setErrorMessage("User not found. Sign Up");
        } else if (err.response?.status === 403) {
          setErrorMessage(`password incorrect for user ${email}`);
        } else if (err.response?.status === 400) {
          setErrorMessage("please complete the form");
        } else if (err.response?.status === 409) {
          setErrorMessage(`user ${email} must create an account`);
        } else {
          setErrorMessage("Server Error has occurred... try again");
        }
        setIsLoading(false);
      } else {
        setErrorMessage("Server Error has occurred");
        setIsLoading(false);
      }
    }
  };

  return (
    <Form>
      <Col lg={12} style={{ marginTop: "2em" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label
            style={{ fontSize: "1em", fontWeight: "400", lineHeight: "1.5em" }}
          >
            Enter your Student ID or email address
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Student ID or email address"
            style={{ height: "3.5em" }}
            spellCheck={false}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Form.Group>
      </Col>

      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label
            style={{ fontSize: "1em", fontWeight: "400", lineHeight: "1.5em" }}
          >
            Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            style={{ height: "3.5em" }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
      </Col>
      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label
            style={{ fontSize: ".8em", fontWeight: "400", lineHeight: "1.5em" }}
          >
            <Link to={"/forgot-password"}>Forgot password </Link>
          </Form.Label>
        </Form.Group>
      </Col>
      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3">
          <Form.Label
            style={{
              fontSize: "1em",
              fontWeight: "400",
              lineHeight: "1.5em",
              color: "red",
            }}
          >
            {errorMessage}
          </Form.Label>
        </Form.Group>
      </Col>
      <Col lg={12} style={{ marginTop: "-1em" }}>
        <NavigationButton
          height="3.5em"
          width="100%"
          backgroundColor="#0089ED"
          title="Sign In"
          handleApiCall={handleSignIn}
        />
      </Col>
      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3" controlId="helpForm">
          <Form.Label>
            <HelpForm />
          </Form.Label>
        </Form.Group>
      </Col>
    </Form>
  );
}
