import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";

export const PDFViewer = ({
  id,
  fileLink,
}: {
  id: string;
  fileLink: string;
}) => {
  const [pdfURL, setPdfURL] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${id}/resume`,
          {
            method: "POST",
            data: {
              key: fileLink.replace(/.*(resume-.*)/, "$1"),
            },
            headers: {
              "Content-Type": "application/json",
            },
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(
          new Blob([data], { type: "application/pdf" })
        );
        setPdfURL(url);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>{pdfURL && <embed src={pdfURL} width='100%' height='900px' />}</div>
  );
};
