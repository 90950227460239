import axios from "axios";
interface UserConnections {}

/***
 * Get Verified for a user
 */
const getUserNonVerified = async () => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/users/non-verified`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const sendVerificationMessage = async (
  user_type: string,
  message: string,
  subject: string,
  check_verified = true
) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/message/users`,
    {
      method: "POST",
      data: {
        user_type,
        message,
        check_verified,
        subject,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const sendVerificationMessageUser = async (
  email: string,
  message: string,
  subject: string
) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/message/user`,
    {
      method: "POST",
      data: {
        email,
        message,
        subject,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export {
  getUserNonVerified,
  sendVerificationMessage,
  sendVerificationMessageUser,
};
