/**
 * Title Component
 *
 * created by Ty D'Angelo 9/9/2023
 */

import React from "react";
import { TitleProps } from "./interfaces";

export default function Title(p: TitleProps) {
  return (
    <div
      style={{
        fontWeight: p.fontWeight,
        fontSize: p.fontSize,
        marginLeft: p.marginLeft,
      }}
    >
      {p.message}
    </div>
  );
}
