import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import {
  ColumnAlignedContainer,
  SpaceBetweenContainer,
} from "../../ui/BaseComponents";
import Title from "../../ui/title/Title";
import SignUpForm from "../form/SignUpForm";
import SignInForm from "../form/SignInForm";
import { useData } from "../../utils/context/DataContext";
import { useNavigate } from "react-router-dom";
import { deleteCookie, getCookie } from "../../utils/cookies";
import axios from "axios";
import { decodeJWTBearer } from "../../utils/decodeJWT";
import styled from "styled-components";
import { Button } from "react-bootstrap";

export default function CardComponent() {
  const { setData, data } = useData();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleIsSignedIn = async () => {
    setIsLoading(true);
    const mentorToken = await getCookie("mentor-token");
    if (mentorToken) {
      try {
        const jwtUser = decodeJWTBearer(mentorToken);
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${jwtUser.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(userData);
        setTimeout(() => {
          if (userData.isAdmin) {
            navigate("/admin");
            return;
          }
          setIsLoading(false);
          if (userData.account_verified) {
            navigate("/dashboard");
          } else {
            navigate("/verification");
          }
        }, 500);
      } catch (e) {
        console.log("error using mentor token");
        setIsLoading(false);
        deleteCookie("mentor-token");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 600);
    }
  };

  useEffect(() => {
    handleIsSignedIn();
  }, []);
  const [showSignUp, setShowSignUp] = useState(false);

  const toggleShowSignUp = () => {
    setShowSignUp(!showSignUp);
  };

  return (
    <StyledCard className="main-card">
      <Card.Body>
        <SpaceBetweenContainer>
          <Card.Title style={{ marginLeft: "1em" }}>
            Welcome to{" "}
            <a style={{ textDecoration: "none" }} href="#">
              BRONCO PREP
            </a>
          </Card.Title>
          <ColumnAlignedContainer style={{ marginRight: "1em" }}>
            {showSignUp ? (
              <>
                <Card.Subtitle
                  className="mb-2 text-muted"
                  style={{
                    marginTop: "1em",
                    color: "#8D8D8D",
                    fontWeight: "400",
                    fontSize: "0.8125em",
                  }}
                >
                  Have an Account?
                </Card.Subtitle>
                <Card.Link
                  style={{
                    textDecoration: "none",
                    fontSize: "0.8125em",
                    cursor: "pointer",
                    color: "blue",
                  }}
                  onClick={toggleShowSignUp}
                >
                  <Button>Sign In</Button>
                </Card.Link>
              </>
            ) : (
              <>
                <Card.Subtitle
                  className="mb-2 text-muted"
                  style={{
                    marginTop: "1em",
                    color: "#8D8D8D",
                    fontWeight: "400",
                    fontSize: "0.8125em",
                  }}
                >
                  Don't have an Account?
                </Card.Subtitle>
                <Card.Link onClick={toggleShowSignUp}>
                  <Button>Sign Up</Button>
                </Card.Link>
              </>
            )}
          </ColumnAlignedContainer>
        </SpaceBetweenContainer>
        {showSignUp ? (
          <>
            <Title
              message="Sign up"
              fontSize={"3.4em"}
              fontWeight="500"
              marginLeft="0.35em"
            />
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <div className="spinner-grow text-primary"></div>
                <div className="spinner-grow text-primary"></div>
                <div className="spinner-grow text-primary"></div>
              </div>
            ) : (
              <SignUpForm />
            )}
          </>
        ) : (
          <>
            <Title
              message="Log In"
              fontSize={"3.4em"}
              fontWeight="500"
              marginLeft="0.35em"
            />
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <div className="spinner-grow text-primary"></div>
                <div className="spinner-grow text-primary"></div>
                <div className="spinner-grow text-primary"></div>
              </div>
            ) : (
              <SignInForm />
            )}
          </>
        )}
      </Card.Body>
    </StyledCard>
  );
}

export const StyledCard = styled(Card)`
  margin-top: 10em;
  padding-bottom: 30px;
  overflow: visible;
  border-radius: 5%;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: -1px 1px 1px 1px #ccc;
  -webkit-box-shadow: -1px 1px 1px 1px #ccc;
  -moz-box-shadow: -1px 1px 1px 1px #ccc;
`;
