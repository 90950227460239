import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import NavigationButton from "../button/NavigationButton";
import Col from "react-bootstrap/esm/Col";
import { useData } from "../../utils/context/DataContext";
import {} from "../../utils/decodeJWT";
import { setCookie } from "../../utils/cookies";
import HelpForm from "../../Help/HelpForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGraduationCap,
  faSchool,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

export const MentorTypes = ["Professional Mentor", "Student Mentor"];
export const getDirivedUserType = (type) => {
  return MentorTypes.includes(type) ? "MENTOR" : "MENTEE";
};

export default function SignUpForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setData, data } = useData();

  const disableButton = () => {
    return !email || !password || !userType;
  };

  // TODO =>
  // Add in some validation and defensive coding to email/password
  // Figure out what to do with confirm password and figure out how to validate each field
  // Add in Loading Icon for isLoading
  // Possibly make api global call

  const handleSignUp = async () => {
    if (!email || !password || !userType) return;
    try {
      setIsLoading(true);
      setErrorMessage("");
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/api/signup",
        {
          method: "POST",
          data: {
            email: email,
            password: password,
            type: getDirivedUserType(userType),
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (headers.authorization) {
        const authHeader = headers.authorization;
        await setCookie("mentor-token", authHeader);
        await setCookie("signup-type", userType);
      }

      setIsLoading(false);
      navigate("/verification");

      setData(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 400) {
          setErrorMessage("please complete the form");
        } else if (err.response?.status === 409) {
          setErrorMessage(
            `user with email ${email} already has an account. Sign In`
          );
        } else {
          setErrorMessage("Server Error has occurred... try again");
        }
        setIsLoading(false);
      } else {
        setErrorMessage("Server Error has occurred... try again");
        setIsLoading(false);
      }
    }
  };

  return (
    <Form>
      <Col lg={12} style={{ marginTop: "2em" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label
            style={{ fontSize: "1em", fontWeight: "400", lineHeight: "1.5em" }}
          >
            Enter your Student ID or email address
          </Form.Label>
          <Form.Control
            type="email"
            placeholder="Student ID or email address"
            style={{ height: "3.5em" }}
            spellCheck={false}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Form.Group>
      </Col>

      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label
            style={{ fontSize: "1em", fontWeight: "400", lineHeight: "1.5em" }}
          >
            Create your Password
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            style={{ height: "3.5em" }}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Group>
      </Col>
      <Col
        lg={12}
        style={{ marginTop: "1em", flexWrap: "wrap", display: "flex" }}
      >
        <UserTypeButton
          style={
            userType === "Professional Mentor"
              ? { backgroundColor: "#e7f0fe" }
              : {}
          }
          onClick={() => {
            setUserType("Professional Mentor");
          }}
        >
          <div>
            <FontAwesomeIcon icon={faGraduationCap} color="orange" />
          </div>
          <div className="ml-2">
            <h6 className="mb-0">Professional Mentor</h6>
          </div>
        </UserTypeButton>
        <UserTypeButton
          style={
            userType === "Student Mentor" ? { backgroundColor: "#e7f0fe" } : {}
          }
          onClick={() => {
            setUserType("Student Mentor");
          }}
        >
          <div>
            <FontAwesomeIcon icon={faSchool} color="purple" />
          </div>
          <div className="ml-2">
            <h6 className="mb-0">Student Mentor</h6>
          </div>
        </UserTypeButton>
        <UserTypeButtonLong
          style={
            userType === "Collegiate Student looking for mentorship"
              ? { backgroundColor: "#e7f0fe" }
              : {}
          }
          onClick={() => {
            setUserType("Collegiate Student looking for mentorship");
          }}
        >
          <div>
            <FontAwesomeIcon icon={faUser} color="green" />
          </div>
          <div className="ml-2">
            <h6 className="mb-0">Collegiate Student looking for mentorship</h6>
          </div>
        </UserTypeButtonLong>

        {/* <Form.Group className="mb-3" controlId="formAdultLearner">
          <Form.Label
            style={{
              fontSize: "1em",
              fontWeight: "400",
              lineHeight: "1.5em",
            }}
          >
            Student (Mentee) or Alumni (Mentor)?
          </Form.Label>
          <div style={{ display: "flex", marginLeft: 10 }}>
            <div style={{ marginLeft: 10, marginRight: 50 }}>
              <label htmlFor="yes" style={{ marginRight: 10 }}>
                Mentor
              </label>
              <input
                type="radio"
                id="yes"
                name="type"
                value="MENTOR"
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="no" style={{ marginRight: 10 }}>
                Student
              </label>
              <input
                type="radio"
                id="no"
                name="type"
                value="MENTEE"
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              />
            </div>
          </div>
        </Form.Group> */}
      </Col>
      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3">
          <Form.Label
            style={{
              fontSize: "1em",
              fontWeight: "400",
              lineHeight: "1.5em",
              color: "red",
            }}
          >
            {errorMessage}
          </Form.Label>
        </Form.Group>
      </Col>

      <Col lg={12} style={{ marginTop: "1em" }}>
        <NavigationButton
          height="3.5em"
          width="100%"
          backgroundColor={disableButton() ? "#eee" : "#0089ED"}
          color={disableButton() ? "black" : ""}
          title="Sign Up"
          handleApiCall={disableButton() ? () => null : handleSignUp}
        />
      </Col>
      <Col lg={12} style={{ marginTop: "1em" }}>
        <Form.Group className="mb-3">
          <Form.Label>
            <HelpForm />
          </Form.Label>
        </Form.Group>
      </Col>
    </Form>
  );
}

export const UserTypeButton = styled("div")`
  display: flex;
  border-style: solid;
  border-color: #ccc;
  background-color: #effff;
  width: 210px;
  align-items: center;
  border-radius: 9px;
  padding: 10px;
  margin-bottom: 5px;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e7f0fe;
  }
`;

export const UserTypeButtonLong = styled("div")`
  display: flex;
  border-style: solid;
  border-color: #ccc;
  background-color: #effff;
  width: 425px;
  align-items: center;
  border-radius: 9px;
  padding: 10px;
  margin-bottom: 5px;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e7f0fe;
  }
`;
