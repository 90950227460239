import React, {useState} from "react";
import styled from "styled-components";
import { Card, Row } from "react-bootstrap";
import { Session } from "../ui/table/SessionRequestContainer";
import { AppointmentCard } from "./AppointmentCard";
import { Link } from "react-router-dom";
import { useData } from "../utils/context/DataContext";
import { SessionsCard } from "./SessionsCard";
import { ContentContainer } from "../ui/BaseComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export const AppointmentContainer = ({
  appointments: initialAppointments,
  type,
}: {
  appointments: Session[];
  type: "upcoming" | "previous";
}) => {
  const { data } = useData();
  const isMentee = data.type === "MENTEE";
  const title = type === "upcoming" ? "Upcoming Sessions" : "Past Sessions";
  const [appointments, setAppointments] = useState<Session[]>(initialAppointments);
  const filteredAppointments = appointments.filter((appt) => {
    if (type === "upcoming") {
      return new Date(appt.date) > new Date();
    } else {
      return new Date(appt.date) < new Date();
    }
  });

  // Limit the number of upcoming sessions displayed to a maximum of two
  const limitedAppointments =
    type === "upcoming" ? filteredAppointments.slice(0, 2) : filteredAppointments;

  const isPrevious = type === "previous";

   // Navigate to the dashboard 
   const navigateToDashboard = () => {
    window.location.href = `/dashboard/`;
  }

  // Handle session cancellation 
  const handleCancelSession = (id: string | number) => {
    setAppointments(appointments.filter(appointment => appointment.id !== id));
  };

  return (
    <div>
      <h4
        style={{
          // borderColor: "rgb(0, 119, 181)",
          // color: "rgb(0, 119, 181)",
          // backgroundColor: "white",
          // padding: 5,
          // borderRadius: 9,
          // borderWidth: 1,
          // fontSize: "1.5em",
          // borderStyle: "solid",
          // width: "fit-content",
          fontSize:"1.5em", 
          fontWeight:"bold",
          marginTop: isPrevious ? "50px" : "none"
        }}
        className="mb-3"
      >
        {title}
      </h4>
      <CardContainer>
        {filteredAppointments.length === 0 ? (
          <div
            style={{
              borderColor: "rgb(0, 119, 181)",
              backgroundColor: "white",
              padding: 40,
              borderRadius: 9,
              borderWidth: 1,
              borderStyle: "solid",
              width: "fit-content",
            }}
          >
            <h5>No appointments scheduled</h5>
            {isMentee ? (
              <>
                <p>Request an appointment with a mentor today</p>
                <Link to={"/dashboard"}>dashboard</Link>
              </>
            ) : (
              <>
                <p>Looks like you do not have any upcomming sessions</p>
              </>
            )}
          </div>
        ) : (
          <UpcomingContainer>
          {limitedAppointments.map((appt) => (
            <AppointmentCard type={type} appointment={appt} onCancelSession={handleCancelSession} key={appt.id} />
          ))}
          {type === "upcoming" && isMentee && (
              <FindCard>
                <ContentContainer style={{gap:"15px"}}>
                  <StyledFontAwesomeIcon icon={faMagnifyingGlass} />
                  <Row>
                    <RowTitle>Find a Mentor</RowTitle>
                  </Row>
                  <Row>
                    <RowDescription>
                      Search our network of amazing students, alumni, and professionals
                    </RowDescription>
                  </Row>
                  <Row>
                    <ActionsContainer>
                      <ActionButton onClick={navigateToDashboard}>
                        <h6 style={{ fontWeight: "bold" }}>View All</h6>
                      </ActionButton>
                    </ActionsContainer>
                  </Row>
                </ContentContainer>
              </FindCard>
            )}
          </UpcomingContainer>
        )}
      </CardContainer>
    </div>
  );
};

const CardContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 60px;
  height: 60px;
  color: #1170b8;
`;

const RowTitle = styled.h6`
  font-weight: bold;
  font-size: 25px;
  margin: 0;
`;

const ActionButton = styled.div`
  padding-left:6px;
  padding-right:6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaa;
  border-radius: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const RowDescription = styled.div`
  font-size: 16px; /* Custom font size for date and time */
  color: #555; /* Custom color */
`;

const FindCard = styled(Card)`
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 400px;
`;

const UpcomingContainer = styled.div`
  display: flex;
  flex-wrap: noWrap;
  gap: 15px;
`;
