import React from "react";
import Button from "react-bootstrap/esm/Button";
import { TabButtonProps } from "./interfaces";

export default function TabButtonForward(p: TabButtonProps) {
  return (
    <>
      <Button
        style={{
          width: p.width,
          height: p.height,
          backgroundColor: p.backgroundColor,
          color: p.color,
        }}
        onClick={() => p.setKey("school")}
      >
        {p.title}
      </Button>
    </>
  );
}
