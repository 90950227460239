/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export default function PageNotFound() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Container fluid style={{ marginTop: 110 }}>
        <Row>
          <Col md={4}></Col>
          <Col md={4} style={{ marginTop: 250 }}>
            <h4>404</h4>
            <h1>Can't find the page you are looking for...</h1>
          </Col>
          <Col md={4}></Col>
        </Row>
      </Container>
    </div>
  );
}
