import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Container, Row, Button, Form, Spinner } from "react-bootstrap";

import {
  sendVerificationMessage,
  sendVerificationMessageUser,
} from "../../apis/Verified";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { fetchUsers } from "../../apis/User";

const MESSAGE_TO_TYPES = [
  {
    name: "Mentors",
    value: "MENTOR",
  },
  {
    name: "Students",
    value: "MENTEE",
  },
  {
    name: "All",
    value: "ALL",
  },
];
const SUBJECTS = [
  {
    name: "Prep News",
    value: "prep_news",
  },
  {
    name: "Prep Communications",
    value: "prep_communications",
  },
  {
    name: "Other",
    value: "other",
  },
];

import { UserData } from "../Mentor/interfaces";
import { AxiosError, isAxiosError } from "axios";

export const PushMessage = ({
  checkVerified = true,
  predefinedEmail,
}: {
  checkVerified?: boolean;
  predefinedEmail?: string;
}) => {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [message, setMessage] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>(predefinedEmail ?? "");
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>(
    predefinedEmail ?? ""
  );
  const [subject, setSubject] = useState<string>("");
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);
  const [apiMessage, setAPIMessage] = useState<{
    message: string;
    success: boolean;
  } | null>({ message: "", success: false });
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [users, setUsers] = useState<UserData[]>([]);

  const toggleSelectedType = (value: string) => {
    setAPIMessage(null);
    if (selectedType === value) {
      setSelectedType(null);
    } else {
      setSelectedType(value);
    }
  };

  const sendMessage = async () => {
    setAPIMessage(null);
    setIsSendingMessage(true);
    try {
      const emailSubject =
        selectedSubject === "other"
          ? subject
          : SUBJECTS.find((sub) => sub.value === selectedSubject)?.name;
      if (selectedType) {
        await sendVerificationMessage(
          selectedType,
          message,
          emailSubject ?? "Bronco Mentor",
          checkVerified
        );
      } else {
        await sendVerificationMessageUser(
          selectedUserEmail,
          message,
          emailSubject ?? "Bronco Mentor"
        );
      }
      setIsSendingMessage(false);
      setAPIMessage({ message: "Message Sent!", success: true });
      setTimeout(() => {
        setAPIMessage(null);
      }, 3000);
    } catch (e) {
      if (isAxiosError(e)) {
        const error: AxiosError = e;
        if (error.code === "404") {
          setIsSendingMessage(false);
          setAPIMessage({
            message: "User Not Found",
            success: false,
          });
        }
      }
      setIsSendingMessage(false);
      setAPIMessage({
        message: "Error Sending Message... Try again later",
        success: false,
      });
    }
  };

  const handleFetchUsers = async () => {
    if (userEmail.length < 4) return;
    try {
      setIsFetchingUsers(true);
      const data = await fetchUsers(userEmail);
      setUsers(data);
      setIsFetchingUsers(false);
    } catch (e) {
      setIsFetchingUsers(false);
    }
  };

  const doShowSearchResults = () => userEmail.length > 3 && !selectedUserEmail;

  useEffect(() => {
    if (!userEmail && isFetchingUsers) return;
    handleFetchUsers();
  }, [userEmail]);

  const doDisable = () => {
    if (selectedType) {
      return !message || !selectedSubject;
    }
    if (selectedUserEmail) {
      return !message || !selectedSubject;
    }
    return true;
  };

  const UserTypeFilterSection = () => {
    return (
      <>
        {` | `}
        <div style={{ display: "flex", padding: "0px 10px" }}>
          {MESSAGE_TO_TYPES.map((type) => (
            <div
              style={{
                margin: "5px 20px 5px 0px",
                padding: 10,
                backgroundColor:
                  selectedType === type.value
                    ? "rgba(55, 133, 191, 0.94)"
                    : "#eee",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                setUserEmail("");
                setSelectedUserEmail("");
                toggleSelectedType(type.value);
              }}
            >
              <h6 style={{ margin: 0 }}>{type.name}</h6>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <Container>
      <PushMessageContentRow>
        <Col lg={8}>
          <div>
            <h3>Push Message</h3>
            <h6>
              Complete the form to send out an email to users{" "}
              {checkVerified ? "who require verification" : ""}
            </h6>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Group
              controlId='user'
              style={{
                width: "100%",
                paddingRight: "10px",
                margin: "20px 0px",
              }}
            >
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Subject
              </Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => setSelectedSubject(e.target.value)}
              >
                <option value=''>Select A Subject</option>
                {SUBJECTS.map((subject) => (
                  <option value={subject.value}>{subject.name}</option>
                ))}
              </Form.Control>
              {selectedSubject === "other" && (
                <>
                  <Form.Label
                    style={{
                      fontSize: "1em",
                      fontWeight: "400",
                      lineHeight: "1.5em",
                      marginTop: 20,
                    }}
                  >
                    Enter Subject
                  </Form.Label>

                  <Form.Control
                    type='text'
                    id={"user"}
                    placeholder=''
                    name='user'
                    defaultValue={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </>
              )}
            </Form.Group>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Group
              controlId='user'
              style={{
                width: "500px",
                paddingRight: "10px",
                margin: "20px 0px",
              }}
            >
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                User
              </Form.Label>
              {selectedUserEmail ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Label
                    style={{
                      fontSize: "1em",
                      fontWeight: "400",
                      lineHeight: "1.5em",
                      paddingLeft: 10,
                      marginBottom: 0,
                    }}
                  >
                    {selectedUserEmail}
                  </Form.Label>
                  {!predefinedEmail && (
                    <Button
                      variant='outline-danger'
                      className='ml-auto'
                      onClick={() => {
                        setUserEmail("");
                        setSelectedUserEmail("");
                      }}
                    >
                      <FontAwesomeIcon icon={faXmarkCircle} />
                    </Button>
                  )}
                </div>
              ) : (
                <Form.Control
                  type='text'
                  id={"user"}
                  placeholder='john.doe@domain.com'
                  name='user'
                  defaultValue={userEmail}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                />
              )}
              {doShowSearchResults() && (
                <UserSearchContainer>
                  {isFetchingUsers ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        paddingTop: 30,
                      }}
                    >
                      <Spinner style={{ color: "rgba(55, 133, 191, 0.94)" }} />
                    </div>
                  ) : (
                    users.map((user) => (
                      <UserSearchItem
                        onClick={() => {
                          setSelectedType(null);
                          setSelectedUserEmail(user.email);
                        }}
                      >
                        <span className='mr-3'>{user.email}</span>
                      </UserSearchItem>
                    ))
                  )}

                  {/* Content inside the scrollable div */}
                  {/* Add your content here */}
                </UserSearchContainer>
              )}
            </Form.Group>
            {!predefinedEmail && <UserTypeFilterSection />}
          </div>

          <Form.Group className='mb-3' controlId='formBio'>
            <Form.Label
              style={{
                fontSize: "1em",
                fontWeight: "400",
                lineHeight: "1.5em",
              }}
            >
              Message
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={7}
              type='text'
              placeholder='Custom message...'
              name='bio'
              defaultValue={message}
              onChange={(e) => {
                setAPIMessage(null);
                setMessage(e.target.value);
              }}
            />
            {/* Error Message */}
            <div style={{ height: 50 }}>
              {isSendingMessage && (
                <Spinner variant='primary' className='mt-2' />
              )}
              {apiMessage && (
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    color: apiMessage.success ? "green" : "red",
                  }}
                >
                  {apiMessage.message}
                </Form.Label>
              )}
            </div>
            <Button disabled={doDisable()} onClick={sendMessage}>
              Send
            </Button>
          </Form.Group>
        </Col>
      </PushMessageContentRow>
    </Container>
  );
};

const PushMessageContentRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin: 30px 0px;
`;

const UserSearchItem = styled("div")`
  display: flex;
  fontsize: 0.8em;
  border-style: solid;
  border-width: 0.5px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #eee;
  border-color: #ddd;
  &:hover {
    background-color: rgba(55, 133, 191, 0.84);
    color: white;
  }
`;

const UserSearchContainer = styled("div")`
  height: 350px;
  width: 405px;
  overflow-y: auto;
  position: absolute;
  top: 240px;
  border-radius: 5px;
  borderstyle: solid;
  border-width: 0.5px;
  background-color: #ddd;
  z-index: 1;
  box-shadow: -1px 1px 1px 1px #ccc;
  -webkit-box-shadow: -1px 1px 1px 1px #ccc;
  -moz-box-shadow: -1px 1px 1px 1px #ccc;
`;
