/**
 * Standard Components for admin app
 * containers, buttons, forms, labels, etc.
 *
 * created by Ty D'Angelo 10/17/2022
 */

import styled from "@emotion/styled";

export const ContentContainer = styled("div")`
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
`;

export const FlexContainerRowNoWrapNoFlex = styled(ContentContainer)`
  flex: none;
  flex-flow: row nowrap;
`;

export const Title = styled(FlexContainerRowNoWrapNoFlex)`
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  margin: 1em 0;
  overflow: visible;
`;

export const ContentHeader = styled(FlexContainerRowNoWrapNoFlex)`
  font-size: 1.5em;
  display: flex;
  justify-content: flex-start;
  margin: 1em 0;
  padding: 0 0 0.75em 0.75em;
  overflow: visible;
  color: #282828;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const CenteredContainer = styled("div")`
  display: flex;
  justify-content: center;
`;

export const SpinnerContainer = styled(CenteredContainer)`
  margin-top: 7%;
`;

export const ButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

export const ViewsTitleContainer = styled("div")`
  min-height: 3.5em;
  border-radius: 0.2em 0.2em 0 0;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  font-weight: 600;
  padding-left: 1em;
  overflow: visible;
  margin: 0;
  width: 100%;
  position: sticky;
  z-index: 1;
`;

export const ViewsContainer = styled("div")`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.6em;
  min-height: 70vh;
  max-height: 70vh;
  overflow: auto;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: #f4f4f4;
`;

export const CenteredAlignedContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeftAlignedContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightAlignedContainer = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const SpaceBetweenContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ColumnAlignedContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const RowAlignedContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

export const DashboardTableContainer = styled("div")`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.6em;
  min-height: 18em;
  max-height: 18em;
  overflow: auto;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: #f4f4f4;
`;

export const FloatingContainer = styled("div")`
  margin: 40px 0px;
  background-color: white;
  padding: 25px 20px;
  border-radius: 9px;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 92%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
`;

export const SmallImageContainer = styled("div")`
  height: 75px;
  width: 75px;
  background-color: black;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  margin-right: 0.75em;
`;

export const SmallProfileImg = styled("img")`
  min-height: 85px;
  min-width: 75px;
  object-fit: cover;
`;
