import React from "react";
import styled from "styled-components";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Availability } from "./interfaces";
import { DAYS_OF_WEEK } from "./constants";
import { isNullOrUndefined } from "util";
const convertTo12HourFormat = (timeStr) => {
  // Extract hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12; // 0 should be converted to 12

  // Format the time
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};
export const GetDayOfWeekDisplayName = (availability: Availability) =>
  DAYS_OF_WEEK.filter((day) => {
    return day.value == availability.day;
  })[0];

export interface TimeZoneInfo {
  abbreviation: string;
  fullName: string;
}

export function mapTimeZoneToInfo(
  timeZoneIdentifier: string
): TimeZoneInfo | undefined {
  const timeZoneMapping: { [key: string]: TimeZoneInfo } = {
    "America/New_York": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Detroit": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indiana/Indianapolis": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indiana/Marengo": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indiana/Vevay": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indianapolis": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Louisville": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },

    "America/Chicago": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Knox": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Tell_City": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Vincennes": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Winamac": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Knox_IN": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Menominee": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/North_Dakota/Beulah": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/North_Dakota/Center": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/North_Dakota/New_Salem": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },

    "America/Denver": {
      abbreviation: "MST",
      fullName: "Mountain Standard Time",
    },
    "America/Boise": {
      abbreviation: "MST",
      fullName: "Mountain Standard Time",
    },
    "America/Shiprock": {
      abbreviation: "MST",
      fullName: "Mountain Standard Time",
    },

    "America/Los_Angeles": {
      abbreviation: "PST",
      fullName: "Pacific Standard Time",
    },
    "America/Phoenix": {
      abbreviation: "PST",
      fullName: "Pacific Standard Time",
    },
    "America/Tijuana": {
      abbreviation: "PST",
      fullName: "Pacific Standard Time",
    },
  };

  return timeZoneMapping[timeZoneIdentifier];
}

export const convertTimeWithoutDate = (
  inputTime,
  sourceTimeZone,
  targetTimeZone
) => {
  // Parse the input time
  const [hours, minutes] = inputTime.split(":").map(Number);

  // Define time zone offsets in minutes
  const timeZoneOffsets = {
    CST: -6 * 60, // Central Standard Time
    PST: -8 * 60, // Pacific Standard Time
    EST: -5 * 60, // Eastern Standard Time
    MST: -7 * 60, // Mountain Standard Time
    // Add more time zones as needed
  };

  // Calculate the time difference in minutes
  const timeDifference =
    timeZoneOffsets[targetTimeZone] - timeZoneOffsets[sourceTimeZone];

  // Apply the time difference to the input time
  const convertedHours = (hours + Math.floor(timeDifference / 60) + 24) % 24;
  const convertedMinutes = (minutes + (timeDifference % 60) + 60) % 60;

  // Format the result
  const convertedTime = `${convertedHours
    .toString()
    .padStart(2, "0")}:${convertedMinutes.toString().padStart(2, "0")}`;

  return convertedTime;
};

export const AvailableDayRow = ({
  availability,
  toggleAvailabilityDay,
  connectionProp,
}: {
  availability: Availability;
  toggleAvailabilityDay: (availability: Availability) => void;
  connectionProp?: boolean; // Add prop for smaller size
}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const start = convertTo12HourFormat(
    convertTimeWithoutDate(
      availability.start_time,
      availability.time_zone,
      mapTimeZoneToInfo(timeZone)?.abbreviation
    )
  );
  const end = convertTo12HourFormat(
    convertTimeWithoutDate(
      availability.end_time,
      availability.time_zone,
      mapTimeZoneToInfo(timeZone)?.abbreviation
    )
  );

  return (
    <Container>
      <RowContainer
        connectionProp={connectionProp}
        onClick={() => {
          toggleAvailabilityDay(availability);
        }}
      >
        {connectionProp ? (
          <div style={{display:"flex", alignItems:"center", border:'1px solid #aaa', borderRadius:"5px"}}>
            <p style={{ margin: 0 }}>{GetDayOfWeekDisplayName(availability).display_name}</p>
            <p style={{ whiteSpace: "nowrap", margin: 0, paddingLeft:"5px" }}> {start} - {end}</p>
          </div>
        ) : (
          <>
            <Col lg={3}>
              <h6 className='mr-2 mb-0'>
                {GetDayOfWeekDisplayName(availability).display_name}
              </h6>
            </Col>
            <Col>
              <p>
              {start} - {end}
              </p>
            </Col>
          </>
        )}
      </RowContainer>
    </Container>
  );
};

const CardContainer = styled(Card)`
  max-width: 250px;
  margin: 10px 5px;
  cursor: pointer;
  box-shadow: -1px 2px 2px 2px #eee;
  -webkit-box-shadow: -1px 2px 2px 2px #eee;
  -moz-box-shadow: -1px 2px 3px 3px #eee;
  &:hover {
    box-shadow: -1px 2px 4px 4px #ddd;
    -webkit-box-shadow: -1px 2px 4px 4px #ddd;
    -moz-box-shadow: -1px 2px 4px 4px #ddd;
  }
`;

const RowContainer = styled(Row)`
  border-top: ${props => props.connectionProp ? null : '1px solid #aaa' }; 
  padding-top: ${props => props.connectionProp ? null : '20px'}; 
  padding-bottom: ${props => props.connectionProp ? null : '10px'}; 
  cursor:${props => props.connectionProp ? null : 'pointer'};
  &:hover {
    background-color: ${(props) => (props.connectionProp ? "inherit" : "rgba(55, 133, 191, 0.84)")};
    color: ${(props) => (props.connectionProp ? "inherit" : "white")};
  }
`;
