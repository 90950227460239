/**
 * Connections Chart Body File.
 *
 * created by Ghameerah McCullers 09/13/2023
 */
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { decodeJWTBearer } from "../utils/decodeJWT";
import { deleteCookie, getCookie } from "../utils/cookies";
import { useNavigate } from "react-router-dom";
import { useData } from "../utils/context/DataContext";
import { NavBarAlt } from "../ui/navbar/NavbarAlt";
import ConnectionRequestTable from "../ui/table/ConnectionRequestTable";
import SessionRequestContainer from "../ui/table/SessionRequestContainer";
import styled from "styled-components";

export default function ManageSessions() {
  const [isLoading, setIsLoading] = useState(false);
  const { setData, data } = useData();
  const navigate = useNavigate();

  const handleIsSignedIn = async () => {
    setIsLoading(true);

    if (data) {
      setIsLoading(false);
      return;
    }

    const mentorToken = await getCookie("mentor-token");

    if (mentorToken) {
      try {
        const jwtUser = decodeJWTBearer(mentorToken);
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${jwtUser.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(userData);
        setTimeout(() => {
          if (userData.isAdmin) {
            navigate("/admin");
          }
          if (!userData.account_verified) {
            navigate("/");
          } else {
            setIsLoading(false);
          }
        }, 500);
      } catch (e) {
        console.log("error using mentor token");
        setIsLoading(false);
        deleteCookie("mentor-token");
        navigate("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/");
      }, 600);
    }
  };

  useEffect(() => {
    handleIsSignedIn();
  }, []);

  return (
    <div
      style={{
        // backgroundColor: "#006B1",
        // backgroundImage: `url(${process.env.PUBLIC_URL}/Site_Backdrop.png)`,
        // backgroundSize: "cover",
        // backgroundAttachment: "fixed",
        backgroundPosition: "top",
        minHeight: "100vh",
      }}
    >
      <NavBarAlt />
      <div style={{ overflowX: "hidden" }}>
        <PageContainer>
          <Row style={{ margin: "50px 0px 0px 0px" }}>
            {/* Dashboard intro */}
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <img src={logo} width="300" height="300" alt="frontend-app" /> */}
                {/* <div style={{ marginTop: 15 }}>
                  <h1>Sessions</h1>
                </div> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div>
                <SessionRequestContainer
                  userId={data?.id}
                  userType={data?.type}
                />
              </div>
            </Col>
          </Row>
        </PageContainer>
      </div>
    </div>
  );
}

// Styled Components
const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  color: "grey",
  paddingRight: "200px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const dateColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const emailColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const buttonColumnStyle = {
  paddingRight: "90px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const editColumnStyle = {
  paddingLeft: "75px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  paddingLeft: "60px",
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;
