import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { RowAlignedContainer } from "../ui/BaseComponents";
import { FloatingContainer } from "../ui/BaseComponents";
import MentorStateConnection from "../Mentor/MentorStateConnection";
import MenteeStateConnection from "../Mentee/MenteeStateConnection";

interface ConnectionCardProps {
  mentor_id: number;
  mentee_id: number;
}

export default function ConnectionCard(p: ConnectionCardProps) {
  return (
    <FloatingContainer>
      <Container style={{ justifyContent: "space-evenly" }}>
        <Row>
          <Col md={5}>
            <h6 style={{ color: "rgb(0, 119, 181)" }}>Mentor</h6>
          </Col>
          <Col md={2}></Col>
          <Col md={5}>
            <h6 style={{ color: "rgb(0, 119, 181)" }}>Mentee</h6>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <MentorStateConnection mentor_id={p.mentor_id} />
          </Col>
          <Col md={2}>
            <CardIconContainer className='mr-2'>
              <FontAwesomeIcon
                icon={faHandshake}
                color='#1170b8'
                style={{ fontSize: 38 }}
              />
            </CardIconContainer>
          </Col>
          <Col md={5}>
            <MenteeStateConnection mentee_id={p.mentee_id} />
          </Col>
        </Row>
      </Container>
    </FloatingContainer>
  );
}

const CardIconContainer = styled("div")`
  background-color: rgb(163 228 255);
  display: flex;
  align-items: center;
  border-radius: 360px;
  height: 63px;
  width: 63px;
  justify-content: center;
  margin-right: 20px;
`;
