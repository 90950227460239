import axios from "axios";

import { ReviewForm } from "../../components/Appointment/AppointmentReview";

const CreateReview = async (reviewData: ReviewForm) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/review`,
    {
      method: "POST",
      data: reviewData,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
const GetReviewById = async (id: string | number) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/review/${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export { CreateReview, GetReviewById };
