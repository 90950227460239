import axios from "axios";
interface UserConnections {}

/***
 * Get connections for a user
 */
const getConnections = async (userId: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/user/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  // await setCurrentUserProfileConnectionsData(connectionsGetData);
  // setIsLoadingCurrentUserProfileConnectionsData(false);
  return data;
};

/***
 * Get connection relationships between mentor and mentee by ids
 */
const getConnection = async (searchBody: {
  mentor_id: string;
  mentee_id: String;
}) => {
  // setIsLoadingConnection(true);
  //   const postData =
  //     data.type === "MENTOR"
  //       ? { mentor_id: data.id, mentee_id: currentUserProfileData?.id }
  //       : { mentor_id: currentUserProfileData?.id, mentee_id: data.id };
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/search`,
    {
      method: "POST",
      data: searchBody,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export { getConnections, getConnection };
