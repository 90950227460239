import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { UserData } from "../Mentor/interfaces";
import { UserCardFilter } from "../Common/FilterUserCards";
export interface UserDataWFav extends UserData {
  num_favorites: string;
}
export const MostFav = () => {
  const [mentors, setMentors] = useState<UserDataWFav[]>([]);
  const handleFetchMostFav = async () => {
    try {
      const { data, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/metrics/favorites`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setMentors(data);
    } catch (e) {}
  };

  useEffect(() => {
    handleFetchMostFav();
  }, []);

  return (
    <Container fluid>
      <div>
        <div style={{ marginBottom: 20 }}>
          <h3>Favorite Mentors</h3>
          <h6>8 Mentors with the most favorites</h6>
        </div>
      </div>
      <div style={{ marginTop: 50 }}>
        <UserCardFilter
          favs={true}
          isAdmin={true}
          users={mentors}
          type='MENTOR'
        />
      </div>
    </Container>
  );
};
