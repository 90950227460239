/**
 * Connections Chart Body File.
 *
 * created by Ghameerah McCullers 09/13/2023
 */
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { decodeJWTBearer } from "../utils/decodeJWT";
import { deleteCookie, getCookie } from "../utils/cookies";
import { useNavigate } from "react-router-dom";
import { useData } from "../utils/context/DataContext";
import { NavBarAlt } from "../ui/navbar/NavbarAlt";
import ConnectionRequestTable, {
  Connection,
} from "../ui/table/ConnectionRequestTable";
import styled from "styled-components";
import { MentorFilterContainer } from "../Mentor";
import { Connections } from "./UserProfile";
import { ConnectionMetric } from "./Analytics";
import {
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Bar,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import ConnectionsByStateMap from "../ui/map/ConnectionsByStateMap";

export default function ManageConnections() {
  const [isLoading, setIsLoading] = useState(false);
  const { setData, data } = useData();
  const navigate = useNavigate();
  const [connections, setConnections] = useState<Connection[]>([]);
  const [connectionMetrics, setConnectionMetrics] = useState<
    ConnectionMetric | undefined
  >(undefined);

  const handleIsSignedIn = async () => {
    setIsLoading(true);

    if (data) {
      handleFetchConnectionsForUser(data.id);
      handleFetchConnectionMetrics(data);
      return;
    }

    const mentorToken = await getCookie("mentor-token");

    if (mentorToken) {
      try {
        const jwtUser = decodeJWTBearer(mentorToken);
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${jwtUser.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(userData);
        setTimeout(() => {
          if (userData.isAdmin) {
            navigate("/admin");
          }
          if (!userData.account_verified) {
            navigate("/");
          } else {
            handleFetchConnectionsForUser(userData.id);
            handleFetchConnectionMetrics(userData);
          }
        }, 500);
      } catch (e) {
        console.log("error using mentor token");
        setIsLoading(false);
        deleteCookie("mentor-token");
        navigate("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/");
      }, 600);
    }
  };

  const handleFetchConnectionsForUser = async (id: number) => {
    setIsLoading(true);
    try {
      const { data: connectionData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/user/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setConnections(connectionData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleFetchConnectionMetrics = async (user) => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/metrics/connections-approved-denied/user/${user.type}/${user.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);

      setConnectionMetrics(data);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const acceptedConnections = connections.filter(
    (connection) => connection?.status === "ACCEPTED"
  );
  const requestedConnections = connections.filter(
    (connection) => connection?.status === "REQUESTED"
  );
  const deniedConnections = connections.filter(
    (connection) => connection?.status === "DENIED"
  );

  const allConnections = [...acceptedConnections, ...requestedConnections, ...deniedConnections]

  const barChartConnectionData = [
    // {
    //   name: "Feb '14",
    //   approved: connectionMetrics?.approvedMonthly?.[1]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar '14",
    //   approved: connectionMetrics?.approvedMonthly?.[2]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[2]?.length ?? 0,
    // },
    // {
    //   name: "Apr",
    //   approved: connectionMetrics?.approvedMonthly?.[3]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[3]?.length ?? 0,
    // },
    // {
    //   name: "May",
    //   approved: connectionMetrics?.approvedMonthly?.[4]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[4]?.length ?? 0,
    // },
    // {
    //   name: "Jun",
    //   approved: connectionMetrics?.approvedMonthly?.[5]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[5]?.length ?? 0,
    // },
    // {
    //   name: "Jul",
    //   approved: connectionMetrics?.approvedMonthly?.[6]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[6]?.length ?? 0,
    // },
    // {
    //   name: "Aug",
    //   approved: connectionMetrics?.approvedMonthly?.[7]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[7]?.length ?? 0,
    // },
    // {
    //   name: "Sep 2023",
    //   approved: connectionMetrics?.approvedMonthly?.[8]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[8]?.length ?? 0,
    // },
    {
      name: "Oct 2023",
      approved: connectionMetrics?.approvedMonthly?.[9]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[9]?.length ?? 0,
    },
    {
      name: "Nov 2023",
      approved: connectionMetrics?.approvedMonthly?.[10]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[10]?.length ?? 0,
    },
    {
      name: "Dec 2023",
      approved: connectionMetrics?.approvedMonthly?.[11]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[11]?.length ?? 0,
    },
    {
      name: "Jan 2024",
      approved: connectionMetrics?.approvedMonthly?.[0]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[0]?.length ?? 0,
    },
    {
      name: "Feb 2024",
      approved: connectionMetrics?.approvedMonthly?.[1]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[1]?.length ?? 0,
    },
  ];

  useEffect(() => {
    handleIsSignedIn();
  }, []);

  return (
    <div
      style={{
        // backgroundColor: "#006B1",
        // backgroundImage: `url(${process.env.PUBLIC_URL}/Site_Backdrop.png)`,
        // backgroundSize: "cover",
        // backgroundAttachment: "fixed",
        // backgroundPosition: "top",
        minHeight: "100vh",
      }}
    >
      <NavBarAlt />
      <div style={{ overflowX: "hidden" }}>
        <PageContainer>
          <Row style={{ margin: "50px -15px 10px -15px" }}> 
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <img src={logo} width="300" height="300" alt="frontend-app" /> */}
                <div style={{ marginTop: 15, marginLeft: "15px", marginBottom:"-10px" }}>
                  <h1 style={{ marginBottom:'20px', fontSize:"1.5em", fontWeight:"bold" }}>Connections<span style={{marginLeft:"5px"}}>({acceptedConnections.length})</span></h1>
                </div>
              </div>
            </Col>
          </Row>
          {isLoading || !data ? (
            <Spinner />
          ) : (
            <>
              {/* <Row>
                <Col md={9}>
                  <FloatingContainer>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="mr-1" style={{ fontSize: "1.3em" }}>
                        <CardIconContainer className="mr-2">
                          <FontAwesomeIcon
                            icon={faHandshake}
                            color="#1170b8"
                            style={{ fontSize: 38 }}
                          />
                        </CardIconContainer>
                      </div>
                      <h2
                        style={{
                          borderColor: "rgb(0, 119, 181)",
                          color: "rgb(0, 119, 181)",
                          backgroundColor: "white",
                          padding: 5,
                          borderRadius: 9,
                          borderWidth: 1,
                          fontSize: "1.5em",
                          borderStyle: "solid",
                          width: "fit-content",
                        }}
                      >
                        Connections
                      </h2>
                    </div>
                    <Row>
                      <Col md={5}>
                        <Connections
                          connections={[...acceptedConnections]}
                          userType={data.type}
                          filter="ACCEPTED"
                          isLoading={false}
                        />
                      </Col>

                     <Col
                        style={{
                          height: 400,
                          // width: 650,
                          marginLeft: "auto",
                          position: "relative",
                          left: -25,
                        }}
                        md={7}
                      >
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart
                            width={500}
                            height={300}
                            data={barChartConnectionData}
                            margin={{
                              top: 20,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="approved"
                              stackId="a"
                              fill="#1170b8"
                            />
                            <Bar dataKey="denied" stackId="a" fill="#ff7701" />
                          </BarChart>
                        </ResponsiveContainer>
                      </Col>
                    </Row>
                  </FloatingContainer>
                </Col>

                <Col lg={3}>
                  <FloatingContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h6
                      style={{
                        backgroundColor: "white",
                        color: "rgb(0, 119, 181)",
                        padding: 5,
                        borderRadius: 9,
                        borderWidth: 1,
                        fontSize: "1.2em",
                        borderStyle: "solid",
                      }}
                    >
                      Connection Requests
                    </h6>
                    <h5
                      style={{
                        backgroundColor: "rgb(0, 119, 181)",
                        color: "white",
                        padding: 5,
                        borderRadius: 9,
                        borderWidth: 1,
                        fontSize: "1.8em",
                        borderStyle: "solid",
                        width: "fit-content",
                      }}
                    >
                      {requestedConnections.length}
                    </h5>
                    <i>pending connection requests</i>
                  </FloatingContainer>

                  <FloatingContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h6
                      style={{
                        backgroundColor: "white",
                        color: "rgb(0, 119, 181)",
                        padding: 5,
                        borderRadius: 9,
                        borderWidth: 1,
                        fontSize: "1.2em",
                        borderStyle: "solid",
                      }}
                    >
                      Connections
                    </h6>
                    <h5
                      style={{
                        backgroundColor: "rgb(0, 119, 181)",
                        color: "white",
                        padding: 5,
                        borderRadius: 9,
                        borderWidth: 1,
                        fontSize: "1.8em",
                        borderStyle: "solid",
                        width: "fit-content",
                      }}
                    >
                      {acceptedConnections.length}
                    </h5>
                    <i>network connections</i>
                  </FloatingContainer>
                </Col>
              </Row> */}
              <Row>
                <FloatingContainer>
                  <ConnectionRequestTable
                    userType={data?.type}
                    connections={connections}
                    setConnections={setConnections}
                    isLoading={isLoading}
                  />
                </FloatingContainer>
              </Row>
              <Row>
                <h1>Connections by State</h1>
                <ConnectionsByStateMap viewOnly />
              </Row>
            </>
          )}
        </PageContainer>
      </div>
    </div>
  );
}

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;

// Styled Components
const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  color: "grey",
  paddingRight: "200px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const dateColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const emailColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const buttonColumnStyle = {
  paddingRight: "90px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const editColumnStyle = {
  paddingLeft: "75px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  paddingLeft: "60px",
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};

const FloatingContainer = styled("div")`
  // margin: 40px 0px;
  // background-color: white;
  // padding: 25px 20px;
  // border-radius: 9px;
  // border-color: rgba(55, 133, 191, 0.94);
  // border-width: 2px;
  // border-radius: 9px;
  // background-color: rgb(238 238 238 / 92%);
  // color: black;
  // box-shadow: -1px 2px 2px 2px #888;
  // -webkit-box-shadow: -1px 2px 2px 2px #888;
  // -moz-box-shadow: -1px 2px 3px 3px #888;
`;

const UserMetricsRow = styled(Row)`
  width: 100%;
  padding-bottom: 20px;
`;

const CardIconContainer = styled("div")`
  background-color: rgb(163 228 255);
  display: flex;
  align-items: center;
  border-radius: 360px;
  height: 63px;
  width: 63px;
  justify-content: center;
  margin-right: 20px;
`;
