import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";

enum ConnectionStatus {
  ACCEPTED = "ACCEPTED",
  DENIED = "DENIED",
}

interface Connection {
  id: string;
  mentor_id: string;
  mentee_id: string;
  created_date: string;
  last_edited_date: string;
  status: string;
  mentee_first_name: string;
  mentee_last_name: string;
  mentee_email: string;
  mentee_profile_image: string;
  mentor_first_name: string;
  mentor_last_name: string;
  mentor_email: string;
  mentor_profile_image: string;
}

interface ConnectionStatusButtonProps {
  connectionId: string;
  title: string;
  status: string;
  updateConnection: (id: string, status: string) => void;
}

export default function ConnectionStatusButton(p: ConnectionStatusButtonProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [connection, setConnection] = useState<(Connection | undefined)[]>([]);

  const updateConnectionStatus = async (id: string) => {
    try {
      if (p.status === "DENIED") {
        const answer = confirm(
          "Are you sure you want to remove this connection?"
        );
        if (!answer) return;
      }
      p.updateConnection(p.connectionId, p.status);
      const { data } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${id}`,
        {
          method: "PATCH",
          data: {
            status: p.status,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setConnection(data);
      console.log("Updated!");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  return (
    <Button
      variant={p.status === ConnectionStatus.ACCEPTED ? "success" : "danger"}
      onClick={() => updateConnectionStatus(p.connectionId)}
    >
      {p.title}
    </Button>
  );
}
