/**
 * Connections Requests Table.
 *
 * created by Ty D'Angelo 10/19/23
 */

import { Col, Container, Row, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import DeleteSessionButton from "../button/DeleteSessionButton";
import SessionStatusButton from "../button/SessionStatusButton";
import {
  GetAppointmentsByMentee,
  GetAppointmentsByMentor,
} from "../../../apis/Appointment/Appointment";
import {
  AppointmentRequest,
  VIRTUAL_OPTION_VALUES,
  getMeetingTypeDisplayName,
} from "../../SetAvailability/AppointmentForm";
import {
  GetDayOfWeekDisplayNameFromDate,
  convertTo12HourFormat,
  monthIndexToAbbreviation,
} from "../../SetAvailability/AvailableDayCard";
import CancelSessionButton from "../button/CancelSessionButton";
import { AppointmentCard } from "../../Appointment/AppointmentCard";
import { AppointmentContainer } from "../../Appointment/AppointmentContainer";
import styled from "styled-components";

enum UserType {
  MENTOR = "MENTOR",
  MENTEE = "MENTEE",
}

interface SessionRequestContainerProps {
  userId: string | number;
  userType: UserType;
}

export interface Session extends AppointmentRequest {
  id: string;
  status: string;
  created_date: Date;
  last_edited_date: Date;
  mentor_first_name: string;
  mentor_last_name: string;
  mentee_first_name: string;
  mentee_last_name: string;
  mentor_email: string;
  mentee_email: string;
  mentor_profile_image: string;
  mentee_profile_image: string;
  mentee_review_id: string;
  mentor_review_id: string;
}
export const getConnectionDetails = (sesh: Session) => {
  const meeting_type = sesh.meeting_type ?? "";

  if (VIRTUAL_OPTION_VALUES.includes(meeting_type)) {
    return (
      <p>
        <a href={`${sesh.meeting_link}`} target='_blank'>
          {sesh.meeting_link}
        </a>
      </p>
    );
  }

  if (meeting_type === "phone") {
    return <p>sesh.phone_number</p>;
  }

  if (meeting_type === "inPerson") {
    return <p>sesh.meeting_location</p>;
  }
  return "";
};
export default function SessionRequestContainer(
  p: SessionRequestContainerProps
) {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchSessionsForUser = async (id: string | number) => {
    setIsLoading(true);
    try {
      const sessionData: Session[] =
        p.userType === UserType.MENTOR
          ? await GetAppointmentsByMentor(id)
          : await GetAppointmentsByMentee(id);
      setSessions(sessionData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };
  const reFetchSessions = (connectionId: string) => {
    const updatedSessions = sessions.filter(
      (connection) => connection.id !== connectionId
    );
    setSessions(updatedSessions);
  };

  const updateSession = (id: string, status: string) => {
    const updatedSessions = sessions.map((connection) => {
      if (connection.id === id) {
        connection.status = status;
      }
      return connection;
    });

    setSessions(updatedSessions);
  };

  useEffect(() => {
    if (!isLoading && p.userId) handleFetchSessionsForUser(p.userId);
  }, [p.userId]);

  const acceptedSessions = sessions.filter(
    (connection) => connection?.status === "ACCEPTED"
  );

  const requestedSessions = sessions.filter(
    (connection) => connection?.status === "PENDING"
  );
  const deniedSessions = sessions.filter(
    (connection) =>
      connection?.status === "DENIED" || connection?.status === "CANCELLED"
  );

  const completedSessions = sessions.filter(
    (connection) => connection?.status === "COMPLETED"
  );

  const [value, setValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event) => {
    setValue(event.target.value);

    if (event.target.value === "Date") {
      const sortedData = [...sessions].sort((a, b) => {
        return sortAscending
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      });
      setSessions(sortedData);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={9}>
              <AppointmentContainer
                type='upcoming'
                appointments={acceptedSessions}
              />
          </Col>
        </Row>
        <Row>
          <Col lg={9}>
              <AppointmentContainer
                type='previous'
                appointments={completedSessions}
              />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Styled Components
const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const dateColumnStyle = {
  // padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const emailColumnStyle = {
  // paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const buttonColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const editColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};

const FloatingContainer = styled("div")`
  margin: 40px 0px;
  background-color: white;
  padding: 25px 20px;
  border-radius: 9px;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 92%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
`;
