/**
 * First Signup page on home page
 *
 * created by Ty D'Angelo 9/10/2023
 */

import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Title from "../../ui/title/Title";
import Description from "../../ui/description/Description";
import CardComponent from "../../SignUp/card/CardComponent";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUserGraduate,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import two from "./two.jpeg";
import girls from "./girls.jpeg";
import backdrop from "./Site_Backdrop.png";
import { HomeNavbar } from "../../ui/navbar/HomeNavbar";

export default function SignUpOne() {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const playVideo = () => {
    if (videoRef.current) {
      const heroTextRef = document.getElementById("hero-content");
      const heroImageRef = document.getElementById("HeroImage");

      // hide the hero pricture and text
      heroTextRef?.classList.add("hide");
      heroImageRef?.classList.add("hide");

      videoRef.current.style.display = "block";
      videoRef.current.classList.remove("hide");
      videoRef.current.play();
      videoRef.current.setAttribute("controls", "true");
    }
  };

  return (
    <div>
      <SectionOneContainer fluid>
        <Row>
          <Col lg={12} style={{ padding: 0, position: "relative" }}>
            <HomeNavbar />
            <HeroImage
              id="HeroImage"
              src={`${process.env.PUBLIC_URL}/guy.jpg`}
            />
            <VideoTag className="hide" ref={videoRef} controls={false} loop>
              <source
                src={`${process.env.PUBLIC_URL}/PREP.V3.mp4`}
                type="video/mp4"
              />
            </VideoTag>
            <FloatingSectionContainer id={"hero-content"}>
              <div style={{ marginBottom: 20 }}>
                <Title
                  message="EMPOWER. GUIDE. THRIVE."
                  fontWeight="900"
                  fontSize={"2.5em"}
                />
                <MobileColorWhite>
                  <Description
                    message="Find Your Mentor Today"
                    fontSize={"2.15em"}
                  />
                </MobileColorWhite>
                <div style={{ marginTop: 20 }}>
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: 900,
                    }}
                    onClick={playVideo}
                  >
                    <FontAwesomeIcon
                      color="rgb(0, 103, 177)"
                      size="xl"
                      icon={faPlayCircle}
                    />
                    <span style={{ marginLeft: 10 }}>CLICK TO WATCH VIDEO</span>
                  </Button>
                </div>
              </div>
            </FloatingSectionContainer>
          </Col>
        </Row>
      </SectionOneContainer>

      <SectionTwoContainer
        style={{
          backgroundColor: "#006B1",
          backgroundImage: `url(${backdrop})`,
          backgroundSize: "cover",
        }}
        fluid
      >
        <Row>
          <Col lg={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageContainer>
                <ImageImage
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/dudes.jpg)`,
                  }}
                ></ImageImage>
                <ImageText>
                  <h1>Empower</h1>
                </ImageText>
              </ImageContainer>
              <ImageContainer>
                <ImageTextRight>
                  <h1>Guide</h1>
                </ImageTextRight>
                <ImageImage
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/meeting8.jpg)`,
                  }}
                ></ImageImage>
              </ImageContainer>
              <ImageContainer>
                <ImageImage
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/grad.jpg)`,
                  }}
                ></ImageImage>
                <ImageText>
                  <h1 className="text-center">Thrive</h1>
                </ImageText>
              </ImageContainer>
            </div>
          </Col>
          <FormInContainer lg={5} id="sign-up">
            <CardComponenetContainer>
              <CardComponent />
            </CardComponenetContainer>
          </FormInContainer>
        </Row>
      </SectionTwoContainer>

      <SectionThreeContainer fluid id="more">
        <Row style={{ marginTop: 50 }}>
          <Col lg={2} className="hide-on-mobile"></Col>
          <LeftMarginCol lg={5} style={{ color: "#0067B1" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Title message="Mentors" fontWeight="400" fontSize={"2.0em"} />
              <div style={{ marginLeft: 15 }}>
                <FontAwesomeIcon icon={faUsers} fontSize={40} />
              </div>
            </div>
            <Description
              message="Join us as a mentor and make a meaningful impact"
              fontSize={"1.5em"}
            />
            <a href="#sign-up">
              <StyledButton style={{ marginTop: 30 }}>Sign Up</StyledButton>
            </a>
            <Description
              message="Whether you're an entrepreneur, a subject matter expert, or someone passionate about mentoring, you can now share your expertise with others."
              fontSize={"1.5em"}
            />
          </LeftMarginCol>
          <MarginCol lg={4}>
            <SectionThreeImages src={two} />
          </MarginCol>
        </Row>
        <Row style={{ marginTop: 250 }} id="testimonials">
          <Col lg={1}></Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            lg={4}
          >
            <SectionThreeImages src={girls} />
          </Col>
          <RightContentDiv lg={5} style={{ color: "#0067B1" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Title message="Students" fontWeight="400" fontSize={"2.0em"} />
              <div style={{ marginLeft: 15 }}>
                <FontAwesomeIcon icon={faUserGraduate} fontSize={40} />
              </div>
            </div>
            <Description
              message="Students who attend Fayetteville State University, log in to validate your account and gain access to a curated selection of verified mentors waiting to assist you."
              fontSize={"1.5em"}
            />
            <a href="#sign-up">
              <StyledButton style={{ marginTop: 30 }}>
                Sign In and verify your account
              </StyledButton>
            </a>
          </RightContentDiv>
        </Row>
      </SectionThreeContainer>
    </div>
  );
}
const StyledButton = styled(Button)`
  background-color: #ff7701;
  color: white;
  border-color: #eee;
  &:hover {
    background-color: #dd6905;
    color: white;
  }
`;
export const ColumnAlignedContainer = styled("div")`
  display: flex;
  flex-direction: column;
  padding-right: 100px;

  /* Conditional styling for mobile devices */
  @media (max-width: 768px) {
    padding: 10px !important;
  }
`;

export const ColorCol = styled(Col)`
  /* Conditional styling for mobile devices */
  @media (max-width: 768px) {
    background-color: white;
  }
`;

export const HideOnMobile = styled("div")`
  /* Conditional styling for mobile devices */
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FormInContainer = styled(Col)`
  z-index: 1;
  @media (max-width: 1200px) {
    position: relative;
    left: 90px;
  }
  @media (max-width: 1000px) {
    position: relative;
    left: 0px;
  }
`;

export const ImageContainer = styled("div")`
  /* Conditional styling for mobile devices */
  margin: 50px 0px;
  display: flex;
  align-items: center;
`;

export const CardComponenetContainer = styled("div")`
  /* Conditional styling for mobile devices */
  @media (max-width: 768px) {
    margin: 0px !important;
  }
`;

export const SectionOneContainer = styled(Container)`
  /* Conditional styling for mobile devices */
  margin: 0px !important;
  background-color: rgb(0, 103, 177);
  min-height: 650px;
  @media (max-width: 768px) {
    margin: 0px !important;
    padding: 200px 20px;
  }
`;

export const SectionTwoContainer = styled(Container)`
  padding: 50px 20px;
  /* Conditional styling for mobile devices */
  @media (max-width: 768px) {
    margin: 0px !important;
    padding: 150px 20px 100px 20px;
  }
`;

export const SectionThreeContainer = styled(Container)`
  background-color: white;
  color: white;
  padding: 100px 20px;
`;

export const FloatingSectionContainer = styled("div")`
  position: absolute;
  top: 40%;
  left: 67%;
  transform: translate(-50%, -50%);
  color:#ff7701;
  z-index: 1;
  text-shadow: 4px 2px 3px rgb(0, 53,100);
  @media (max-width: 768px) {
    color: rgb(244 255 61);
    position: absolute;
    top: 330px !important;
    left: 40% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1;!important;
  }
`;

export const MarginCol = styled(Col)`
  margin-left: 80px !important;
  display: flex;
  @media only screen and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 1) {
    display: flex;
    margin-left: 0px !important;
    justify-content: center;
  }
`;

const LeftMarginCol = styled(Col)`
  @media only screen and (min-device-width: 850px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    margin-left: 50px !important;
  }
`;

export const SectionThreeImages = styled("img")`
  width: 400px;
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  color: #eee;
  @media (max-width: 768px) {
    width: 300px !important;
    margin: 10px 0px;
  }
`;

export const VideoTag = styled("video")`
  width: 100%;
  @media (max-width: 768px) {
    display: none;
    position: relative;
    top: 50px;
  }
`;

export const MobileColorWhite = styled("div")`
  color: #ff7701; !important;
  text-shadow: 1px 1px 1px rgb(0, 53, 100);
  @media (max-width: 768px) {
    color: white !important;
  }
`;

export const HeroImage = styled("img")`
  width: 100%;
`;

export const ImageImage = styled("div")`
  border-style: solid;
  color: #eee;
  z-index: 1;
  border-radius: 360px;
  overflow: hidden;
  width: 300px;
  height: 300px;
  background-size: cover;
  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

export const ImageText = styled("div")`
  background-color: white;
  height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-radius: 60px;
  position: relative;
  left: -75px;
  width: 400px;
  border-color: #aaa;
  padding-left: 20px;
  justify-content: center;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    width: 270px;
    height: 120px;
  }
`;

export const ImageTextRight = styled("div")`
  background-color: white;
  height: 150px;
  display: flex;
  align-items: center;
  text-align: center;
  border-style: solid;
  border-color: #aaa;
  border-radius: 60px;
  position: relative;
  right: -75px;
  width: 400px;
  padding-right: 20px;
  justify-content: center;
  letter-spacing: 2px;
  @media (max-width: 768px) {
    width: 270px;
    height: 120px;
  }
`;

export const RightContentDiv = styled(Col)`
  @media only screen and (min-device-width: 850px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    margin-left: 100px;
  }
`;
