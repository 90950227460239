import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import ConnectionList from "../Admin/ConnectionsList";
import { UserData } from "../Mentor/interfaces";
import axios from "axios";
import { NavbarAdmin } from "../ui/navbar/NavbarAdmin";
import { UserCardFilter } from "../Common/FilterUserCards";
import VerifiedList from "../Admin/VerifiedList";
import { PushMessage } from "../Admin/PushMessage";
import { MostFav } from "../Admin/MostFav";

export default function AdminDashboard() {
  const [key, setKey] = useState<string>();
  const [nonApprovedMentors, setNonApprovedMentors] = useState<UserData[]>([]);
  const [mentors, setMentors] = useState<UserData[]>([]);
  const [mentees, setMentees] = useState<UserData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchNonApprovedMentors = async () => {
    try {
      setIsLoading(true);
      const { data: mentorData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/mentors?approved=false`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setNonApprovedMentors(mentorData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleFetchMentors = async () => {
    try {
      setIsLoading(true);
      const { data: mentorData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/mentors?approved=true`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMentors(mentorData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleFetchMentees = async () => {
    try {
      setIsLoading(true);
      const { data: mentorData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/mentee`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMentees(mentorData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    handleFetchNonApprovedMentors();
    handleFetchMentors();
    handleFetchMentees();
  }, []);

  return (
    <>
      <NavbarAdmin />
      <PageContainer>
        <Row>
          <Col lg={12}>
            <h2>Admin Dashboard</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Tabs
              id='controlled-tab-example'
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className='mb-3'
            >
              <Tab
                eventKey='approvals'
                title={`Approvals ${
                  nonApprovedMentors.length > 0 ? nonApprovedMentors.length : ""
                }`}
              >
                <div style={{ padding: 20 }}>
                  <h6>
                    Below are a list of Mentor accounts that require your
                    approval
                  </h6>
                  <Row style={{ paddingBottom: 40 }}>
                    {nonApprovedMentors.length === 0 ? (
                      <div className='pt-5 pl-5'>
                        <h6>No approvals required</h6>
                      </div>
                    ) : (
                      <UserCardFilter
                        users={nonApprovedMentors}
                        type='MENTOR'
                        isAdmin={true}
                      />
                    )}
                  </Row>
                </div>
              </Tab>
              <Tab eventKey='Mentors' title={`Mentors`}>
                <div style={{ padding: 20 }}>
                  <h6>Mentor accounts that have been approved</h6>
                  <Row style={{ paddingBottom: 40 }}>
                    <UserCardFilter
                      isAdmin={true}
                      users={mentors}
                      type='MENTOR'
                    />
                  </Row>
                </div>
              </Tab>
              <Tab eventKey='Mentee' title={`Students`}>
                <div style={{ padding: 20 }}>
                  <h6>
                    Student Mentee accounts currently signed up and verified
                  </h6>
                  <Row style={{ paddingBottom: 40 }}>
                    <UserCardFilter users={mentees} type='MENTEE' />
                  </Row>
                </div>
              </Tab>
              <Tab eventKey='connections' title='Connections'>
                <ConnectionList isAdmin={true} />
              </Tab>
              <Tab eventKey='verified' title='Needs Verification'>
                <VerifiedList isAdmin={true} />
              </Tab>
              <Tab eventKey='pushMessage' title='Push Message'>
                <PushMessage checkVerified={false} />
              </Tab>
              <Tab eventKey='favs' title='Favorite Mentors'>
                <MostFav />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
}

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;

const RowContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;
