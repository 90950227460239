import React from "react";
import { DataProvider } from "../src/components/utils/context/DataContext";
import { BrowserRouter } from "react-router-dom";
import FrontendApp from "./FrontendApp";

export default function App() {
  return (
    <DataProvider>
        <BrowserRouter>
            <FrontendApp />
        </BrowserRouter>
    </DataProvider>
  );
}
