import { Button } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import React from "react";

export const FileUploader = ({
  selectedFile,
  setSelectedFile,
  setSelectedFileFormData,
  data,
  isLoading = false,
}) => {
  const [fileName, setFileName] = useState("");

  const removeFile = () => {
    setSelectedFile(null);
    setSelectedFileFormData(null);
    setFileName("");
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      setFileName(file.name);
      formData.append("file", file, `resume-${data.id}.pdf`);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
        setSelectedFileFormData(formData);
      };
      reader.readAsDataURL(file);
    }
  };

  if (isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className='spinner-border text-primary'></div>
      </div>
    );
  }

  return (
    <>
      <div>
        {selectedFile ? (
          <div style={{ backgroundColor: "#eee", width: "auto", padding: 10 }}>
            <span className='text-success'>Resume Uploaded</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 10,
              }}
            >
              <span>{fileName}</span>

              <Button
                variant='outline-danger'
                style={{ marginLeft: 20 }}
                onClick={removeFile}
              >
                remove
              </Button>
            </div>
          </div>
        ) : (
          <input
            type='file'
            accept='application/pdf'
            onChange={handleImageChange}
            style={{
              backgroundColor: "#eee",
              display: "flex",
              padding: 10,
            }}
          />
        )}
      </div>
    </>
  );
};
