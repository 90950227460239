import React, {useState} from 'react';
import './HelpForm.css';
import { Form, Button, Row, Col, Modal} from 'react-bootstrap';
import axios from 'axios';

export default function HelpForm() {
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [Hovered, setHovered] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);

    const handleModalOpen = () => {
      setRole('');
      setName('');
      setEmail('');
      setDescription('')
      setShowModal(true);
    }
    const handleModalClose = () => setShowModal(false);

    const handleRoleChange = (e) => {
      setRole(e.target.value);
    }

    const handleSubmit = async (e) => {
      e.preventDefault();

      if(!name || !email || !description) return;
      
      const formData = {
        type: role,
        name,
        email,
        description
      };

      setFormDisabled(true);

      console.log('Form Data:', formData);

      const headers = {
        'Content-Type': 'application/json'
      };

      try {
        const response = await axios.post('https://mentor-portal-backend.azurewebsites.net/api/message/help', formData, {
          headers:headers
        })

        console.log('Full Response:', response)

        if (response.status === 200) {
          if (response.data !== '') {
            console.log('Request Successful:', response.data);
          } else {
            console.log('Request Successful but Response Data is Empty.');
          }
        }

        setShowModal(false);

      } catch(error){
        console.error('Error:', error);
      } finally{
        setFormDisabled(false);
      }
    };

    const buttonStyle = {
      backgroundColor: '#dd6905',
      fontWeight:'700',
      border:'none',
      boxShadow: Hovered ? '0px 2px 4px rgba(0, 0, 0, 0.4)' : 'none',
      transition: 'background-color 0.3s ease'
    }

    return (
      <>
        <div style={{display:'flex', justifyContent:'center'}}>
          <Button onClick={handleModalOpen} style={buttonStyle} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>Help Desk</Button>
        </div>
        <Modal show={showModal} onHide={handleModalClose} centered dialogClassName="custom-modal" contentClassName="custom-modal-content">
          <Modal.Body >
            <Modal.Header style={{ borderBottom: 'none', textAlign:'center'}} closeButton>
              <Modal.Title style={{color: '#dd6905', fontWeight:'700', fontSize:'3.4em', marginBottom:'20px', width:'100%'}}>Help Desk</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
              <Row className='mb-3' style={{textAlign:'center'}}>
                <Col>
                  <Form.Check type='radio' id='menteeRadio' label='Mentee' name='role' value='mentee' checked = {role === 'mentee'} onChange={handleRoleChange} className='radio-bold'/>
                </Col>
                <Col>
                  <Form.Check type='radio' id='mentorRadio' label='Mentor' name='role' value='mentor' checked = {role === 'mentor'} onChange={handleRoleChange} className='radio-bold'/> 
                </Col>
              </Row>
              <Form.Group className='mb-3' controlId='helpFormName'>
                <Form.Label>Name</Form.Label>
                <Form.Control type='name' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} disabled={formDisabled}/>
              </Form.Group>
              <Form.Group className='mb-3' controlId='helpFormEmail'>
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} disabled={formDisabled}/>
              </Form.Group>
              <Form.Group className='mb-3' controlId='helpFormDescription'>
                <Form.Label>Description</Form.Label>
                <Form.Control as='textarea' rows={5} placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} disabled={formDisabled}/>
              </Form.Group>
                <Button type='submit' style={{width:'100%', marginTop:'10px', backgroundColor:'#dd6905', height:'3.5em', border:'none' }} disabled={formDisabled}>
                    {formDisabled ? 'Sending...' : 'Submit'}
                </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    )
}