import * as React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import { Button, Container, Row } from "react-bootstrap";
import logo from "../navbar/logo.png";
import { deleteCookie } from "../../utils/cookies";
import { useData } from "../../utils/context/DataContext";

export function NavbarAdmin() {
  const { data } = useData();
  const navigate = useNavigate();

  const doLogout = async () => {
    await deleteCookie("mentor-token");
    navigate("/");
  };

  return (
    <StyledDashboardNavbar>
      <Container>
        <a
          href="/"
          style={{
            padding: "0.1875em",
          }}
        >
          <img src={logo} width="75" height="75" alt="frontend-app" />
        </a>

        <NavbarDiv className="header">
          <a
            style={{
              textDecoration: "none",
              color: "#ff7701",
              margin: "0px 10px",
              marginTop: 7,
              fontWeight: 800,
              cursor: "pointer",
              letterSpacing: "2px",
            }}
            href="/analytics"
            id="contact-us-button"
          >
            Analytics
          </a>
          <Button
            style={{
              textDecoration: "none",
              margin: "0px 10px",
              fontWeight: 800,
              cursor: "pointer",
              letterSpacing: "2px",
            }}
            variant="light"
            id="contact-us-button"
            onClick={doLogout}
          >
            Logout
          </Button>
        </NavbarDiv>
      </Container>
    </StyledDashboardNavbar>
  );
}

// Styled Components
const NavbarDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const NavLink = styled(Link)`
  color: #f4f4f4;
  font-weight: 900;
  text-decoration: none;
  padding: 0 5px;
`;

const StyledNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  // width: 100%;
  background-color: red;
`;

const StyledDashboardNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #eee;
  height: 80px;
`;
