import React from "react";
import styled from "styled-components";
import { Card } from "react-bootstrap";
import { Availability } from "./interfaces";
import { DAYS_OF_WEEK } from "./constants";
import { convertTimeWithoutDate } from "./AvailableDayRow";
import { mapTimeZoneToInfo } from "./SetAvailability";
export const convertTo12HourFormat = (timeStr) => {
  // Extract hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12; // 0 should be converted to 12

  // Format the time
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};
export const GetDayOfWeekDisplayName = (availability: Availability) =>
  DAYS_OF_WEEK.filter((day) => {
    return day.value == availability.day;
  })[0];

export const GetDayOfWeekDisplayNameFromDate = (date: Date) =>
  DAYS_OF_WEEK.filter((day) => {
    return day.value == date.getDay();
  })[0];
export const monthIndexToAbbreviation = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const AvailableDayCard = ({
  availability,
  date,
}: {
  availability: Availability;
  date?: Date;
}) => {
  const availabilityStart = availability.start_time;
  const availabilityEnd = availability.end_time;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const DisplayTitle = () => {
    if (date) {
      return `${GetDayOfWeekDisplayName(availability)?.display_name}, ${
        monthIndexToAbbreviation[new Date(date).getMonth()]
      } ${new Date(date).getDate()}`;
    }

    return `${GetDayOfWeekDisplayName(availability)?.display_name}`;
  };

  return (
    <CardContainer style={{ borderColor: "#ff7701" }}>
      <Card.Header
        className='text-center text-bold'
        style={{ backgroundColor: "#1170b8", fontWeight: 400, color: "white" }}
      >
        {DisplayTitle()}
      </Card.Header>
      <Card.Body>
        <Card.Title style={{ textAlign: "center" }}>
          {convertTo12HourFormat(
            convertTimeWithoutDate(
              availabilityStart,
              availability.time_zone,
              mapTimeZoneToInfo(timeZone)?.abbreviation
            )
          )}{" "}
          -{" "}
          {convertTo12HourFormat(
            convertTimeWithoutDate(
              availabilityEnd,
              availability.time_zone,
              mapTimeZoneToInfo(timeZone)?.abbreviation
            )
          )}
        </Card.Title>
      </Card.Body>
    </CardContainer>
  );
};

const CardContainer = styled(Card)`
  width: 350px;
  margin: 10px 5px;
  cursor: pointer;
  box-shadow: -1px 2px 2px 2px #eee;
  -webkit-box-shadow: -1px 2px 2px 2px #eee;
  -moz-box-shadow: -1px 2px 3px 3px #eee;
  &:hover {
    box-shadow: -1px 2px 4px 4px #ddd;
    -webkit-box-shadow: -1px 2px 4px 4px #ddd;
    -moz-box-shadow: -1px 2px 4px 4px #ddd;
  }
  @media (max-width: 768px) {
    width: 275px;
  }
`;
