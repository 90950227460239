import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import styled from "styled-components";
import { useData } from "../../utils/context/DataContext";
import logo from "./logo.png";
import logo2 from "./main-logo.png";

const StyledNavbar = styled(Navbar)`
  position: absolute;
  top: 6%;
  left: 50%;
  width: 65%;
  transform: translate(-50%, -50%);
  color: #ff7701;
  @media (max-width: 768px) {
    position: fixed;
    z-index: 100000000;
    width: 100%;
    top: 0%;
  }
`;
const StyledNavbarContainer = styled("div")`
  display: flex;
  z-index: 100000000;
`;

const ContainerSection = styled(Container)`
  background-color: rgb(250 250 250 / 99%) !important;
  color: black;
  border-radius: 9px;
  padding: 10px;
  border-style: solid;
  border-color: #bbb;
  @media (max-width: 768px) {
    position: fixed;
    z-index: 100000000;
    width: 100%;
    top: 0%;
  }
`;

const NavBarLogoImg = styled("img")`
  height: 70px;
  width: 125px;
  margin-left: 20px;
  @media (max-width: 768px) {
    height: 40px;
    width: 50px;
  }
`;

const ToggleContainer = styled("div")`
  margin-right: 20px;
`;

const ToggleContentContainer = styled("div")`
  margin-left: 20px;
`;

export function HomeNavbar() {
  const { data } = useData();
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    // Add your sign-up logic or navigate to the sign-up page
    // Example: navigate("/signup");
    alert(window.innerHeight);
    window.scrollTo(0, window.innerHeight * 2 - 700);
  };

  return (
    <StyledNavbarContainer>
      <StyledNavbar expand='lg' variant='light' style={{ zIndex: "10000000" }}>
        <ContainerSection>
          <Navbar.Brand
            href='#home'
            style={{ display: "flex", alignItems: "center" }}
          >
            <NavBarLogoImg src={logo2} />
          </Navbar.Brand>
          <ToggleContainer>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
          </ToggleContainer>
          <Navbar.Collapse id='basic-navbar-nav'>
            <h1
              style={{
                fontSize: "1.2em",
                fontWeight: 300,
                fontStyle: "italic",
                padding: 10,
                margin: 0,
                color: "#1170b8",
              }}
            >
              Res Non Verba. "Deeds Not Words."
            </h1>

            <Nav className='ml-auto'>
              <ToggleContentContainer>
                <Nav.Link href='#testimonials'>
                  <span style={{ fontWeight: 800 }}>Testimonials</span>
                </Nav.Link>
              </ToggleContentContainer>
              <ToggleContentContainer>
                <Nav.Link href='#more'>
                  <span style={{ fontWeight: 800 }}>More</span>
                </Nav.Link>
              </ToggleContentContainer>
              <ToggleContentContainer>
                <Nav.Link href='#sign-up'>
                  <span style={{ fontWeight: 800 }}>Log In</span>
                </Nav.Link>
              </ToggleContentContainer>
            </Nav>
          </Navbar.Collapse>
        </ContainerSection>
      </StyledNavbar>
    </StyledNavbarContainer>
  );
}
