/**
 * Connections Requests Table.
 *
 * created by Ty D'Angelo 10/19/23
 */

import { Col, Container, Row, Spinner } from "react-bootstrap";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import DeleteConnectionButton from "../button/DeleteConnectionButton";
import ConnectionStatusButton from "../button/ConnectionStatusButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { AvailableDayRow } from "../../SetAvailability/AvailableDayRow";
import { getUserAvailability } from "../../../apis/Availability";

enum UserType {
  MENTOR = "MENTOR",
  MENTEE = "MENTEE",
}

interface ConnectionRequestTableProps {
  userType: UserType;
  connections: Connection[];
  setConnections: (connections: Connection[]) => void;
  isLoading: boolean;
}

export interface Connection {
  id: string;
  mentor_id: string;
  mentee_id: string;
  created_date: string;
  last_edited_date: string;
  status: string;
  mentee_first_name: string;
  mentee_last_name: string;
  mentee_email: string;
  mentee_profile_image: string;
  mentor_first_name: string;
  mentor_last_name: string;
  mentor_email: string;
  mentor_profile_image: string;
}

export interface Availability {
  day: number;
  start_time: string;
  end_time: string;
  enabled: boolean;
  time_zone: string;
}

export interface DayState {
  start_time: string;
  end_time: string;
  enabled: boolean;
  time_zone: string;
}

export interface AvailabilityState {
  [day: number]: DayState;
}

export default function ConnectionRequestTable(p: ConnectionRequestTableProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleFetchConnectionsForUser = async (id: number) => {
    setIsLoading(true);
    try {
      const { data: connectionData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/user/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setConnections(connectionData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };
  const reFetchConnections = (connectionId: string) => {
    const updatedConnections = p.connections.filter(
      (connection) => connection.id !== connectionId
    );
    p.setConnections(updatedConnections);
  };

  const updateConnection = (id: string, status: string) => {
    const updatedConnections = p.connections.map((connection) => {
      if (connection.id === id) {
        connection.status = status;
      }
      return connection;
    });

    p.setConnections(updatedConnections);
  };

  const acceptedConnections = p.connections.filter(
    (connection) => connection?.status === "ACCEPTED"
  );
  const requestedConnections = p.connections.filter(
    (connection) => connection?.status === "REQUESTED"
  );
  const deniedConnections = p.connections.filter(
    (connection) => connection?.status === "DENIED"
  );

  const [value, setValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event) => {
    setValue(event.target.value);

    if (event.target.value === "Date") {
      const sortedData = [...connections].sort((a, b) => {
        return sortAscending
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      });
      setConnections(sortedData);
    }
  };

  if (p.isLoading) {
    return <Spinner />;
  }

  const getFullName = (connection: Connection) => {
    return p.userType === UserType.MENTOR
      ? connection?.mentee_first_name + " " + connection?.mentee_last_name
      : connection?.mentor_first_name + " " + connection?.mentor_last_name;
  };

  // Sort connections alphabetically
  const sortedConnections = [...p.connections].sort((a, b) => {
    return getFullName(a).localeCompare(getFullName(b));
  });

  // Group connections by the first letter of the full name
  const groupedConnections = sortedConnections.reduce((acc, connection) => {
    const firstLetter = getFullName(connection).charAt(0).toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(connection);
    return acc;
  }, {});

  const handleClick = (connection) => {
    const userId = p.userType === UserType.MENTOR? connection.mentee_id : connection.mentor_id;
    window.location.href = `/profile/${userId}`;
  };

const [availabilityByUser, setAvailabilityByUser] = useState<{ [userId: string]: AvailabilityState }>({});

useEffect(() => {
  const fetchAvailabilityForUsers = async () => {
    setIsLoading(true);
    try {
      const userIds = p.connections.map(connection => 
        p.userType === UserType.MENTOR ? connection.mentee_id : connection.mentor_id
      );
      const availabilityData = await Promise.all(
        userIds.map(async userId => {
          const availability = await getUserAvailability(userId);
          const availabilityState: AvailabilityState = availability.reduce((acc: AvailabilityState, curr: Availability) => {
            acc[curr.day] = {
              start_time: curr.start_time,
              end_time: curr.end_time,
              enabled: curr.enabled,
              time_zone: curr.time_zone,
            };
            return acc;
          }, {} as AvailabilityState);
          return { userId, availability: availabilityState };
        })
      );
      const availabilityMap = availabilityData.reduce((acc, { userId, availability }) => {
        acc[userId] = availability;
        return acc;
      }, {} as { [userId: string]: AvailabilityState });
      setAvailabilityByUser(availabilityMap);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching availability:", error);
      setIsLoading(false);
    } 
  };
  fetchAvailabilityForUsers();
}, [p.connections]);

  const renderAvailableDays = (availabilityState: AvailabilityState, userId: string) => {
    const firstAvailableDay = Object.entries(availabilityState)
      .find(([, dayAvailability]) => dayAvailability.enabled);
  
    if (!firstAvailableDay) return null;
  
    const [day, dayAvailability] = firstAvailableDay;
  
    return (
      <div style={{ display: "flex", fontWeight: "bold", alignItems: "center", height: "100%" }}>
        <div>Available Sessions:</div>
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft:"5px" , height: "100%" }}>
            <AvailableDayRow
              availability={{ day: Number(day), ...dayAvailability }}
              toggleAvailabilityDay={() => { }}
              connectionProp={true}
            />
          </div>
          <ViewAllButton userId={userId} />
        </div>
      </div>
    );
  };
  if (isLoading) {
    return <Spinner />;
  }

  const ViewAllButton = ({userId}) => {
    const handleViewAllClick = () => {
      window.location.href = `/profile/${userId}`;
    };

    return (
      <div style={{marginLeft: "5px"}}>
        <div 
          style={{ 
            border: "1px solid #aaa", 
            borderRadius:"5px", 
            cursor: "pointer", 
            fontWeight:"bold", 
            backgroundColor:"white", 
            paddingRight:"5px", 
            paddingLeft:"5px",
            transition: "background-color 0.3s ease",
          }} 
            onClick={handleViewAllClick}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "#ddd";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "white";
            }}
            >
          View All
        </div>
      </div>
    )
  }

  if(p.isLoading) {
    return <Spinner/>
  }

return (
  <Container fluid>
      <div>
        <Table>
          <TableBody>
            {Object.keys(groupedConnections).map((letter) => (
              <React.Fragment key={letter}>
                <TableRow>
                  <TableCell colSpan={p.userType === UserType.MENTOR ? 5 : 4} style={letterCellStyle}>
                    <span style={{ marginLeft: "10px", color: "grey" }}>{letter}</span>
                  </TableCell>
                </TableRow>
                {groupedConnections[letter].map((connection, index) => {
                  const userId = p.userType === UserType.MENTOR ? connection.mentee_id : connection.mentor_id;
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell style={nameColumnStyle}>
                          <div style={{ display: 'flex', marginTop:"10px", marginBottom:"10px"}}>
                            <div onClick={() => handleClick(connection)} style={{ cursor: "pointer", marginRight: "10px", alignSelf: 'flex-start' }}>
                              <img
                                src={p.userType === UserType.MENTOR ? connection?.mentee_profile_image : connection?.mentor_profile_image}
                                alt={p.userType === UserType.MENTOR ? "Mentee Profile" : "Mentor Profile"}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50%",
                                  transition: "transform 0.3s ease",
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.transform = "translateY(-5px)";
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.transform = "translateY(0)"; 
                                }}
                              />
                            </div>
                            <div style={{marginLeft:"20px"}}>
                              <div style={{ color: "black", fontWeight: "bold", fontSize:"20px" }}>
                                {getFullName(connection)}
                              </div>
                              <div style={{ marginTop: "10px", color: "black", fontWeight: "bold", fontSize:"15px"  }}>
                                {p.userType === UserType.MENTOR ? connection?.mentee_email : connection?.mentor_email}
                              </div>
                              {availabilityByUser[userId] && (
                                <div style={{ marginTop: "10px"  }}>
                                 {renderAvailableDays(availabilityByUser[userId], userId)}
                                </div>
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {connection.status === "ACCEPTED" && (
                            <DeleteConnectionButton
                              connectionId={connection.id}
                              removeConnection={reFetchConnections}
                            />
                          )}
                          {connection.status === "DENIED" && p.userType === UserType.MENTEE && (
                            <Button variant="danger" disabled>Denied</Button>
                          )}
                          {connection.status === "REQUESTED" && p.userType === UserType.MENTEE && (
                            <Button variant="secondary" disabled>Pending</Button>
                          )}
                        </TableCell>
                        {p.userType === UserType.MENTOR && connection?.status !== "ACCEPTED" && (
                          <TableCell style={buttonColumnStyle}>
                            <div style={{display:"flex"}}>
                              <ConnectionStatusButton
                                updateConnection={updateConnection}
                                connectionId={connection?.id}
                                title={"Approve"}
                                status={"ACCEPTED"}
                              />
                              <div style={{marginLeft:"10px"}}>
                                {connection?.status !== "DENIED" && (
                                  <ConnectionStatusButton
                                  updateConnection={updateConnection}
                                  connectionId={connection?.id}
                                  title={"Deny"}
                                  status={"DENIED"}
                                />
                                )}
                                  {connection?.status === "DENIED" && (
                                    <Button variant="danger" disabled>Denied</Button>
                                  )}
                              </div>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};

// Styled Components
const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  // color: "grey",
  paddingRight: "200px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
 
};

const dateColumnStyle = {
  paddingRight: "120px",
  fontWeight: "bold",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
  
};

const emailColumnStyle = {
  paddingRight: "120px",
  fontWeight: "bold",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
  
};

const buttonColumnStyle = {
  // paddingRight: "90px",
  // display: "flex",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
  borderBottom: 'none',
};

const editColumnStyle = {
  paddingLeft: "75px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  paddingLeft: "60px",
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};

const CardIconContainer = styled("div")`
  background-color: rgb(163 228 255);
  display: flex;
  align-items: center;
  border-radius: 360px;
  height: 63px;
  width: 63px;
  justify-content: center;
  margin-right: 20px;
`;

const letterCellStyle = {
  fontWeight: "bold",
  fontSize: "1em",
  borderBottom: "1px solid #ddd",
  backgroundColor:"#eee",
  padding: "5px",
};