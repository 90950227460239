/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";

import { UserData } from "../Mentor/interfaces";
import axios from "axios";
import { MentorDashboard, MentorFilterContainer } from "../Mentor";
import { useData } from "../utils/context/DataContext";
import { decodeJWTBearer } from "../utils/decodeJWT";
import { deleteCookie, getCookie } from "../utils/cookies";
import { useNavigate } from "react-router-dom";
import { NavBarAlt } from "../ui/navbar/NavbarAlt";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [mentors, setMentors] = useState<UserData[]>([]);

  const { setData, data } = useData();
  const navigate = useNavigate();

  const handleFetchMentors = async (id) => {
    try {
      const { data: mentorData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/new-mentors/connected/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setMentors(mentorData);
      console.log(mentorData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleIsSignedIn = async () => {
    setIsLoading(true);

    if (data) {
      if (data.isAdmin) {
        navigate("/admin");
        return;
      }
      if (data.type === "MENTEE") {
        handleFetchMentors(data.id);
      }
      return;
    }

    const mentorToken = await getCookie("mentor-token");

    if (mentorToken) {
      try {
        const jwtUser = decodeJWTBearer(mentorToken);
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${jwtUser.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(userData);
        setTimeout(() => {
          if (userData.isAdmin) {
            navigate("/admin");
            return;
          }
          if (!userData.account_verified) {
            navigate("/");
          } else if (userData.type === "MENTEE") {
            handleFetchMentors(userData.id);
          }
        }, 500);
      } catch (e) {
        console.log("error using mentor token");
        setIsLoading(false);
        deleteCookie("mentor-token");
        navigate("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/");
      }, 600);
    }
  };

  useEffect(() => {
    if (!isLoading) handleIsSignedIn();
  }, []);

  return (
    <div
      style={{
        // backgroundColor: "#006B1",
        // backgroundImage: `url(${process.env.PUBLIC_URL}/Site_Backdrop.png)`,
        // backgroundSize: "cover",
        // backgroundAttachment: "fixed",
        // backgroundPosition: "top",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <NavBarAlt />
      <div style={{ overflowX: "hidden" }}>
        <PageContainer>
          <Row style={{ margin: "50px -15px 10px -15px" }}>
            {/* Dashboard intro */}
            {data?.type === "MENTEE" && (
              <Col md={12}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {/* <img src={logo} width="300" height="300" alt="frontend-app" /> */}
                  <div style={{ marginTop: 15, marginLeft: "-10px" }}>
                    {/* <h1>Student Dashboard</h1> */}
                    <h1
                      style={{
                        marginBottom: "2px",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                      }}
                    >
                      Find a Mentor
                    </h1>
                    {/* <p
                      style={{
                        margin: "5px 20px 5px 0px",
                        backgroundColor: "#ff7701",
                        padding: 5,
                        color: "white",
                        borderColor: "#eee",
                        borderRadius: 9,
                        fontSize: "1.8em",
                      }}
                    >
                      Find mentoring connections right for you, Get 1:1 advice
                      on goals, life, achievements and more.
                    </p> */}
                    <p
                      style={{
                        marginTop: "0",
                        marginBottom: "20px",
                        color: "black",
                      }}
                    >
                      Find mentoring connections right for you, Get 1:1 advice
                      on goals, life, achievements and more
                    </p>
                    <hr
                      style={{
                        margin: "10px 0",
                        width: "100%",
                        position: "absolute",
                        bottom: -1,
                        left: 0,
                        backgroundColor: "grey",
                        height: "1px",
                      }}
                    />
                  </div>
                </div>
              </Col>
            )}
          </Row>
          {/* mentor card list */}
          {data?.type === "MENTEE" && (
            <Row>
              <MentorFilterContainer
                mentors={mentors.length > 0 ? mentors : []}
                isLoading={isLoading}
                menteeId={data.id}
              />
            </Row>
          )}
          {data?.type === "MENTOR" && <MentorDashboard data={data} />}
        </PageContainer>
      </div>
    </div>
  );
}

// Styled Components

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;

const ImageCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageContainer = styled("div")`
  height: 250px;
  width: 250px;
  background-color: black;
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const SmallImageContainer = styled("div")`
  height: 65px;
  width: 65px;
  background-color: black;
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const LeftContentContainer = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const RightContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const UserMetricsRow = styled(Row)`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;

const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  color: "grey",
  paddingRight: "200px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const dateColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const emailColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const buttonColumnStyle = {
  paddingRight: "90px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const editColumnStyle = {
  paddingLeft: "75px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  paddingLeft: "60px",
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};
