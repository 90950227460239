import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";

interface DeleteConnectionButtonProps {
  connectionId: string;
  removeConnection: (id: string) => void;
}

interface Connection {
  id: string;
  mentor_id: string;
  mentee_id: string;
  created_date: string;
  last_edited_date: string;
  status: string;
  mentee_first_name: string;
  mentee_last_name: string;
  mentee_email: string;
  mentee_profile_image: string;
  mentor_first_name: string;
  mentor_last_name: string;
  mentor_email: string;
  mentor_profile_image: string;
}

export default function DeleteConnectionButton(p: DeleteConnectionButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const deleteConnectionRequest = async (id: string) => {
    try {
      const answer = confirm(
        "Are you sure you want to remove this connection?"
      );
      if (!answer) return;
      setIsLoading(true);
      p.removeConnection(id);
      const { data } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant='danger'
      onClick={() => deleteConnectionRequest(p.connectionId)}
    >
      Remove Connection
    </Button>
  );
}
