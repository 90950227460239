/**
 * Signup page after initial signup
 *
 * created by Ty D"Angelo 9/10/2023
 */

import React, { useState, useEffect, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import PersonalForm from "../SignUp/form/PersonalForm";
import SchoolForm from "../SignUp/form/SchoolForm";
import { useData } from "../utils/context/DataContext";
import { deleteCookie, getCookie } from "../utils/cookies";
import { decodeJWTBearer } from "../utils/decodeJWT";
import { NavBar } from "../ui/navbar/Navbar";
import ConsentForm from "../SignUp/form/ConsentForm";

import Cropper from "react-easy-crop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faThumbsUp } from "@fortawesome/free-solid-svg-icons";

export const ImageCropper = ({
  selectedImage,
  setSelectedImage,
  setSelectedImageFile,
  showMessage = false,
}) => {
  const [crop, setCrop] = useState({ x: 20, y: 20 });
  const [zoom, setZoom] = useState(1);
  const { data } = useData();

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels);
    // Use croppedAreaPixels to get the cropped image data

    // Create a new canvas element
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set canvas dimensions
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    // Create a new image element
    const croppedImage = new Image();

    // When the image is loaded, draw it onto the canvas
    croppedImage.onload = function () {
      // Draw the cropped area of the image onto the canvas
      if (ctx) {
        ctx.drawImage(
          croppedImage,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );
      }

      // Convert the canvas content to a blob
      canvas.toBlob((blob) => {
        if (blob) {
          // Prepare the form data with the cropped image blob
          const formData = new FormData();
          formData.append("image", blob, `image-${data.id}.png`);
          // Update the state with the cropped image blob and data URL
          setSelectedImageFile(formData);
        }
      }, "image/png");
    };

    // Set the source of the image element to the cropped image data URL
    croppedImage.src = selectedImage;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Row>
      {!selectedImage && (
        <>
          {showMessage && (
            <div className='text-center'>
              <h3>Account Verified!</h3>
              <p>Upload a photo to continue</p>
            </div>
          )}

          <div
            style={{
              backgroundColor: "#eee",
              height: 100,
              display: "flex",
              alignItems: "center",
            }}
          >
            <input type='file' accept='image/*' onChange={handleFileChange} />
          </div>
        </>
      )}
      {selectedImage && (
        <>
          <div style={{ width: 1000 }}>
            <Cropper
              image={selectedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
            />
          </div>
        </>
      )}
    </Row>
  );
};

export default function Verification() {
  const [key, setKey] = useState("personal");
  const { setData, data } = useData();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPhotoUpload, setShowPhotoUpload] = useState(false);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<string | null>(
    null
  );
  const [resumeFile, setResumeFile] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const handleIsSignedIn = async () => {
    setIsLoading(true);

    if (data) {
      setUserData({ ...userData, ...data });
      setIsLoading(false);
      return;
    }

    const mentorToken = await getCookie("mentor-token");

    if (mentorToken) {
      try {
        const jwtUser = decodeJWTBearer(mentorToken);
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${jwtUser.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(userData);
        setUserData({ ...userData });
        setTimeout(() => {
          if (userData.isAdmin) {
            navigate("/admin");
            return;
          }
          if (userData.account_verified) {
            navigate("/dashboard");
          } else {
            setIsLoading(false);
          }
        }, 500);
      } catch (e) {
        console.log("error using mentor token");
        setIsLoading(false);
        deleteCookie("mentor-token");
        navigate("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/");
      }, 600);
    }
  };

  const [userData, setUserData] = useState({
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    student_id: "",
    zip_code: "",
    classification: null,
    major: "",
    bio: "",
    blurb: "New Mentor! Ready to help",
    adult_learner: null,
    first_generation: null,
    military: null,
    gender: "",
    type: "",
    race: "",
    alumni: null,
    state: null,
    country: "",
    is_felon: null,
    felon_explanation: "",
    entrepreneur: null,
    subject_expert: null,
    subject: "",
    linkedin_url: "",
    stem: null,
  });

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.name]: event.target.value });
  };

  const setUserType = (type) => {
    setUserData({ ...userData, type });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateFormData();
  };

  // UPDATE
  const updateFormData = async () => {
    // Validate required feilds
    // (first_name last_name email zip_code classification major adult_learner first_generation military gender state)
    try {
      setIsLoading(true);
      const { data: patchData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${userData.id}`,
        {
          method: "PATCH",
          data: {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            phone_number: userData.phone_number,
            student_id: userData.student_id,
            state: userData.state,
            zip_code: userData.zip_code,
            classification: userData.classification,
            major: userData.major ? userData.major.trim() : "",
            bio: userData.bio ? userData.bio.trim() : "",
            blurb: userData.blurb ? userData.blurb.trim() : "",
            adult_learner: userData.adult_learner,
            first_generation: userData.first_generation,
            military: userData.military,
            gender: userData.gender,
            type: userData.type,
            race: userData.race ? userData.race.trim() : "",
            account_verified: true,
            date_of_verified: new Date().toISOString(),
            alumni: userData.alumni,
            country: userData.country,
            is_felon: userData.is_felon,
            felon_explanation: userData.felon_explanation,
            entrepreneur: userData.entrepreneur,
            subject: userData.subject,
            subject_expert: userData.subject_expert,
            linkedin_url: userData.linkedin_url,
            stem: userData.stem,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resumeFile) await handleSelectedFile();

      setUserData(patchData);
      setShowPhotoUpload(true);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleSelectedFile = async () => {
    try {
      const { data: profileData } = await axios.post(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${userData.id}/upload/resume`,
        resumeFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // setUserData({ ...data, profile_resume: profileData.profile_resume });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleSelectedImage = async () => {
    try {
      setErrorMessage("");
      setIsLoadingImage(true);
      const { data: profileData } = await axios.post(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${userData.id}/upload/profile`,
        selectedImageFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUserData({ ...data, profile_image: profileData.profile_image });
      setTimeout(() => {
        setIsLoading(false);
        setIsLoadingImage(false);
        navigate("/dashboard");
      }, 1000);
    } catch (e) {
      setErrorMessage("error uploading profile picture");
      console.log(e);
      setTimeout(() => {
        navigate("/dashboard");
      }, 500);
      setIsLoading(false);
      setIsLoadingImage(false);
    }
  };

  useEffect(() => {
    handleIsSignedIn();
  }, []);

  if (isLoading) {
    return (
      <PageContainer fluid={false}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4>Fetching Account Details</h4>
          <div className='spinner-border text-primary ml-3'></div>
        </div>
      </PageContainer>
    );
  }

  if (!data) {
    return (
      <PageContainer fluid={false}>
        <h2>User session not found. Redirecting</h2>
      </PageContainer>
    );
  }

  if (showPhotoUpload) {
    return (
      <>
        <NavBar />
        <PageContainer fluid={false}>
          <Row>
            <Col md={4} />
            <ImageCol md={4}>
              <CropContainer>
                <ImageCropper
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  setSelectedImageFile={setSelectedImageFile}
                  showMessage={true}
                />
              </CropContainer>
              <div style={{ minHeight: 50 }}>
                <p>{errorMessage && errorMessage}</p>
              </div>
            </ImageCol>
            <Col md={4} />
          </Row>

          <Row className='mt-4'>
            {isLoadingImage && (
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Uplaoding Image{" "}
                <div className='ml-3 spinner-border text-primary'></div>
              </Col>
            )}
            {selectedImage && !isLoadingImage && (
              <Col className='text-center'>
                <Button
                  className='mx-3'
                  onClick={() => {
                    setSelectedImage(null);
                  }}
                  variant='danger'
                >
                  Redo <FontAwesomeIcon icon={faRedo} />
                </Button>
                <Button onClick={handleSelectedImage} className='mx-3'>
                  Upload <FontAwesomeIcon icon={faThumbsUp} />
                </Button>
              </Col>
            )}
          </Row>
        </PageContainer>
      </>
    );
  }

  return (
    <>
      <NavBar />
      <PageContainer fluid={false}>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <div>
              <h2>Account Verification</h2>
              <p>Validate the information below</p>
            </div>
            <Tabs
              id='controlled-tab-example'
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className='mb-3'
            >
              <Tab eventKey='personal' title='Personal Details'>
                <PersonalForm
                  setKey={setKey}
                  data={data}
                  isLoading={isLoading}
                  handleChange={handleChange}
                />
              </Tab>
              <Tab eventKey='school' title='Profile Information'>
                <SchoolForm
                  setKey={setKey}
                  data={data}
                  isLoading={isLoading}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  resumeFile={resumeFile}
                  setResumeFile={setResumeFile}
                  setUserType={setUserType}
                />
              </Tab>
              <Tab eventKey='consent' title='Consent Form'>
                <ConsentForm updateFormData={updateFormData} setKey={setKey} />
              </Tab>
            </Tabs>
          </Col>
          <Col md={2} />
        </Row>
      </PageContainer>
    </>
  );
}

// Styled Components

const PageContainer = styled(Container)`
  padding-top: 300px;
  padding-bottom: 110px;
`;
const ImageCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled("div")`
  border-radius: 30px;
  border-style: solid;
  min-width: 200px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-color: #eee;
`;

const CropContainer = styled("div")`
  width: 350px;
  height: 350px;
`;
