import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { UserData } from "../Mentor/interfaces";
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  getUserAvailability,
  setUserAvailability,
} from "../../apis/Availability";
import { Availability, AvailabilityState, DayState } from "./interfaces";
import { DAYS_OF_WEEK, INIT_AVAILABILITY_STATE } from "./constants";
import { AvailableDayCardContainer } from "./AvailableDayCardContainer";
import { setCookie } from "../utils/cookies";
export const formatTime = (timeStr) => {
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Check if the time is not '12:00'
  if (hours !== 12) {
    // Format hours with leading zero
    const formattedHours = hours.toString().padStart(2, "0");
    return `${formattedHours}:${minutes.toString().padStart(2, "0")}`;
  }

  // If the time is '12:00', return as is
  return timeStr;
};

export interface TimeZoneInfo {
  abbreviation: string;
  fullName: string;
}

export function mapTimeZoneToInfo(
  timeZoneIdentifier: string
): TimeZoneInfo | undefined {
  const timeZoneMapping: { [key: string]: TimeZoneInfo } = {
    "America/New_York": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Detroit": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indiana/Indianapolis": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indiana/Marengo": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indiana/Vevay": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Indianapolis": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },
    "America/Louisville": {
      abbreviation: "EST",
      fullName: "Eastern Standard Time",
    },

    "America/Chicago": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Knox": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Tell_City": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Vincennes": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Indiana/Winamac": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Knox_IN": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/Menominee": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/North_Dakota/Beulah": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/North_Dakota/Center": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },
    "America/North_Dakota/New_Salem": {
      abbreviation: "CST",
      fullName: "Central Standard Time",
    },

    "America/Denver": {
      abbreviation: "MST",
      fullName: "Mountain Standard Time",
    },
    "America/Boise": {
      abbreviation: "MST",
      fullName: "Mountain Standard Time",
    },
    "America/Shiprock": {
      abbreviation: "MST",
      fullName: "Mountain Standard Time",
    },

    "America/Los_Angeles": {
      abbreviation: "PST",
      fullName: "Pacific Standard Time",
    },
    "America/Phoenix": {
      abbreviation: "PST",
      fullName: "Pacific Standard Time",
    },
    "America/Tijuana": {
      abbreviation: "PST",
      fullName: "Pacific Standard Time",
    },
  };

  return timeZoneMapping[timeZoneIdentifier];
}

const TIMEZONES = [
  { abbreviation: "EST", fullName: "Eastern Standard Time" },
  { abbreviation: "CST", fullName: "Central Standard Time" },
  { abbreviation: "MST", fullName: "Mountain Standard Time" },
  { abbreviation: "PST", fullName: "Pacific Standard Time" },
  // ... add more options as needed
];

export const SetAvailability = ({
  userData,
  showCurrent = true,
}: {
  userData: UserData;
  showCurrent?: Boolean;
}) => {
  const [availability, setAvailability] = useState<Availability[]>([]);
  const [availabilityState, setAvailabilityState] = useState<AvailabilityState>(
    INIT_AVAILABILITY_STATE
  );

  const [selectedTimeZone, setSelectedTimeZone] = useState<
    string | undefined
  >();

  const handleSetUserAvailability = async () => {
    if (selectedTimeZone) {
      const enabledAvailabilityArray = Object.entries(availabilityState).filter(
        ([_, value]) => value.start_time && value.end_time
      );

      const enabledAvailability = Object.fromEntries(enabledAvailabilityArray);

      const userAvailability = Object.entries(enabledAvailability).map(
        ([key, value]): Availability => {
          const dayState: DayState = value;
          const day: number = parseInt(key, 10);
          return {
            day,
            start_time: dayState.start_time,
            end_time: dayState.end_time,
            enabled: dayState.enabled,
            time_zone: selectedTimeZone,
          };
        }
      );

      try {
        await setUserAvailability(userData.id, userAvailability);
        setCookie(`avail-state-${userData.id}`, userAvailability);
      } catch (e) {
        console.log(e);
      }

      setAvailability(userAvailability);
    }
  };

  const handleUpdateAvailabilityStateTime = (
    day: number,
    value,
    start = true
  ) => {
    const dayObject = availabilityState[day];
    if (start) {
      dayObject.start_time = value;
    } else {
      dayObject.end_time = value;
    }

    setAvailabilityState({ ...availabilityState, ...{ [day]: dayObject } });
  };

  const toggleAvailabilityStateEnabled = (day) => {
    const dayObject = availabilityState[day];
    dayObject.enabled = !dayObject.enabled;
    setAvailabilityState({ ...availabilityState, ...{ [day]: dayObject } });
  };

  const handleSetAvailabilityState = async () => {
    try {
      const fetchedUserAvailability: Availability[] = await getUserAvailability(
        userData.id
      );

      const tempStateFromAvalibility = INIT_AVAILABILITY_STATE;

      fetchedUserAvailability.forEach((userAval): void => {
        tempStateFromAvalibility[userAval.day] = {
          start_time: formatTime(userAval.start_time),
          end_time: formatTime(userAval.end_time),
          enabled: userAval.enabled,
          time_zone: userAval.time_zone,
        };
      });

      setSelectedTimeZone(fetchedUserAvailability[0].time_zone);
      setAvailabilityState(tempStateFromAvalibility);
      setAvailability(fetchedUserAvailability);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleSetAvailabilityState();
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <div>
            <h1>Set Availability</h1>
            <p>
              Update your availability allowing students to request appointments
              when you are available
            </p>
          </div>
        </Col>
        <Col lg={showCurrent ? 7 : 12}>
          <div>
            <h5>Time Zone</h5>
            <Form.Select
              defaultValue={selectedTimeZone}
              value={selectedTimeZone}
              onChange={(e) => {
                setSelectedTimeZone(e.target.value);
              }}
            >
              <option value=''>Select a Time Zone</option>
              {TIMEZONES.map((tz) => (
                <option key={tz.abbreviation} value={tz.abbreviation}>
                  {`${tz.abbreviation} - ${tz.fullName}`}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='mt-3'>
            <h5>Availability</h5>
          </div>
          <div>
            {DAYS_OF_WEEK.map((day) => (
              <DayContentContainer>
                <div style={{ marginRight: 5, flex: 1 }}>
                  <Form.Check // prettier-ignore
                    type='switch'
                    id='custom-switch'
                    label={day.name}
                    style={{ fontSize: 30 }}
                    checked={availabilityState[day.value].enabled}
                    onChange={() => {
                      toggleAvailabilityStateEnabled(day.value);
                    }}
                  />
                </div>
                <DayTimeContainer>
                  <TimeContainer>
                    <input
                      type='time'
                      defaultValue={
                        availabilityState[day.value].start_time ?? ""
                      }
                      onChange={(e) => {
                        handleUpdateAvailabilityStateTime(
                          day.value,
                          e.target.value
                        );
                      }}
                    />
                  </TimeContainer>
                  -
                  <TimeContainer>
                    <input
                      type='time'
                      defaultValue={availabilityState[day.value].end_time ?? ""}
                      onChange={(e) => {
                        handleUpdateAvailabilityStateTime(
                          day.value,
                          e.target.value,
                          false
                        );
                      }}
                    />
                  </TimeContainer>
                </DayTimeContainer>
              </DayContentContainer>
            ))}
          </div>
          <div className='mt-4 mb-4 text-right'>
            <Button
              disabled={!selectedTimeZone}
              onClick={handleSetUserAvailability}
            >
              Save
            </Button>
          </div>
        </Col>
        {showCurrent && (
          <Col lg={5}>
            {availability?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h5>Current Availability</h5>
                <AvailableDayCardContainer availability={availability} />
              </div>
            ) : (
              <p className='text-center mt-5'>
                Please set your availability on the left
              </p>
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};

const AvailableDayContainer = styled("div")`
  background-color:red @media (max-width: 768px) {
  }
`;

const DayContentContainer = styled(Row)`
  display: flex;
  align-items: center;
`;

const DayTimeContainer = styled("div")`
  display: flex;
  flex: 2;
  @media (max-width: 768px) {
  }
`;

const TimeContainer = styled("div")`
  padding: 0px 10px;
  @media (max-width: 768px) {
  }
`;
