import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Availability } from "./interfaces";
import { DAYS_OF_WEEK } from "./constants";
import { AvailableDayCard } from "./AvailableDayCard";
import { AppointmentForm, AppointmentRequest } from "./AppointmentForm";
import {
  CreateAppointment,
  GetAppointmentsByMentor,
} from "../../apis/Appointment/Appointment";
import { convertTimeWithoutDate } from "./AvailableDayRow";
import { mapTimeZoneToInfo } from "./SetAvailability";
import { Session } from "../ui/table/SessionRequestContainer";
import { AxiosError, isAxiosError } from "axios";

interface AvailabilityTimeBlock {
  start_time: string;
  end_time: string;
  booked: boolean;
}
interface AvailabilityDateTimeBlock {
  start_time: string;
  end_time: string;
  date: Date;
}
interface AvailabilityGroup {
  date: Date;
  availability: AvailabilityTimeBlock[];
}

const splitAvailability = (availObj, increment): AvailabilityTimeBlock[] => {
  const result: AvailabilityTimeBlock[] = [];

  // Validate input
  if (!availObj || typeof availObj !== "object" || isNaN(increment)) {
    console.error("Invalid input");
    return result;
  }

  // Extract values from the availability object
  const { start_time, end_time, enabled, day } = availObj;

  // Validate time format
  const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeFormatRegex.test(start_time) || !timeFormatRegex.test(end_time)) {
    console.error("Invalid time format");
    return result;
  }

  // Validate day
  if (isNaN(day) || day < 0 || day > 6) {
    console.error("Invalid day");
    return result;
  }

  // Convert start_time and end_time to minutes
  const startTimeInMinutes = convertTimeToMinutes(start_time);
  const endTimeInMinutes = convertTimeToMinutes(end_time);

  // Calculate time increment in minutes
  const incrementInMinutes = increment;

  // Generate availability objects for each increment
  let currentTimeInMinutes = startTimeInMinutes;
  while (currentTimeInMinutes < endTimeInMinutes) {
    const currentEndTimeInMinutes = currentTimeInMinutes + incrementInMinutes;

    // Add the availability object to the result array
    result.push({
      start_time: convertMinutesToTime(currentTimeInMinutes),
      end_time: convertMinutesToTime(currentEndTimeInMinutes),
      booked: false,
    });

    // Move to the next increment
    currentTimeInMinutes = currentEndTimeInMinutes;
  }

  return result;
};

// Helper function to convert time to minutes
function convertTimeToMinutes(time) {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

// Helper function to convert minutes to time
function convertMinutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(
    2,
    "0"
  )}`;
}
const convertTo12HourFormat = (timeStr) => {
  // Extract hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12; // 0 should be converted to 12

  // Format the time
  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};
function getNextDayOfWeek(dayIndex, date = new Date()) {
  const adjustedIndex = (dayIndex - date.getDay() + 7) % 7;
  const daysToAdd = adjustedIndex === 0 ? 7 : adjustedIndex;

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + daysToAdd
  );
}

function getThreeDatesForDayOfWeek(dayIndex, startDate = new Date()) {
  const dates: Date[] = [];

  for (let i = 0; i < 3; i++) {
    const nextDayOfWeek = getNextDayOfWeek(dayIndex, startDate);
    dates.push(nextDayOfWeek);
    startDate = new Date(nextDayOfWeek); // Update startDate for the next iteration
  }

  return dates;
}
export const GetDayOfWeekDisplayName = (availability: Availability) =>
  DAYS_OF_WEEK.filter((day) => {
    return day.value == availability.day;
  })[0];

const monthIndexToAbbreviation = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
interface AvailabilityMapping {
  date: Date;
  availability: AvailabilityTimeBlock[];
}
export const AvailableTimeRow = ({
  availability,
  mentee_id,
  mentor_id,
}: {
  availability: Availability;
  mentee_id: string;
  mentor_id: string;
}) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [appointmentAvailability, setAppointmentAvailability] = useState<
    AvailabilityGroup[]
  >([]);

  const [selectedTime, setSelectedTime] =
    useState<AvailabilityDateTimeBlock | null>();

  const [isSubmittingAppointmentRequest, setIsSubmittingAppointmentRequest] =
    useState<boolean>(false);
  const [isRequestSent, setIsRequestSent] = useState<boolean>(false);

  const handleSetDayTimes = async () => {
    // split availability by 30 or 60 increments - userDayTimeAvailability
    const userDayTimeAvailability: AvailabilityTimeBlock[] = splitAvailability(
      availability,
      30
    );

    // get three weeks of userDayTimeAvailability records
    const ThreeWeekAvailabilityMapping: AvailabilityMapping[] = [];
    const nextDayOfWeek = getNextDayOfWeek(availability.day);
    const nextDayOfWeek2 = getNextDayOfWeek(availability.day, nextDayOfWeek);
    const nextDayOfWeek3 = getNextDayOfWeek(availability.day, nextDayOfWeek2);
    ThreeWeekAvailabilityMapping.push({
      date: nextDayOfWeek,
      availability: userDayTimeAvailability,
    });
    ThreeWeekAvailabilityMapping.push({
      date: nextDayOfWeek2,
      availability: userDayTimeAvailability,
    });
    ThreeWeekAvailabilityMapping.push({
      date: nextDayOfWeek3,
      availability: userDayTimeAvailability,
    });

    let fetchedUserSessions: Session[] = [];
    try {
      //get the dates to check in an array
      fetchedUserSessions = await GetAppointmentsByMentor(
        mentor_id,
        "ACCEPTED"
      );
    } catch (e) {
      if (isAxiosError(e)) {
        const error: AxiosError = e;
        if (error.code === "404") {
          console.log("no appointments found");
        }
      }
    }

    const datesWithAppointments = fetchedUserSessions.map(
      (fetchedUserSession) => fetchedUserSession.date.replace(/(.*)(T.*)/, "$1")
    );

    //loop though the test object
    // if the date is included in the datesWithAppointments,
    //do the booked update from test's availibility array

    const updatedThreeWeekAvailabilityMapping: AvailabilityMapping[] =
      ThreeWeekAvailabilityMapping.reduce((acc: AvailabilityMapping[], cur) => {
        const mappingDate = new Date(cur.date)
          .toISOString()
          .replace(/(.*)(T.*)/, "$1");
        if (datesWithAppointments.includes(mappingDate)) {
          const availabilityTimes = cur.availability;
          let updatedAvailability: AvailabilityTimeBlock[] = [];
          // for each start time
          for (let i in availabilityTimes) {
            const foundAppt = fetchedUserSessions.filter(
              (fetchedUserSession) =>
                fetchedUserSession.date.replace(/(.*)(T.*)/, "$1") ===
                  mappingDate &&
                fetchedUserSession.start_time ===
                  availabilityTimes[i].start_time
            );
            // Create a copy of the availability object
            const updatedTimeBlock = { ...availabilityTimes[i] };
            if (foundAppt.length > 0) {
              updatedTimeBlock.booked = true;
            }
            updatedAvailability.push(updatedTimeBlock);
            cur.availability = updatedAvailability;
          }
        }

        acc.push(cur);
        return acc;
      }, []);

    setAppointmentAvailability(updatedThreeWeekAvailabilityMapping);
  };

  const submitAppointmentRequest = async (
    appointmentData: AppointmentRequest
  ) => {
    setIsSubmittingAppointmentRequest(true);
    appointmentData.mentee_id = mentee_id;
    appointmentData.mentor_id = mentor_id;
    try {
      await CreateAppointment(appointmentData);
      setIsRequestSent(true);
      setIsSubmittingAppointmentRequest(false);
    } catch (e) {
      console.log(e);
      setIsSubmittingAppointmentRequest(false);
    }
  };

  const doHideBack = () => {
    if (isRequestSent) return true;
    if (isSubmittingAppointmentRequest) return true;
    return false;
  };

  useEffect(() => {
    setSelectedTime(null);
    handleSetDayTimes();
  }, [availability]);

  return (
    <div className='mt-4' style={{ minHeight: 400 }}>
      {selectedTime ? (
        <div className='mb-4'>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!doHideBack() && (
              <Button variant='link' onClick={() => setSelectedTime(null)}>
                undo selection
              </Button>
            )}
            <AvailableDayCard
              availability={{
                day: availability.day,
                start_time: selectedTime.start_time,
                end_time: selectedTime.end_time,
                enabled: true,
                time_zone: availability.time_zone,
              }}
              date={selectedTime.date}
            />
          </div>
          <div>
            <AppointmentForm
              availability={{
                day: availability.day,
                start_time: selectedTime.start_time,
                end_time: selectedTime.end_time,
                enabled: true,
                time_zone: availability.time_zone,
              }}
              isSubmitting={isSubmittingAppointmentRequest}
              isRequestSent={isRequestSent}
              date={selectedTime.date}
              handleSubmit={submitAppointmentRequest}
            />
          </div>
        </div>
      ) : (
        appointmentAvailability.map((mentorAvailability) => (
          <div className='mb-4'>
            <div>
              <h6>
                {GetDayOfWeekDisplayName(availability)?.display_name},{" "}
                {
                  monthIndexToAbbreviation[
                    new Date(mentorAvailability.date).getMonth()
                  ]
                }{" "}
                {mentorAvailability.date.getDate()}
              </h6>
            </div>

            <div
              style={{
                backgroundColor: "#eee",
                padding: 10,
                display: "flex",
                overflowX: "scroll",
                borderBottomStyle: "solid",
                borderTopStyle: "solid",
                borderColor: "#aaa",
              }}
            >
              {mentorAvailability.availability.map((day) => (
                <TimeChoice
                  onClick={() =>
                    setSelectedTime({
                      date: mentorAvailability.date,
                      start_time: day.start_time,
                      end_time: day.end_time,
                    })
                  }
                  variant='outline'
                  disabled={day.booked}
                >
                  <h6 style={{ margin: 0 }}>
                    {convertTo12HourFormat(
                      convertTimeWithoutDate(
                        day.start_time,
                        availability.time_zone,
                        mapTimeZoneToInfo(timeZone)?.abbreviation
                      )
                    )}
                  </h6>
                </TimeChoice>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

const CardContainer = styled(Card)`
  max-width: 250px;
  margin: 10px 5px;
  cursor: pointer;
  box-shadow: -1px 2px 2px 2px #eee;
  -webkit-box-shadow: -1px 2px 2px 2px #eee;
  -moz-box-shadow: -1px 2px 3px 3px #eee;
  &:hover {
    box-shadow: -1px 2px 4px 4px #ddd;
    -webkit-box-shadow: -1px 2px 4px 4px #ddd;
    -moz-box-shadow: -1px 2px 4px 4px #ddd;
  }
`;

const RowContainer = styled(Row)`
  border-top-style: solid;
  border-width: 1px;
  border-color: #aaa;
  padding-top: 20px;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: rgba(55, 133, 191, 0.24);
    color: black;
  }
`;

const TimeChoice = styled(Button)`
  margin: 5px;
  padding: 10px;
  background-color: #fff;
  color: rgba(55, 133, 191, 0.94);
  cursor: pointer;
  width: 500px;
  &:hover {
    color: #fff;
    background-color: rgba(55, 133, 191, 0.94);
  }
`;
