import styled from "styled-components";
import { ConnectionData, MentorDashboardProps } from "./interfaces";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import logo from "./logo.png";
import axios from "axios";
import { Connections } from "../pages/UserProfile";
import PersonalFormEdit from "../SignUp/form/PersonalFormEdit";
import SchoolFormEdit from "../SignUp/form/SchoolFormEdit";
import { PDFViewer } from "../Common/PDFViewer";
import { FileUploader } from "../Common/FileUploader";
import { SetAvailability } from "../SetAvailability/SetAvailability";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { getMentorsFavoriteCount } from "../../apis/User";
import { Session } from "../ui/table/SessionRequestContainer";
import {
  GetAppointmentsByMentee,
  GetAppointmentsByMentor,
} from "../../apis/Appointment/Appointment";
import { AppointmentContainer } from "../Appointment/AppointmentContainer";
import { DisplayClassification } from "../Common/DisplayClassification";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const MentorDashboard = ({ data }: MentorDashboardProps) => {
  const [key, setKey] = useState("personal");
  const [connectionsData, setConnectionsData] = useState([]);
  const [isLoadingConnectionData, setIsLoadingConnectionData] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileFormData, setSelectedFileFormData] = useState(null);
  const [userResumeLink, setUserResumeLink] = useState<string | null>(
    data.resume_link
  );
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [favoriteCount, setFavoriteCount] = useState<number | null>(0);

  const [sessions, setSessions] = useState<Session[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConnections = async () => {
    try {
      const { data: connectionsGetData, headers } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/user/${data.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      await setConnectionsData(connectionsGetData);
      setIsLoadingConnectionData(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFetchFavorites = async () => {
    try {
      const favoriteCountData: { count: number } =
        await getMentorsFavoriteCount(data.id);

      setFavoriteCount(favoriteCountData.count);
    } catch (e) {
      console.log("fetch Favorites failed");
      console.log(e);
    }
  };

  const handleFetchSessionsForUser = async (id: string | number) => {
    setIsLoading(true);
    try {
      const sessionData: Session[] = await GetAppointmentsByMentor(id);

      setSessions(sessionData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getConnections();
    handleFetchFavorites();
    handleFetchSessionsForUser(data.id);
  }, []);

  const handleUploadFile = async (resumeFile) => {
    try {
      setIsUploadingFile(true);
      const { data: profileData } = await axios.post(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${data.id}/upload/resume`,
        resumeFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUserResumeLink(profileData.profile_resume);
      setIsUploadingFile(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRemoveResume = async (resumeFile) => {
    try {
      setIsUploadingFile(true);
      const { data: profileData } = await axios.delete(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${data.id}/upload/resume`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setUserResumeLink(null);
      setSelectedFile(null);
      setSelectedFileFormData(null);
      setIsUploadingFile(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectedFile && selectedFileFormData) {
      handleUploadFile(selectedFileFormData);
    }
  }, [selectedFile, selectedFileFormData]);

  const acceptedSessions = sessions.filter(
    (connection) => connection?.status === "ACCEPTED"
  );

  return (
    <>
      <Row style={{ margin: "20px 0px" }}>
        <Col md={12}>
          <div
            style={{
              display: "flex",
            }}
          >
            {/* <img src={logo} width="300" height="300" alt="frontend-app" /> */}
            <div>
              <h1>Mentor Dashboard</h1>
              <p
                style={{
                  margin: "5px 20px 5px 0px",
                  backgroundColor: "#ff7701",
                  padding: 5,
                  color: "white",
                  borderColor: "#eee",
                  borderRadius: 9,
                  fontSize: "1.8em",
                }}
              >
                Manage your account details. Set availability, view connection
                and appointment metrics
              </p>
            </div>
          </div>
        </Col>
      </Row>

      <Row style={{ margin: "20px 0px" }}>
        <ImageContainerContainer lg={9}>
          <FloatingContainer>
            <div>
              <Row>
                <UserBasicInfoContainer>
                  <Row style={{ justifyContent: "center" }}>
                    <ImageContainer>
                      {data.profile_image ? (
                        <ProfileImg
                          src={`${data.profile_image}`}
                          alt="frontend-app"
                        />
                      ) : (
                        <img
                          style={{ marginTop: 10 }}
                          src={logo}
                          width={"75%"}
                          height={"75%"}
                        />
                      )}
                    </ImageContainer>

                    <div style={{ margin: "20px 10px", flex: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div>
                          <h2>
                            <i>
                              {data.first_name} {data.last_name}{" "}
                            </i>
                          </h2>
                          {/* <span
                  style={{
                    backgroundColor: "rgb(255 119 1)",
                    color: "white",
                    padding: "3px 5px",
                    marginLeft: 10,
                    fontWeight: 900,
                    fontSize: ".8rem",
                  }}
                >
                  MENTOR
                </span> */}
                        </div>
                        <div>
                          <h6>
                            <i>{data.email}</i>
                          </h6>
                        </div>
                      </div>

                      <div style={{ margin: "10px 0px" }}>
                        {data?.alumni ? (
                          <div style={{ margin: "15px 0px", display: "flex" }}>
                            <h6>
                              Alumni
                              {data.linkedin_url && (
                                <>
                                  {" | "}
                                  <a target="_blank" href={data.linkedin_url}>
                                    <FontAwesomeIcon
                                      color="#0077b5"
                                      size="xl"
                                      icon={faLinkedin}
                                    />
                                  </a>
                                </>
                              )}
                            </h6>
                          </div>
                        ) : (
                          <div style={{ margin: "15px 0px" }}>
                            <h6>
                              <DisplayClassification userData={data} />{" "}
                              {data.linkedin_url && (
                                <>
                                  {" | "}
                                  <a target="_blank" href={data.linkedin_url}>
                                    <FontAwesomeIcon
                                      color="#0077b5"
                                      size="xl"
                                      icon={faLinkedin}
                                    />
                                  </a>
                                </>
                              )}
                            </h6>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            borderTopStyle: "solid",
                            borderWidth: 1,
                            borderBottomStyle: "solid",
                            padding: "10px 0px",
                          }}
                        >
                          {data?.state && (
                            <Button
                              style={{
                                margin: "5px 20px 5px 0px",
                                borderColor: "rgb(0, 119, 181)",
                                color: "rgb(0, 119, 181)",
                                backgroundColor: "white",
                                padding: 3,
                              }}
                            >
                              <h6 style={{ margin: 0 }}>{data?.state}</h6>
                            </Button>
                          )}
                          {data?.military && (
                            <Button
                              style={{
                                margin: "5px 20px 5px 0px",
                                backgroundColor: "#ff7701",
                                padding: 3,
                                color: "white",
                                borderColor: "#eee",
                              }}
                            >
                              <h6
                                style={{ margin: 0 }}
                              >{`Military Affiliate`}</h6>
                            </Button>
                          )}
                          {data?.first_generation && (
                            <Button
                              style={{
                                margin: "5px 20px 5px 0px",
                                backgroundColor: "#eee",
                                color: "#0077b5",
                                padding: 5,
                              }}
                            >
                              <h6
                                style={{ margin: 0 }}
                              >{`First Generation Student`}</h6>
                            </Button>
                          )}
                          {data?.adult_learner && (
                            <Button
                              style={{
                                margin: "5px 20px 5px 0px",
                                color: "white",
                                backgroundColor: "#0077b5",
                                borderColor: "#0077b5",
                                padding: 5,
                              }}
                            >
                              <h6 style={{ margin: 0 }}>{`21+`}</h6>
                            </Button>
                          )}
                        </div>
                      </div>
                      <div>
                        <h6>{data?.bio}</h6>
                      </div>
                    </div>
                  </Row>
                </UserBasicInfoContainer>
              </Row>

              <Row>
                <AppointmentContainer
                  type="upcoming"
                  appointments={acceptedSessions}
                />
              </Row>
            </div>
          </FloatingContainer>
        </ImageContainerContainer>

        <Col lg={3}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <UserMetricsRow>
              <Col lg={12}>
                <FloatingContainer
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h6
                    style={{
                      backgroundColor: "white",
                      color: "rgb(0, 119, 181)",
                      padding: 5,
                      borderRadius: 9,
                      borderWidth: 1,
                      fontSize: "1.2em",
                      borderStyle: "solid",
                    }}
                  >
                    Connection Request
                  </h6>
                  <h5
                    style={{
                      backgroundColor: "rgb(0, 119, 181)",
                      color: "white",
                      padding: 5,
                      borderRadius: 9,
                      borderWidth: 1,
                      fontSize: "1.8em",
                      borderStyle: "solid",
                      width: "fit-content",
                    }}
                  >
                    {
                      connectionsData.filter(
                        (connection: ConnectionData) =>
                          connection.status === "REQUESTED"
                      ).length
                    }
                  </h5>
                  <i>pending connection requests</i>
                </FloatingContainer>
              </Col>
              <Col lg={12}>
                <FloatingContainer
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h6
                    style={{
                      backgroundColor: "white",
                      color: "rgb(0, 119, 181)",
                      padding: 5,
                      borderRadius: 9,
                      borderWidth: 1,
                      fontSize: "1.2em",
                      borderStyle: "solid",
                    }}
                  >
                    Connections
                  </h6>
                  <h5
                    style={{
                      backgroundColor: "rgb(0, 119, 181)",
                      color: "white",
                      padding: 5,
                      borderRadius: 9,
                      borderWidth: 1,
                      fontSize: "1.8em",
                      borderStyle: "solid",
                      width: "fit-content",
                    }}
                  >
                    {
                      connectionsData.filter(
                        (connection: ConnectionData) =>
                          connection.status === "ACCEPTED"
                      ).length
                    }
                  </h5>
                  <i>network connections</i>
                </FloatingContainer>
              </Col>
              <Col lg={12}>
                <FloatingContainer
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h6
                    style={{
                      backgroundColor: "white",
                      color: "rgb(0, 119, 181)",
                      padding: 5,
                      borderRadius: 9,
                      borderWidth: 1,
                      fontSize: "1.2em",
                      borderStyle: "solid",
                    }}
                  >
                    Favorites
                  </h6>
                  <h5
                    style={{
                      backgroundColor: "rgb(0, 119, 181)",
                      color: "white",
                      padding: 5,
                      borderRadius: 9,
                      borderWidth: 1,
                      fontSize: "1.8em",
                      borderStyle: "solid",
                      width: "fit-content",
                    }}
                  >
                    {favoriteCount}
                  </h5>
                  <i>Users have set you as a favorite mentor</i>
                </FloatingContainer>
              </Col>
            </UserMetricsRow>
          </div>
        </Col>
      </Row>

      <Row style={{ margin: "20px 0px" }}>
        <Col>
          <FloatingContainer>
            <SetAvailability userData={data} showCurrent={true} />
          </FloatingContainer>
        </Col>
      </Row>

      <Row>
        <Col>
          <FloatingContainer>
            <h1 style={{ marginBottom: 25 }}>Account Information</h1>
            <Col lg={11}>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="personal" title="Personal Details">
                  <PersonalFormEdit
                    setKey={setKey}
                    data={data}
                    isLoading={true}
                    handleChange={() => {}}
                    handleUserUpdate={() => {}}
                  />
                </Tab>
                <Tab eventKey="school" title="Profile Information">
                  <SchoolFormEdit
                    setKey={setKey}
                    data={data}
                    isLoading={true}
                    handleUserUpdate={() => {}}
                  />
                </Tab>
              </Tabs>
            </Col>
            <Col lg={1} />
          </FloatingContainer>
        </Col>
        <Col>
          <FloatingContainer>
            <Col>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1>Resume</h1>

                  {userResumeLink && (
                    <Button
                      onClick={handleRemoveResume}
                      variant="outline-danger ml-3"
                    >
                      remove
                    </Button>
                  )}
                </div>
                <div style={{ backgroundColor: "#eee", padding: 20 }}>
                  {userResumeLink ? (
                    <PDFViewer id={data.id} fileLink={userResumeLink} />
                  ) : (
                    <FileUploader
                      data={data}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      setSelectedFileFormData={setSelectedFileFormData}
                      isLoading={isUploadingFile}
                    />
                  )}
                </div>
              </div>
            </Col>
          </FloatingContainer>
        </Col>
      </Row>
    </>
  );
};

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
`;
const LeftContentSection = styled("div")`
  width: 400px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ImageCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eee;
  padding: 40px;
`;

const SmallImageContainer = styled("div")`
  height: 65px;
  width: 65px;
  background-color: black;
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const LeftContentContainer = styled("div")`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const RightContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
`;

const UserMetricsRow = styled(Row)`
  width: 100%;
  padding-bottom: 20px;
`;

const ImageContainer = styled("div")`
  height: 250px;
  width: 250px;
  background-color: #eee;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border-style: solid;
  border-color: black;
  border-width: 3px;
  @media (max-width: 768px) {
    justify-content: center;
    margin-left: 0px !important;
  }
`;

const ProfileImg = styled("img")`
  height: 100%;
  min-height: 230px;
  min-width: 249px;
  object-fit: cover;
`;

const ImageContainerContainer = styled(Col)`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const StyledConnectionRequest = styled("div")`
  color: white;
  border-style: solid;
  height: 150px;
  border-width: 2px;
  border-color: #333;
  background-color: #1170b8;
  width: 150px;
  border-radius: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 6em;
  margin: 10px;
`;

const StyledConnections = styled("div")`
  color: white;
  border-style: solid;
  height: 150px;
  border-width: 2px;
  border-color: #333;
  background-color: rgb(255 119 1);
  width: 150px;
  border-radius: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 6em;
  margin: 10px;
  text-decoration: none !important;
`;

const FloatingContainer = styled("div")`
  margin: 20px 0px;
  background-color: #eee;
  padding: 20px;
  border-radius: 9px;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 93%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
`;

const UserBasicInfoContainer = styled("div")`
  display: flex;
  @media (max-width: 868px) {
    display: flex;
    justify-content: center;
  }
`;
