import React from "react";
import { UserData } from "../Mentor/interfaces";

export const DisplayClassification = ({ userData }: { userData: UserData }) => {
  const classification =
    userData.classification ?? "Classification Not Specified";
  if (classification === "Mentor") {
    let major = "";
    if (userData.entrepreneur) {
      major = "Entrepreneur";
    }
    if (userData.subject_expert) {
      major = `${major} | Subject Mater Expert`;
      major = userData.subject ? `${major} | ${userData.subject}` : major;
    }
    if (major) {
      return `${classification} ${major}`;
    } else {
      return classification;

      // style={{ fontSize: "1.2em", marginTop: 10 }}
      // ${classification}`}</p>
    }
  } else {
    if (userData.major && userData.major.length > 0) {
      return `${classification} | ${userData.major}`;
    } else {
      return classification;
    }
  }
};

export const DisplayClassificationText = (userData) => {
  const classification =
    userData.classification ?? "Classification Not Specified";
  if (classification === "Mentor") {
    let major = "";
    if (userData.entrepreneur) {
      major = "Entrepreneur";
    }
    // if (userData.subject_expert) {
    //   major = `${major} | Subject Mater Expert`;
    //   major = userData.subject ? `${major} | ${userData.subject}` : major;
    // }

    return (
      <div>
        {major ? (
          <div>
          <span style={{ border: "1px solid transparent", borderRadius:"6px", padding: "2px 4px", backgroundColor: "rgba(0, 0, 0, 0.5)"  }}>
            {classification} {major}
          </span>
        </div>
        ) : (
          <span style={{ border: "1px solid transparent", borderRadius:"6px", padding: "2px 4px", backgroundColor: "rgba(0, 0, 0, 0.5)"  }}>{classification}</span>
        )}
      </div>
    );

    // if (major) {
    //   return  `${classification} ${major} `;
    // } else {
    //   return classification;
    
    //   // style={{ fontSize: "1.2em", marginTop: 10 }}
    //   // ${classification}`}</p>
    // }
  } else {
    if (userData.major && userData.major.length > 0) {
      // return ` ${classification} ${userData.major}`;
      return (
        <div>
        <span style={{ border: "1px solid transparent", borderRadius:"6px", padding: "2px 4px",  marginRight:"5px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          {classification} 
        </span>
        <span style={{ border: "1px solid transparent", borderRadius:"6px", padding: "2px 4px", backgroundColor: "rgba(0, 0, 0, 0.5)"  }}>
          {userData.major}
        </span>
      </div>
      );
    } else {
      // return classification;
      return (
        <span style={{ border: "1px solid transparent ", borderRadius:"6px", padding: "2px 4px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          {classification}
        </span>
      );
    }
  }
};

