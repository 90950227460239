import axios from "axios";
interface UserConnections {}

/***
 * Get Verified for a user
 */
const fetchUsers = async (searchString: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/search/`,
    {
      method: "POST",
      data: {
        searchString,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

/***
 * return token to reset password with api resetPasswordWithConfirmation
 */
const resetPasswordInit = async (email: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/init-reset/`,
    {
      method: "POST",
      data: {
        email,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

/***
 * updates users password given confirm token
 */
const resetPasswordWithConfirmation = async (
  password: string,
  email: string,
  confirmationCode: string
) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/complete-reset/`,
    {
      method: "POST",
      data: {
        password,
        confirmationCode,
        email,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const getIsUserFavorite = async (
  currentUserId: string,
  targetUserId: string
) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${currentUserId}/is-favorite`,
    {
      method: "POST",
      data: {
        userId: targetUserId,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const setUserFavorite = async (mentee_id: string, mentor_id: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/favorite`,
    {
      method: "POST",
      data: {
        mentee_id,
        mentor_id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const removeUserFavorite = async (mentee_id: string, mentor_id: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/favorite`,
    {
      method: "DELETE",
      data: {
        mentee_id,
        mentor_id,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const getFavoriteMentors = async (menteeId: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${menteeId}/my-favorites/users`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

const getMentorsFavoriteCount = async (mentorId: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${mentorId}/mentor-favorite-count`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export {
  fetchUsers,
  resetPasswordInit,
  resetPasswordWithConfirmation,
  getIsUserFavorite,
  setUserFavorite,
  removeUserFavorite,
  getFavoriteMentors,
  getMentorsFavoriteCount,
};
