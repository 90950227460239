/**
 * Home page
 *
 * created by Ty D'Angelo 9/9/2023
 */

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SignUpOne from "./signup/SignUpOne";
import { HomeNavbar } from "../ui/navbar/HomeNavbar";

export default function Home() {
  return (
    <>
      <div className='mainContainer'>
        <SignUpOne />
      </div>
    </>
  );
}
