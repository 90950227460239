import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { SpaceBetweenContainer } from "../../ui/BaseComponents";
import StateButton from "../button/StateButton";
import { FormProps } from "./interfaces";
import TabButtonForward from "../button/TabButtonForward";

export default function PersonalForm(p: FormProps) {
  const [showFelonTextBox, setShowFelonTextBox] = useState(false);
  const [firstName, setFirstName] = useState(p.data.first_name);
  const [lastName, setLastName] = useState(p.data.last_name);
  const [gender, setGender] = useState(p.data.gender);
  const [race, setRace] = useState(p.data.gender);
  const [phone, setPhone] = useState(p.data.phone_number);
  const [country, setCountry] = useState(p.data.country);
  const [state, setState] = useState(p.data.state);
  const [zip, setZip] = useState(p.data.zip_code);
  const [felon, setFelon] = useState(p.data.is_felon);
  const [felonMessage, setFelonMessage] = useState(p.data.felon_explanation);

  const validateForm = () => {
    // Check for empty strings in non-boolean fields
    if (
      !firstName ||
      !lastName ||
      !gender ||
      !race ||
      !phone ||
      !country ||
      felon == null ||
      !state ||
      !zip
    ) {
      alert("Please complete all required fields.");
      console.log("Form is not valid:", {
        firstName,
        lastName,
        gender,
        race,
        phone,
        country,
        state,
        zip,
        felon,
        felonMessage,
      });
      return false;
    }

    // Check if felon is true, then felonMessage should not be empty
    if (felon && !felonMessage) {
      console.log("Form is not valid:", {
        firstName,
        lastName,
        gender,
        race,
        phone,
        country,
        state,
        zip,
        felon,
        felonMessage,
      });
      alert("Please provide details for the felony.");
      return false;
    }

    console.log("Form is valid:", {
      firstName,
      lastName,
      gender,
      race,
      phone,
      country,
      state,
      zip,
      felon,
      felonMessage,
    });
    p.setKey("school");
    // If all checks pass
    return true;
  };

  return (
    <>
      <Form>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicFirstName'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                First Name
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='First Name'
                style={{ height: "3.5em" }}
                defaultValue={p.data.first_name}
                name='first_name'
                onChange={(e) => {
                  setFirstName(e.target.value);
                  p.handleChange(e);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicLastName'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Last Name
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Last Name'
                style={{ height: "3.5em" }}
                defaultValue={p.data.last_name}
                name='last_name'
                onChange={(e) => {
                  setLastName(e.target.value);
                  p.handleChange(e);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicGender'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Gender
              </Form.Label>
              <Form.Select
                style={{ height: "3.5em" }}
                defaultValue={p.data.gender ? p.data.gender : null}
                onChange={(e) => {
                  setGender(e.target.value);
                  p.handleChange(e);
                }}
                name='gender'
              >
                <option disabled selected>
                  Choose...
                </option>
                <option value='Male'>Male</option>
                <option value='Female'>Female</option>
                <option value='Non-Binary'>Non-Binary</option>
                <option value='Genderfluid'>Genderfluid</option>
                <option value='Pangender'>Pangender</option>
                <option value='Other'>Other</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicGender'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Race / Ethnicity
              </Form.Label>
              <Form.Select
                style={{ height: "3.5em" }}
                defaultValue={p.data.gender ? p.data.gender.trim() : null}
                onChange={(e) => {
                  setRace(e.target.value);
                  p.handleChange(e);
                }}
                name='race'
              >
                <option disabled selected>
                  Choose...
                </option>
                <option value='Black or African American'>
                  Black or African American
                </option>
                <option value='Hispanic/Latino'>Hispanic/Latino</option>
                <option value='Two or More Races'>Two or More Races</option>
                <option value='American Indian or Alaskan Native'>
                  American Indian or Alaskan Native
                </option>
                <option value='Native Hawaiian or Other Pacific Islander'>
                  Native Hawaiian or Other Pacific Islander
                </option>
                <option value='Race/Ethnicity Unknown'>
                  Race/Ethnicity Unknown
                </option>
                <option value='Asian'>Asian</option>
                <option value='White'>White</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Email address
              </Form.Label>
              <Form.Control
                type='email'
                disabled={true}
                placeholder='Email address'
                style={{ height: "3.5em" }}
                defaultValue={p.data.email}
                name='email'
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicPhoneNumber'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Phone Number
              </Form.Label>
              <Form.Control
                type='tel'
                placeholder='Phone Number'
                style={{ height: "3.5em" }}
                defaultValue={p.data.phone_number}
                onChange={(e) => {
                  setPhone(e.target.value);
                  p.handleChange(e);
                }}
                name='phone_number'
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {p.data.type && p.data.type !== "MENTOR" && (
            <Col lg='6'>
              <Form.Group className='mb-3' controlId='formBasicStudentID'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Student ID #
                </Form.Label>
                <Form.Control
                  type='text'
                  disabled={true}
                  placeholder='Student ID #'
                  style={{ height: "3.5em" }}
                  defaultValue={p.data.student_id}
                  name='student_id'
                />
              </Form.Group>
            </Col>
          )}
          <Col lg='6'>
            <Form.Group className='mb-3' controlId='formBasicCountry'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Country
              </Form.Label>
              <Form.Select
                style={{ height: "3.5em" }}
                name='country'
                defaultValue={p.data.country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  p.handleChange(e);
                }}
              >
                <option disabled selected>
                  Choose...
                </option>
                <option value='USA'>USA</option>{" "}
                <option value='International'>International</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicCountry'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                State
              </Form.Label>
              <Form.Select
                style={{ height: "3.5em" }}
                name='state'
                onChange={(e) => {
                  setState(e.target.value);
                  p.handleChange(e);
                }}
                defaultValue={p.data.state}
              >
                <option disabled selected>
                  Choose...
                </option>
                <option value='Alabama'>Alabama</option>
                <option value='Alaska'>Alaska</option>
                <option value='Arizona'>Arizona</option>
                <option value='Arkansas'>Arkansas</option>
                <option value='California'>California</option>
                <option value='Colorado'>Colorado</option>
                <option value='Connecticut'>Connecticut</option>
                <option value='Delaware'>Delaware</option>
                <option value='Florida'>Florida</option>
                <option value='Georgia'>Georgia</option>
                <option value='Hawaii'>Hawaii</option>
                <option value='Idaho'>Idaho</option>
                <option value='Illinois'>Illinois</option>
                <option value='Indiana'>Indiana</option>
                <option value='Iowa'>Iowa</option>
                <option value='Kansas'>Kansas</option>
                <option value='Kentucky'>Kentucky</option>
                <option value='Louisiana'>Louisiana</option>
                <option value='Maine'>Maine</option>
                <option value='Maryland'>Maryland</option>
                <option value='Massachusetts'>Massachusetts</option>
                <option value='Michigan'>Michigan</option>
                <option value='Minnesota'>Minnesota</option>
                <option value='Mississippi'>Mississippi</option>
                <option value='Missouri'>Missouri</option>
                <option value='Montana'>Montana</option>
                <option value='Nebraska'>Nebraska</option>
                <option value='Nevada'>Nevada</option>
                <option value='New Hampshire'>New Hampshire</option>
                <option value='New Jersey'>New Jersey</option>
                <option value='New Mexico'>New Mexico</option>
                <option value='New York'>New York</option>
                <option value='North Carolina'>North Carolina</option>
                <option value='North Dakota'>North Dakota</option>
                <option value='Ohio'>Ohio</option>
                <option value='Oklahoma'>Oklahoma</option>
                <option value='Oregon'>Oregon</option>
                <option value='Pennsylvania'>Pennsylvania</option>
                <option value='Rhode Island'>Rhode Island</option>
                <option value='South Carolina'>South Carolina</option>
                <option value='South Dakota'>South Dakota</option>
                <option value='Tennessee'>Tennessee</option>
                <option value='Texas'>Texas</option>
                <option value='Utah'>Utah</option>
                <option value='Vermont'>Vermont</option>
                <option value='Virginia'>Virginia</option>
                <option value='Washington'>Washington</option>
                <option value='West Virginia'>West Virginia</option>
                <option value='Wisconsin'>Wisconsin</option>
                <option value='Wyoming'>Wyoming</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formBasicZipCode'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Zip Code
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='Zip Code'
                name='zip_code'
                style={{ height: "3.5em" }}
                defaultValue={p.data.zip_code}
                onChange={(e) => {
                  setZip(e.target.value);
                  p.handleChange(e);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formFelon'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Have you been convicted of any criminal charges?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_fe' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_fe'
                    name='is_felon'
                    value='true'
                    onChange={(e) => {
                      setFelon(true);
                      p.handleChange(e);
                    }}
                    onClick={() => {
                      setShowFelonTextBox(true);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor='no_fe' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_fe'
                    name='is_felon'
                    value='false'
                    onChange={(e) => {
                      setFelon(false);
                      p.handleChange(e);
                    }}
                    onClick={() => {
                      setShowFelonTextBox(false);
                    }}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        {showFelonTextBox && (
          <Row>
            <Form.Group className='mb-3' controlId='formBio'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Please explain your past or present criminal charges
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                type='text'
                placeholder=''
                name='felon_explanation'
                defaultValue={p.data.felon_explanation}
                onChange={(e) => {
                  setFelonMessage(e.target.value);
                  p.handleChange(e);
                }}
              />
            </Form.Group>
          </Row>
        )}
      </Form>
      <SpaceBetweenContainer>
        <StateButton
          height='3.75em'
          width='10em'
          backgroundColor='#FFFFFF'
          title='Cancel'
          color='#2091F9'
        />
        {/* TabChangeButton */}
        <TabButtonForward
          height='3.75em'
          width='10em'
          backgroundColor='#2091F9'
          title='Continue'
          setKey={() => {
            validateForm();
          }}
        />
      </SpaceBetweenContainer>
    </>
  );
}
