import React, { useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import { deleteUserInterests, postUserInterests } from "../../apis/Interests";

export const Interests = ({ interests, setInterests, userId }) => {
  const [currentInterests, setCurrentInterests] = useState(interests ?? []);
  const [newInterestName, setNewInterestName] = useState("");

  const handleAddInterest = async () => {
    try {
      const {id} = await postUserInterests(userId, newInterestName);
      const copy = [...currentInterests, { name: newInterestName, id }];
      setInterests(copy);
      setCurrentInterests(copy);
    } catch (e) {
      console.log(e);
      const copy = [...currentInterests];
      copy.pop();
      setCurrentInterests(copy);
    }
    setNewInterestName("");
  };

  const handleAddRemove = async (id) => {
    try {
      const copy = currentInterests.filter((interest) => {
        return interest.id !== id;
      });
      setCurrentInterests(copy);
      setInterests(copy);
      await deleteUserInterests(id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
       <Row style={{ marginTop: 50 ,borderBottomStyle:"solid",padding:"20px 0px",borderBottomColor:"#ccc" }}>
        <Col lg={2} />

        <Col lg={8}>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label
              style={{
                fontSize: "1em",
                fontWeight: "400",
                lineHeight: "1.5em",
              }}
            >
              <h3>Add</h3>
            </Form.Label>
            <br />
            <p style={{ marginBottom: 10 }}>
              <i>Add a name of the interest you would like to add</i>
            </p>
            <Form.Control
              type='text'
              placeholder='Sports'
              style={{ height: "3.5em" }}
              defaultValue={newInterestName}
              onChange={(e) => {
                setNewInterestName(e.target.value);
              }}
              value={newInterestName}
            />

            <div style={{ textAlign: "right", marginTop: 15 }}>
              <Button
                variant='primary'
                className='ml-auto pr-5 pl-5'
                onClick={handleAddInterest}
              >
                Update
              </Button>
            </div>
          </Form.Group>
        </Col>
        <Col lg={2} />
      </Row>
      <Row style={{padding:"50px 0px"}}>
        <Col lg={1} />
        <Col lg={10}>
          <div>
            <h3>Current Interests</h3>
          </div>
          <div style={{ display: "flex",flexWrap:"wrap",paddingTop:20 }}>
            {currentInterests.length > 0 ? (
              currentInterests.map((interest) => {
                return (
                  <div>
                    <div
                      style={{
                        margin: "5px 20px 5px 0px",
                        minWidth: 80,
                        padding: 5,
                        paddingLeft: 15,
                        backgroundColor: "#eee",
                        color: "rgba(55, 133, 191, 0.94)",
                        borderColor: "rgba(55, 133, 191, 0.94)",
                        borderStyle: "solid",
                        borderRadius: 15,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {interest.name}
                      <Button
                        style={{
                          marginBottom: "0px",
                          padding: "0px 10px",
                          position: "relative",
                          top: -10,
                          left: 10,
                          cursor: "pointer",
                          backgroundColor: "#eee",
                          color: "black",
                        }}
                        onClick={() => {
                          handleAddRemove(interest.id);
                        }}
                      >
                        x
                      </Button>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Nothing to see here...</p>
            )}
          </div>
        </Col>
        <Col lg={1} />
      </Row>
     
    </div>
  );
};
