import React, { useEffect, useState } from "react";
import { Col, Spinner, Tab, Tabs, Row } from "react-bootstrap";
import { MentorListProps, UserData } from "./interfaces";
import MentorCard from "./MentorCard";
import { UserCardFilter } from "../Common/FilterUserCards";
import { getFavoriteMentors } from "../../apis/User";
import MentorCardTwo from "./MentorCardTwo";

export const MentorFilterContainer = ({
  mentors,
  isLoading: isLoadingMentors,
  menteeId,
}: MentorListProps) => {
  const [favoriteMentors, setFavoriteMentors] = useState<UserData[]>([]);
  const [isLoadingFavorites, setIsLoadingFavorites] = useState<boolean>(true);

  const handleFetchUserFavorites = async () => {
    try {
      const favorites: UserData[] = await getFavoriteMentors(menteeId);
      setFavoriteMentors(favorites);
      setIsLoadingFavorites(false);
    } catch (e) {
      setIsLoadingFavorites(false);
      console.log("Error loading user favorites");
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isLoadingMentors) handleFetchUserFavorites();
  }, [isLoadingMentors]);

  if (isLoadingMentors || isLoadingFavorites)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          paddingTop: 30,
        }}
      >
        <Spinner style={{ color: "rgba(55, 133, 191, 0.94)" }} />
      </div>
    );
  return (
    <div style={{ margin: "-10px" }}>
      <UserCardFilter
        favorites={favoriteMentors}
        users={mentors}
        type='MENTOR'
      />
    </div>
  );
};
