import axios from "axios";
import { AppointmentRequest } from "../../components/SetAvailability/AppointmentForm";

const CreateAppointment = async (
  appointmentRequestData: AppointmentRequest
) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/request`,
    {
      method: "POST",
      data: appointmentRequestData,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};
const GetAppointmentsByMentor = async (
  id: string | number,
  status?: string
) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/request/mentor/${id}`,
    {
      method: "POST",
      data: {
        status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const GetAppointmentsByMentee = async (id: string | number) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/request/mentee/${id}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const DeleteAppointment = async (id: string | number) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/request/${id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

const UpdateAppointment = async (id: string | number, status: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/appointment/request/${id}`,
    {
      method: "PATCH",
      data: {
        status,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data;
};

export {
  CreateAppointment,
  GetAppointmentsByMentor,
  GetAppointmentsByMentee,
  DeleteAppointment,
  UpdateAppointment,
};
