import styled from "styled-components";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import {
  Session,
  getConnectionDetails,
} from "../ui/table/SessionRequestContainer";
import {
  GetDayOfWeekDisplayNameFromDate,
  convertTo12HourFormat,
  monthIndexToAbbreviation,
} from "../SetAvailability/AvailableDayCard";
import { useData } from "../utils/context/DataContext";
import {
  VIRTUAL_OPTION_VALUES,
  getMeetingTypeDisplayName,
} from "../SetAvailability/AppointmentForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStartOutline } from "@fortawesome/free-regular-svg-icons";
import SessionStatusButton from "../ui/button/SessionStatusButton";
import React, { useState, useEffect } from "react";
import { AppointmentReview } from "./AppointmentReview";
// import logo from "../../components/pages/logo.png";
import { ContentContainer } from "../ui/BaseComponents";
import {Table, TableBody, TableCell, TableRow, TableHead } from "@mui/material";
import { CreateReview, GetReviewById} from "../../apis/Review";
import { GetAppointmentsByMentee } from "../../apis/Appointment/Appointment";
import { DeleteAppointment } from "../../apis/Appointment/Appointment";

export interface ReviewForm {
  id?: string;
  user_id:string | undefined;

  appointment_id: string;
  rating: number;
}

export interface ReviewAPIResult extends ReviewForm {
  id: string;
}

function combineDateTime(date, startTime, endTime) {
  // Parse the date string
  const parsedDate = new Date(date);

  // Extract hours and minutes from start and end times
  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  // Set the hours and minutes for the parsed date
  parsedDate.setUTCHours(startHours, startMinutes, 0, 0);

  // Format the start and end date-time strings
  const startDateString = parsedDate.toISOString();

  // Set the hours and minutes for the parsed date for the end time
  parsedDate.setUTCHours(endHours, endMinutes, 0, 0);

  // Format the end date-time string
  const endDateString = parsedDate.toISOString();

  return {
    start_date_time: startDateString,
    end_date_time: endDateString,
  };
}

function generateOutlookEventLink(
  subject,
  location,
  startDate,
  endDate,
  description
) {
  const calendarEvent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${subject}
DESCRIPTION:${description}
LOCATION:${location}
DTSTART:${startDate
    .toISOString()
    .replace(/[-:]/g, "")
    .replace(/\.\d{3}Z$/, "")}
DTEND:${endDate
    .toISOString()
    .replace(/[-:]/g, "")
    .replace(/\.\d{3}Z$/, "")}
END:VEVENT
END:VCALENDAR`;

  const outlookEventLink = `data:text/calendar;charset=utf-8,${encodeURIComponent(
    calendarEvent
  )}`;
  return outlookEventLink;
}

// Example usage
const subject = "Meeting with John";
const location = "Office";
const startDate = new Date(); // Replace with your start date
const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Assuming a 1-hour meeting
const description = "Discuss project updates";

const eventLink = generateOutlookEventLink(
  subject,
  location,
  startDate,
  endDate,
  description
);

export const AppointmentCard = ({
  appointment,
  type,
  isMentee,
  onCancelSession
}: {
  appointment: Session;
  type: "upcoming" | "previous";
  isMentee: boolean;
  onCancelSession: (id: string | number) => void; // Define onCancelSession in props
}) => {
  const { data } = useData();
  const Mentee = data.type === "MENTEE";
  const profile_image = Mentee
    ? appointment.mentor_profile_image
    : appointment.mentee_profile_image;
  const fullName = Mentee 
    ? appointment.mentor_first_name + " " + appointment.mentor_last_name
    : appointment.mentee_first_name + " " + appointment.mentee_last_name  
  const [rating, setRating] = useState<number>(0);
  const [reviewText, setReviewText] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [reviewId, setReviewId] = useState<string>(
    isMentee ? appointment.mentee_review_id : appointment.mentor_review_id
  );

  const RATING_OPTIONS = [
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
    },
  ];
    
  const { start_date_time, end_date_time } = combineDateTime(
    appointment.date,
    appointment.start_time,
    appointment.end_time
  );

  const [showSessions, setShowSessions] = useState(false);

  // Build the calendar link
  const calendarLink = `data:text/calendar;charset=utf-8,\
  BEGIN:VCALENDAR\n\
  VERSION:2.0\n\
  BEGIN:VEVENT\n\
  SUMMARY:${"session"}\n\
  DESCRIPTION:${"description"}\n\
  LOCATION:${"location"}\n\
  DTSTART:${start_date_time}\n\
  DTEND:${end_date_time}\n\
  END:VEVENT\n\
  END:VCALENDAR`;

  const link = encodeURI(calendarLink);

  const toggleDetails = () => setShowSessions(!showSessions);

  const CardFooter = () => {
    return type === "previous" ? (
      <></>
    ) : (
      <Card.Footer className='text-right'>
        <SessionStatusButton
          connectionId={appointment?.id}
          title='Cancel Session'
          status='CANCELLED'
        />
      </Card.Footer>
    );
  };

  const isPrevious = type === "previous";
  const upcoming = type === "upcoming";

  const height = isPrevious ? "auto" : "300px";

  const menteeReviewId = appointment.mentee_review_id;
  const mentorReviewId = appointment.mentor_review_id;

  const getHeight = () => {
    if (showSessions) return "auto";

    return height;
  };

  const dateFormat = new Intl.DateTimeFormat("en-US", { timeZone: "UTC" });
  const formattedDate = dateFormat.format(new Date(appointment.date));
  const dateWithTimeZone = new Date(formattedDate);

  useEffect(() => {
    const fetchReviewData = async () => {
      try {
        if (reviewId) {
          const reviewData: ReviewAPIResult = await GetReviewById(reviewId);
          setRating(reviewData.rating);
        }
      } catch (error) {
        console.error("Error fetching review data:", error);
      }
    };

    fetchReviewData();
  }, [reviewId]);

  const handleStarClick = async (ratingValue: number) => {
    if (!reviewId) {
      setRating(ratingValue);
      try {
        setIsSubmitting(true);
        const data: ReviewAPIResult = await CreateReview({
          rating: ratingValue,
          review:reviewText,
          user_id: isMentee ? appointment.mentee_id : appointment.mentor_id,
          appointment_id: appointment.id,
        });
        setRating(ratingValue);
        setReviewId(data.id);
        setIsSubmitting(false);
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

   // Navigate to the profile page
   const navigateToProfile = () => {
    const profileId = Mentee ? appointment.mentor_id : appointment.mentee_id;
    window.location.href = `/profile/${profileId}`;
  };

  // Cancel the session 
  const cancelSession = async () => {
    try {
      await DeleteAppointment(appointment.id); // Call the delete appointment API
      onCancelSession(appointment.id); // Call the callback to remove the session from the parent component's state
    } catch (error) {
      console.error("Error cancelling the session:", error);
    }
  };

  // Function to group sessions by year
function groupSessionsByYear(sessions) {
  const grouped = {};
  sessions.forEach((session) => {
    const year = new Date(session.date).getFullYear();
    if (!grouped[year]) {
      grouped[year] = [];
    }
    grouped[year].push(session);
  });
  return grouped;
}

// Group previous sessions by year
const previousSessionsByYear = groupSessionsByYear([appointment]);

  return (
      <div>
        {upcoming && (
          <CardContainer>
            <ContentContainer  style={{gap:"15px"}}>
              <SmallImageContainer>
                <SmallProfileImg src={profile_image} alt='frontend-app' />
              </SmallImageContainer>
              <Row>
                <RowTitle>Mentorship Session with</RowTitle>
                <RowContent>{fullName}</RowContent>
              </Row>
                <DateTimeRow>
                {`${GetDayOfWeekDisplayNameFromDate(new Date(dateWithTimeZone))?.display_name}, ${
                    monthIndexToAbbreviation[new Date(dateWithTimeZone).getMonth()]
                  } ${new Date(dateWithTimeZone).getDate()}`} at {convertTo12HourFormat(appointment.start_time)}        
                </DateTimeRow>
                <Row>
                  <ActionsContainer>
                      <ActionButton onClick={navigateToProfile}>
                        <h6 style={{fontWeight:"bold"}}>Reschedule</h6>
                      </ActionButton>
                      <ActionButton onClick={cancelSession} >
                        <h6 style={{fontWeight:"bold"}}>Cancel Session</h6>
                      </ActionButton>
                    </ActionsContainer>
                </Row>
            </ContentContainer>
          </CardContainer>
        )}

        {isPrevious && (
          <>
            {Object.keys(previousSessionsByYear).map((year) => (
              <React.Fragment key={year}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={yearStyle}>
                        <span style={{marginLeft:"10px"}}>{year}</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {previousSessionsByYear[year].map((session) => (
                      <TableRow key={session.id}>
                        <TableCell style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                          <div>
                            <SmallImageContainer>
                              <SmallProfileImg src={profile_image} alt="frontend-app" />
                            </SmallImageContainer>
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <div style={{ whiteSpace: "nowrap", fontWeight: "bold", fontSize: "20px" }}>{fullName}</div>
                            <div style={{ whiteSpace: "nowrap", fontSize: "15px" }}>
                              {`${GetDayOfWeekDisplayNameFromDate(new Date(session.date))?.display_name}, ${
                                monthIndexToAbbreviation[new Date(session.date).getMonth()]
                              } ${new Date(session.date).getDate()} at ${convertTo12HourFormat(session.start_time)}`}
                            </div>
                          </div>
                          <div style={{ fontSize: "15px" }}>
                            {session.meeting_details}
                          </div>
                          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            {RATING_OPTIONS.map((star, index) => (
                              <FontAwesomeIcon
                                key={index}
                                fontSize={"2em"}
                                color={!reviewId ? "#ff7701" : "#1170b8"}
                                icon={star.value <= rating ? faStar : faStartOutline}
                                style={{ cursor: !!reviewId ? "auto" : "pointer" }}
                                onClick={() => handleStarClick(star.value)}
                              />
                            ))}
                          </div>
                          <div style={{ color: "#1170b8", cursor: "pointer", fontWeight: "bold", whiteSpace: "nowrap", fontSize: "15px" }} onClick={navigateToProfile}>
                            Book Again
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </React.Fragment>
            ))}
          </>
        )}
    </div>
  );
};

const CardContainer = styled(Card)`
  // width: 350px;
  // margin: 10px 5px;
  // cursor: pointer;
  // box-shadow: -1px 2px 2px 2px #eee;
  // -webkit-box-shadow: -1px 2px 2px 2px #eee;
  // -moz-box-shadow: -1px 2px 3px 3px #eee;
  // &:hover {
  //   box-shadow: -1px 2px 4px 4px #ddd;
  //   -webkit-box-shadow: -1px 2px 4px 4px #ddd;
  //   -moz-box-shadow: -1px 2px 4px 4px #ddd;
  // }
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 400px;
`;


const SmallImageContainer = styled("div")`
  // height: 75px;
  // width: 75px;
  // background-color: black;
  // border-radius: 9px;
  // display: flex;
  // justify-content: center;
  // overflow: hidden;
  // border-style: solid;
  // border-color: black;
  // border-width: 1px;
`;

const SmallProfileImg = styled("img")`
//   min-height: 85px;
//   min-width: 75px;
//   object-fit: cover;
// 
width: 60px;
height: 60px;
border-radius: 50%;
`;

const RowTitle = styled.h6`
font-weight: bold;
font-size: 25px;
margin: 0;
`;

const RowContent = styled.h6`
font-weight: bold;
color: #1170b8;
font-size: 25px;
margin: 0;
`;

const DateTimeRow = styled.div`
  font-size: 16px; /* Custom font size for date and time */
  color: #555; /* Custom color */
`;

const ActionButton = styled.div`
  padding-left:6px;
  padding-right:6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #aaa;
  border-radius: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 60px;
  height: 60px;
  color: #1170b8;
`;

const RowDescription = styled.div`
  font-size: 16px; /* Custom font size for date and time */
  color: #555; /* Custom color */
`;

const yearStyle = {
  fontWeight: "bold",
  fontSize: "1em",
  borderBottom: "1px solid #ddd",
  backgroundColor:"#eee",
  padding: "5px",
  color: "grey"
};