import { useState, useEffect } from "react";
import { UserData } from "../Mentor/interfaces";
import React from "react";
import styled from "styled-components";
import { MenteeCard } from "../Mentee";
import { MentorCard } from "../Mentor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStartOutline } from "@fortawesome/free-regular-svg-icons";
import { Col, Row } from "react-bootstrap";
import { UserDataWFav } from "../Admin/MostFav";
import MentorCardTwo from "../Mentor/MentorCardTwo";

interface UserCardFilterProps {
  type: string;
  users: UserData[] | UserDataWFav[];
  favorites?: UserData[];
  isAdmin?: boolean;
  favs?: boolean;
}
interface UserAttrFilter {
  name: string;
  key: string;
}

export const UserCardFilter = ({
  type,
  users,
  favorites,
  isAdmin = false,
  favs = false,
}: UserCardFilterProps) => {
  const [selectedSchoolAttrFilter, setSelectedSchoolAttrFilter] = useState<
    string[]
  >([]);

  const [isShowingFavorites, setIsShowingFavorites] = useState<boolean>(false);
  const [isFilterAll, setIsFilterAll] = useState<boolean>(true);

  const toggleIsShowingFavorites = () => {
    setIsShowingFavorites(!isShowingFavorites);
    setIsFilterAll(false);
  };

  const toggleIsShowingAll = () => {
    setIsFilterAll(true);
    setIsShowingFavorites(false);
    setSelectedSchoolAttrFilter([]);
  };

  const SchoolAttrFilters: UserAttrFilter[] = [
    {
      name: "Military Affiliate",
      key: "military",
    },
    {
      name: "First Generation",
      key: "first_generation",
    },
    {
      name: "21+",
      key: "adult_learner",
    },
    {
      name: "STEM",
      key: "stem",
    },
  ];

  const [selectedClassificationFilter, setSelectedClassificationFilters] =
    useState<string[]>([]);
  // const ClassificationFilters: UserAttrFilter[] = [
  //   {
  //     name: "Freshman",
  //     key: "Freshman",
  //   },
  //   {
  //     name: "Sophomore",
  //     key: "Sophomore",
  //   },
  //   {
  //     name: "Junior",
  //     key: "Junior",
  //   },
  //   {
  //     name: "Senior",
  //     key: "Senior",
  //   },
  //   {
  //     name: "Graduate",
  //     key: "Graduate",
  //   },
  //   {
  //     name: "Alumni",
  //     key: "Alumni",
  //   },
  //   {
  //     name: "Mentor",
  //     key: "Mentor",
  //   },
  // ];

  //correct version
  const handleToggleSchoolAttrFilter = (userAttrFilter: UserAttrFilter) => {
    if (selectedSchoolAttrFilter.includes(userAttrFilter.key)) {
      const cloned = selectedSchoolAttrFilter.filter(
        (key) => key !== userAttrFilter.key
      );
      setSelectedSchoolAttrFilter(cloned);
      setIsFilterAll(false);
    } else {
      const cloned = [...selectedSchoolAttrFilter];
      cloned.push(userAttrFilter.key);
      setSelectedSchoolAttrFilter(cloned);
      setIsFilterAll(false);
    }
  };

  const handleToggleClassificationFilter = (userAttrFilter: UserAttrFilter) => {
    if (selectedClassificationFilter.includes(userAttrFilter.key)) {
      const cloned = selectedClassificationFilter.filter(
        (key) => key !== userAttrFilter.key
      );
      setSelectedClassificationFilters(cloned);
    } else {
      const cloned = [...selectedClassificationFilter];
      cloned.push(userAttrFilter.key);
      setSelectedClassificationFilters(cloned);
    }
  };

  useEffect(() => {
    if (selectedSchoolAttrFilter.length === 0 && !isShowingFavorites) {
      setIsFilterAll(true);
    } else {
      setIsFilterAll(false);
    }
  }, [selectedSchoolAttrFilter, isShowingFavorites]);

  const filteredUsers =
    selectedSchoolAttrFilter.length > 0 ||
    selectedClassificationFilter.length > 0
      ? users.filter((user) => {
          const schoolAttrFilterPassed =
            (!selectedSchoolAttrFilter.includes("first_generation") ||
              user.first_generation) &&
            (!selectedSchoolAttrFilter.includes("military") || user.military) &&
            (!selectedSchoolAttrFilter.includes("adult_learner") ||
              user.adult_learner) &&
            (!selectedSchoolAttrFilter.includes("stem") || user.stem);

          const classificationFilterPassed =
            selectedClassificationFilter.length === 0 ||
            selectedClassificationFilter.includes(user.classification);

          return schoolAttrFilterPassed && classificationFilterPassed;
        })
      : users;

  const filteredFavorites =
    favorites &&
    (selectedSchoolAttrFilter.length > 0 ||
      selectedClassificationFilter.length > 0)
      ? favorites.filter((favorite) => {
          const schoolAttrFilterPassed =
            (!selectedSchoolAttrFilter.includes("first_generation") ||
              favorite.first_generation) &&
            (!selectedSchoolAttrFilter.includes("military") ||
              favorite.military) &&
            (!selectedSchoolAttrFilter.includes("adult_learner") ||
              favorite.adult_learner);

          const classificationFilterPassed =
            selectedClassificationFilter.length === 0 ||
            selectedClassificationFilter.includes(favorite.classification);

          return schoolAttrFilterPassed && classificationFilterPassed;
        })
      : favorites
      ? favorites
      : [];

  const NO_FILTER_RESULTS_CONTENT = (
    <StyledDiv>
      <div>
        <h4>No results found</h4>
        <h6>Select different filters to update the results</h6>
      </div>
    </StyledDiv>
  );

  const FavoriteFilteredContent = () => {
    if (favorites) {
      if (favorites.length > 0) {
        return (
          <FlexWrapContainer>
            {filteredFavorites.length > 0
              ? filteredFavorites.map((user) => {
                  if (type === "MENTOR") {
                    if (favs) {
                      return (
                        <div
                          key={`mentor-${user?.id}`}
                          style={{ marginTop: 25 }}
                        >
                          <div style={{ display: "flex" }}>
                            <h4>{user.num_favorites}</h4>
                            <FontAwesomeIcon
                              fontSize={"2em"}
                              color={"#1170b8"}
                              icon={faStar}
                              style={{ marginLeft: 10 }}
                            />
                            {"'s"}
                          </div>
                          <MentorCardTwo isAdmin={isAdmin} mentorData={user} />
                        </div>
                      );
                    } else {
                      return (
                        <MentorCardTwo
                          key={`mentor-${user?.id}`}
                          isAdmin={isAdmin}
                          mentorData={user}
                        />
                      );
                    }
                  }
                  return (
                    <MenteeCard key={`mentee-${user?.id}`} mentorData={user} />
                  );
                })
              : NO_FILTER_RESULTS_CONTENT}
          </FlexWrapContainer>
        );
      } else {
        return (
          <StyledDiv>
            <div>
              <h4>No favorites found</h4>
              <h6>
                Looks like you do not have any favorite mentors. Visit a
                mentor's profile and click the{" "}
                <FontAwesomeIcon
                  fontSize={"1.2em"}
                  color={"#1170b8"}
                  icon={faStartOutline}
                />{" "}
                to set them as a favorite.
              </h6>
            </div>
          </StyledDiv>
        );
      }
    }

    return null;
  };

  // const FavoriteFilteredContent = () => {
  //   if (favorites) {
  //     if (favorites.length > 0) {
  //       return filteredFavorites.length > 0
  //         ? filteredFavorites.map((user) => {
  //             if (type === "MENTOR")
  //               return (
  //                 <MentorCardTwo
  //                   isAdmin={isAdmin}
  //                   key={`mentor-${user?.id}`}
  //                   mentorData={user}
  //                 />
  //               );
  //             return (
  //               <MenteeCard key={`mentee-${user?.id}`} mentorData={user} />
  //             );
  //           })
  //         : NO_FILTER_RESULTS_CONTENT;
  //     } else {
  //       return (
  //         <StyledDiv>
  //           <div>
  //             <h4>No favorites found</h4>
  //             <h6>
  //               Looks like you do not have any favorite mentors. Visit a mentors
  //               profile and click the{" "}
  //               <FontAwesomeIcon
  //                 fontSize={"1.2em"}
  //                 color={"#1170b8"}
  //                 icon={faStartOutline}
  //               />{" "}
  //               to set them as a favorite.
  //             </h6>
  //           </div>
  //         </StyledDiv>
  //       );
  //     }
  //   }

  //   return null;
  // };

  //correct version
  const MentorFilteredContent = () => {
    return filteredUsers.length > 0 ? (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {filteredUsers.map((user) => {
          if (type === "MENTOR") {
            if (favs) {
              return (
                <div key={`mentor-${user?.id}`} style={{ marginTop: 25 }}>
                  <div style={{ display: "flex" }}>
                    <h4>{user.num_favorites}</h4>
                    <FontAwesomeIcon
                      fontSize={"2em"}
                      color={"#1170b8"}
                      icon={faStar}
                      style={{ marginLeft: 10 }}
                    />
                    {"'s"}
                  </div>
                  <MentorCardTwo isAdmin={isAdmin} mentorData={user} />
                </div>
              );
            } else {
              return (
                <MentorCardTwo
                  key={`mentor-${user?.id}`}
                  isAdmin={isAdmin}
                  mentorData={user}
                />
              );
            }
          }
          return <MenteeCard key={`mentee-${user?.id}`} mentorData={user} />;
        })}
      </div>
    ) : (
      NO_FILTER_RESULTS_CONTENT
    );
  };

  const AllFilteredContent = () => {
    return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {users.map((user) => {
          if (type === "MENTOR") {
            if (favs) {
              return (
                <div key={`mentor-${user?.id}`} style={{ marginTop: 25 }}>
                  <div style={{ display: "flex" }}>
                    <h4>{user.num_favorites}</h4>
                    <FontAwesomeIcon
                      fontSize={"2em"}
                      color={"#1170b8"}
                      icon={faStar}
                      style={{ marginLeft: 10 }}
                    />
                    {"'s"}
                  </div>
                  <MentorCardTwo isAdmin={isAdmin} mentorData={user} />
                </div>
              );
            } else {
              return (
                <MentorCardTwo
                  key={`mentor-${user?.id}`}
                  isAdmin={isAdmin}
                  mentorData={user}
                />
              );
            }
          }
          return <MenteeCard key={`mentee-${user?.id}`} mentorData={user} />;
        })}
      </div>
    );
  };

  // const MentorFilteredContent = () => {
  //   return filteredUsers.length > 0
  //     ? filteredUsers.map((user) => {
  //         if (type === "MENTOR") {
  //           if (favs) {
  //             return (
  //               <Row style={{ marginTop: 25 }}>
  //                 <Col md={1}>
  //                   <div style={{ display: "flex" }}>
  //                     <h4>{user.num_favorites}</h4>
  //                     <FontAwesomeIcon
  //                       fontSize={"2em"}
  //                       color={"#1170b8"}
  //                       icon={faStar}
  //                       style={{ marginLeft: 10 }}
  //                     />
  //                     {"'s"}
  //                   </div>
  //                 </Col>
  //                 <Col md={12}>
  //                   <MentorCardTwo
  //                     isAdmin={isAdmin}
  //                     key={`mentor-${user?.id}`}
  //                     mentorData={user}
  //                   />
  //                 </Col>
  //               </Row>
  //             );
  //           } else {
  //             return (
  //               <MentorCardTwo
  //                 isAdmin={isAdmin}
  //                 key={`mentor-${user?.id}`}
  //                 mentorData={user}
  //               />
  //             );
  //           }
  //         }
  //         return <MenteeCard key={`mentee-${user?.id}`} mentorData={user} />;
  //       })
  //     : NO_FILTER_RESULTS_CONTENT;
  // };

  const [hoveredFilter, setHoveredFilter] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isAllHovered, setIsAllHovered] = useState(false);

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "-10px" }}>
        <div>
          <div
            style={{
              margin: "5px 20px 5px 0px",
              minWidth: 20,
              padding: 5,
              backgroundColor: isFilterAll ? "#ddd" : "",
              color: isFilterAll ? "black" : "",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #aaa",
              borderRadius: "5px",
            }}
            onClick={toggleIsShowingAll}
          >
            <h6 style={{ margin: 0, fontSize: ".9em" }}>All</h6>
          </div>
        </div>
        {favorites && (
          <div>
            <div
              style={{
                margin: "5px 20px 5px 0px",
                minWidth: 20,
                padding: 5,
                backgroundColor: isShowingFavorites ? "#ddd" : "",
                color: isShowingFavorites ? "black" : "",
                // borderColor: "rgba(55, 133, 191, 0.94)",
                // borderStyle: "solid",
                // borderRadius: 9,
                cursor: "pointer",
                // borderWidth: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #aaa",
                borderRadius: "5px",
              }}
              onClick={toggleIsShowingFavorites}
            >
              <FontAwesomeIcon
                fontSize={"1em"}
                color={"#ff7701"}
                icon={faStar}
                style={{ cursor: "pointer", margin: "0px 10px 0px 0px" }}
              />{" "}
              <h6 style={{ margin: 0, fontSize: ".9em" }}>Favorites</h6>
            </div>
          </div>
        )}
        <div style={{ display: "flex" }}>
          {SchoolAttrFilters.map((filter) => (
            <div
              style={{
                margin: "5px 20px 5px 0px",
                minWidth: 20,
                padding: 5,
                backgroundColor: selectedSchoolAttrFilter.includes(filter.key)
                  ? "#ddd"
                  : "",
                color: selectedSchoolAttrFilter.includes(filter.key)
                  ? "black"
                  : "",
                // borderColor: "rgba(55, 133, 191, 0.94)",
                // borderStyle: "solid",
                // borderRadius: 9,
                // borderWidth: 1,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #aaa",
                borderRadius: "5px",
              }}
              onClick={() => {
                handleToggleSchoolAttrFilter(filter);
              }}
            >
              <h6 style={{ margin: 0, fontSize: ".9em" }}>{filter.name}</h6>
            </div>
          ))}
        </div>

        {/* <div
          style={{
            display: "flex",
            borderLeftStyle: "solid",
            borderLeftColor: "rgba(55, 133, 191, 0.94)",
            borderWidth: 3,
            paddingLeft: 20,
          }}
         >
          {ClassificationFilters.map((filter) => (
            <div
              style={{
                margin: "5px 20px 5px 0px",
                minWidth: 80,
                padding: 5,
                backgroundColor: selectedClassificationFilter.includes(
                  filter.key
                )
                  ? "rgba(55, 133, 191, 0.94)"
                  : "#eee",
                color: selectedClassificationFilter.includes(filter.key)
                  ? "white"
                  : "rgba(55, 133, 191, 0.94)",
                borderColor: "rgba(55, 133, 191, 0.94)",
                borderStyle: "solid",
                borderRadius: 9,
                cursor: "pointer",
                borderWidth: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                handleToggleClassificationFilter(filter);
              }}
            >
              <h6 style={{ fontSize: ".9em", marginBottom: 0 }}>
                {filter.name}
              </h6>
            </div>
          ))}
        </div> */}
      </div>

      {showAll ? (
        <AllFilteredContent />
      ) : (
        <div>
          {isShowingFavorites ? (
            <FavoriteFilteredContent />
          ) : (
            <MentorFilteredContent />
          )}
        </div>
      )}
    </div>
  );
};

const StyledDiv = styled("div")`
  margin: 20px 0px;
  padding: 10px 50px;
  border-style: solid;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 92%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
  /* Increase box shadow on hover */
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;
const FlexWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
