/**
 * App Body File.
 *
 * created by Ty DeMarcus Kennedy 10/4/2022
 */
import React, { useState } from "react";
import { Col, Form, Container, Row, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "./logo.png";
import axios from "axios";

export default function Support() {
  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !description) return;

    const formData = {
      type: role,
      name,
      email,
      description,
    };

    setFormDisabled(true);

    console.log("Form Data:", formData);

    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        "https://mentor-portal-backend.azurewebsites.net/api/message/help",
        formData,
        {
          headers: headers,
        }
      );

      console.log("Full Response:", response);

      if (response.status === 200) {
        if (response.data !== "") {
          console.log("Request Successful:", response.data);
        } else {
          console.log("Request Successful but Response Data is Empty.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setFormDisabled(false);
      setDone(true);
    }
  };

  return (
    <div style={{ overflowX: "hidden", marginBottom: 150 }}>
      <Container fluid>
        <Row>
          <Col md={3}></Col>
          <Col style={{ marginTop: 150 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginTop: 10 }}>Support </h3>
              <img
                style={{ marginTop: 10 }}
                src={logo}
                width={"25%"}
                height={"25%"}
              />
            </div>
            <h5 style={{ marginTop: 50, marginBottom: 50 }}>
              We are committed to providing support and answering any questions
              you may have. If you need assistance or encounter any technical
              issues, please fill out the help form.
            </h5>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row>
          <Col md={4} />
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row className='mb-3' style={{ textAlign: "center" }}>
                <Col>
                  <Form.Check
                    type='radio'
                    id='menteeRadio'
                    label='Mentee'
                    name='role'
                    value='mentee'
                    checked={role === "mentee"}
                    onChange={handleRoleChange}
                    className='radio-bold'
                  />
                </Col>
                <Col>
                  <Form.Check
                    type='radio'
                    id='mentorRadio'
                    label='Mentor'
                    name='role'
                    value='mentor'
                    checked={role === "mentor"}
                    onChange={handleRoleChange}
                    className='radio-bold'
                  />
                </Col>
              </Row>
              <Form.Group className='mb-3' controlId='helpFormName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={formDisabled}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='helpFormEmail'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={formDisabled}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='helpFormDescription'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={5}
                  placeholder='Description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={formDisabled}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='helpFormDescription'>
                {error && (
                  <p style={{ color: "red" }}>
                    an error has ocurred. please try again
                  </p>
                )}
                {done && (
                  <p style={{ color: "green" }}>
                    Success. We shall reach out soon.
                  </p>
                )}
              </Form.Group>
              <Button
                type='submit'
                style={{
                  width: "100%",
                  marginTop: "10px",
                  backgroundColor: "#dd6905",
                  height: "3.5em",
                  border: "none",
                }}
                disabled={formDisabled}
              >
                {formDisabled ? "Sending..." : "Submit"}
              </Button>
            </Form>
          </Col>
          <Col md={4} />
        </Row>
      </Container>
    </div>
  );
}
