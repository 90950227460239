import React from "react";
import Button from "react-bootstrap/esm/Button";
import { StateButtonProps } from "./interfaces";
import { Link, useNavigate } from "react-router-dom";
import { deleteCookie } from "../../utils/cookies";

export default function StateButton(p: StateButtonProps) {
  const navigation = useNavigate();
  const handleCancel = () => {
    const choice = confirm("Are you sure you want to cancel verification?");
    if (choice) {
      deleteCookie("mentor-token");
      navigation("/");
    }
  };

  return (
    <Button
      style={{
        width: p.width,
        height: p.height,
        backgroundColor: p.backgroundColor,
        color: p.color,
      }}
      onClick={handleCancel}
    >
      {p.title}
    </Button>
  );
}
