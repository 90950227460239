/**
 * Connections Requests Table.
 *
 * created by Ty D'Angelo 10/19/23
 */

import { Col, Container, Row, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import DeleteConnectionButton from "../ui/button/DeleteConnectionButton";
import ConnectionStatusButton from "../ui/button/ConnectionStatusButton";

enum UserType {
  MENTOR = "MENTOR",
  MENTEE = "MENTEE",
}

interface ConnectionRequestTableProps {
  userId?: number;
  userType?: UserType;
  isAdmin?: boolean;
}

interface Connection {
  id: string;
  mentor_id: string;
  mentee_id: string;
  created_date: string;
  last_edited_date: string;
  status: string;
  mentee_first_name: string;
  mentee_last_name: string;
  mentee_email: string;
  mentee_profile_image: string;
  mentor_first_name: string;
  mentor_last_name: string;
  mentor_email: string;
  mentor_profile_image: string;
}

export default function ConnectionList(p: ConnectionRequestTableProps) {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchConnectionsForUser = async (id: number | undefined) => {
    setIsLoading(true);
    try {
      const apiURL = p.isAdmin
        ? `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest`
        : `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/user/${id}`;
      const { data: connectionData, headers } = await axios(apiURL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      setConnections(connectionData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };
  const reFetchConnections = (connectionId: string) => {
    const updatedConnections = connections.filter(
      (connection) => connection.id !== connectionId
    );
    setConnections(updatedConnections);
  };

  const updateConnection = (id: string, status: string) => {
    const updatedConnections = connections.map((connection) => {
      if (connection.id === id) {
        connection.status = status;
      }
      return connection;
    });

    setConnections(updatedConnections);
  };

  useEffect(() => {
    if ((!isLoading && p.userId) || (!isLoading && p.isAdmin))
      handleFetchConnectionsForUser(p.userId);
  }, [p.userId]);

  const acceptedConnections = connections.filter(
    (connection) => connection?.status === "ACCEPTED"
  );
  const requestedConnections = connections.filter(
    (connection) => connection?.status === "REQUESTED"
  );
  const deniedConnections = connections.filter(
    (connection) => connection?.status === "DENIED"
  );

  const [value, setValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event) => {
    setValue(event.target.value);

    if (event.target.value === "Date") {
      const sortedData = [...connections].sort((a, b) => {
        return sortAscending
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      });
      setConnections(sortedData);
    }
  };

  const displayName = (connection: Connection) => {
    if (p.isAdmin) {
      return (
        <>
          <div style={{ margin: 5 }}>
            <span
              style={{
                backgroundColor: "#eee",
                padding: "3px 5px",
                marginRight: 10,
                fontWeight: 900,
                fontSize: ".8rem",
                cursor: "pointer",
              }}
            >
              <a
                target='_blank'
                style={{ textDecoration: "none", color: "red" }}
                href={`/profile/${connection.mentor_id}`}
              >
                MENTOR
              </a>
            </span>
            {connection?.mentor_first_name + " " + connection?.mentor_last_name}
          </div>
          <div style={{ margin: 5 }}>
            <span
              style={{
                backgroundColor: "#eee",
                padding: "3px 5px",
                marginRight: 10,
                fontWeight: 900,
                fontSize: ".8rem",
                cursor: "pointer",
              }}
            >
              <a
                target='_blank'
                style={{ textDecoration: "none", color: "green" }}
                href={`/profile/${connection.mentee_id}`}
              >
                MENTEE
              </a>
            </span>
            {connection?.mentee_first_name + " " + connection?.mentee_last_name}
          </div>
        </>
      );
    }
    if (p.userType === UserType.MENTOR) {
      return connection?.mentee_first_name + " " + connection?.mentee_last_name;
    } else {
      return connection?.mentor_first_name + " " + connection?.mentor_last_name;
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Container fluid>
        <div>
          <div style={{ marginBottom: 20 }}>
            <h3>Totals</h3>
            <h6>
              The current amount of accepted, denied and pending connection
              requests
            </h6>
          </div>
          <div>
            <h5>{acceptedConnections.length} Accepted</h5>
          </div>
          <div>
            <h5>{requestedConnections.length} Pending Requests</h5>
          </div>
          <div>
            <h5>{deniedConnections.length} Pending Connections</h5>
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <div style={{ marginBottom: 20 }}>
            <h3>Connection List Data</h3>
            <h6>Data records of all the connections within the system</h6>
          </div>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label='Accepted' />
            <Tab label='Requested' />
            <Tab label='Denied' />
          </Tabs>
          {selectedTab === 0 && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    {p.isAdmin ? (
                      <TableCell style={nameColumnStyle}>
                        Mentor / Mentee
                      </TableCell>
                    ) : (
                      <TableCell style={nameColumnStyle}>
                        {p.userType === UserType.MENTOR ? "Mentee" : "Mentor"}
                      </TableCell>
                    )}
                    <TableCell style={dateColumnStyle}>Date</TableCell>
                    <TableCell style={emailColumnStyle}>Email</TableCell>
                    <TableCell style={sortColumnTitleStyle}>
                      <span>Sort: </span>
                      <select
                        style={selectStyle}
                        value={value}
                        onChange={handleDropdownChange}
                      >
                        <option value=''></option>
                        <option value='Date'>Date</option>
                      </select>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {acceptedConnections.map((connection, index) => (
                    <TableRow key={index}>
                      <TableCell style={nameColumnStyle}>
                        {displayName(connection)}
                      </TableCell>
                      <TableCell style={dateColumnStyle}>
                        {new Date(
                          connection?.last_edited_date
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>
                          {connection?.mentor_email}
                          <br />
                          {connection?.mentee_email}
                        </div>
                      </TableCell>
                      <TableCell style={buttonColumnStyle}>
                        <DeleteConnectionButton
                          removeConnection={reFetchConnections}
                          connectionId={connection?.id}
                        />
                      </TableCell>
                      <TableCell style={editColumnStyle}></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          {selectedTab === 1 && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    {p.isAdmin ? (
                      <TableCell style={nameColumnStyle}>
                        Mentor / Mentee
                      </TableCell>
                    ) : (
                      <TableCell style={nameColumnStyle}>
                        {p.userType === UserType.MENTOR ? "Mentee" : "Mentor"}
                      </TableCell>
                    )}
                    <TableCell style={dateColumnStyle}>Date</TableCell>
                    <TableCell style={emailColumnStyle}>Email</TableCell>
                    <TableCell style={columnStyle}></TableCell>
                    <TableCell style={sortColumnTitleStyle}>
                      <span>Sort: </span>
                      <select
                        style={selectStyle}
                        value={value}
                        onChange={handleDropdownChange}
                      >
                        <option value='none'></option>
                        <option value='Date'>Date</option>
                      </select>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestedConnections.map((connection, index) => (
                    <TableRow key={index}>
                      <TableCell style={nameColumnStyle}>
                        {displayName(connection)}
                      </TableCell>
                      <TableCell style={dateColumnStyle}>
                        {new Date(
                          connection?.created_date
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>
                          {connection?.mentor_email}
                          <br />
                          {connection?.mentee_email}
                        </div>
                      </TableCell>
                      <TableCell style={buttonColumnStyle}>
                        {p.userType === UserType.MENTOR ? (
                          <ConnectionStatusButton
                            connectionId={connection?.id}
                            updateConnection={updateConnection}
                            title={"Approve"}
                            status={"ACCEPTED"}
                          />
                        ) : (
                          <DeleteConnectionButton
                            connectionId={connection?.id}
                            removeConnection={reFetchConnections}
                          />
                        )}
                      </TableCell>
                      <TableCell style={editColumnStyle}>
                        {p.userType === UserType.MENTOR && (
                          <ConnectionStatusButton
                            updateConnection={updateConnection}
                            connectionId={connection?.id}
                            title={"Deny"}
                            status={"DENIED"}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          {selectedTab === 2 && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    {p.isAdmin ? (
                      <TableCell style={nameColumnStyle}>
                        Mentor / Mentee
                      </TableCell>
                    ) : (
                      <TableCell style={nameColumnStyle}>
                        {p.userType === UserType.MENTOR ? "Mentee" : "Mentor"}
                      </TableCell>
                    )}
                    <TableCell style={dateColumnStyle}>Date</TableCell>
                    <TableCell style={emailColumnStyle}>Email</TableCell>
                    <TableCell style={sortColumnTitleStyle}>
                      <span>Sort: </span>
                      <select
                        style={selectStyle}
                        value={value}
                        onChange={handleDropdownChange}
                      >
                        <option value=''></option>
                        <option value='Date'>Date</option>
                      </select>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deniedConnections.map((connection, index) => (
                    <TableRow key={index}>
                      <TableCell style={nameColumnStyle}>
                        {displayName(connection)}
                      </TableCell>
                      <TableCell style={dateColumnStyle}>
                        {new Date(
                          connection?.last_edited_date
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>
                          {connection?.mentor_email}
                          <br />
                          {connection?.mentee_email}
                        </div>
                      </TableCell>
                      <TableCell style={buttonColumnStyle}>
                        {p.userType === UserType.MENTOR && (
                          <DeleteConnectionButton
                            connectionId={connection?.id}
                            removeConnection={reFetchConnections}
                          />
                        )}
                      </TableCell>
                      <TableCell style={editColumnStyle}></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

// Styled Components
const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  color: "grey",
  paddingRight: "50px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const dateColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const emailColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const buttonColumnStyle = {
  paddingRight: "90px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const editColumnStyle = {
  paddingLeft: "75px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  paddingLeft: "60px",
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};
