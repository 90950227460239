import Cookies from "universal-cookie";
const cookies = new Cookies();

export const getCookie = async (key) => {
  return await cookies.get(key);
};

export const setCookie = async (key, value, expire: null | Date = null) => {
  const current = new Date();
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);
  await cookies.set(key, value, {
    path: "/",
    expires: expire ? expire : nextYear,
  });
};

export const deleteCookie = async (key) => {
  await cookies.remove(key, { path: "/" });
};
