import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import { Container, Row } from "react-bootstrap";
import logo from "../navbar/logo.png";

export function NavBar() {
  const location = useLocation();
  return (
    <StyledLink href="/">
      <img src={logo} width="280" height="250" alt="frontend-app" />
    </StyledLink>
  );
}

// Styled Components
const NavbarDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const NavLink = styled(Link)`
  color: #f4f4f4;
  font-weight: 900;
  text-decoration: none;
  padding: 0 5px;
`;

const StyledNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  // width: 100%;
  background-color: red;
`;

const StyledDashboardNavbar = styled(Navbar)`
  overflow: hidden;
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #eee;
  height: 80px;
`;

const StyledLink = styled.a`
  padding: 0.1875em;
  position: absolute;
  left: 150px;
  top: 10px;
  cursor: pointer;
  z-index: 1;

  /* Conditional styling for mobile devices */
  @media (max-width: 768px) {
    left: 50px; /* Adjust the left position for mobile devices */
    /* Additional mobile-specific styles can be added here */
  }
`;
