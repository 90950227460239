import { useEffect, useState } from "react";
import { AvailabilityGroup, AvailabilityTimeBlock } from "../pages/UserProfile";
import { Form, Button, Spinner } from "react-bootstrap";
import React from "react";
import { Availability } from "./interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

// Helper function to convert time to minutes
function convertTimeToMinutes(time) {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
}

// Helper function to convert minutes to time
function convertMinutesToTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(remainingMinutes).padStart(
    2,
    "0"
  )}`;
}

const splitAvailability = (
  availObj,
  increment
): {
  start_time: string;
  end_time: string;
}[] => {
  const result: AvailabilityTimeBlock[] = [];

  // Validate input
  if (!availObj || typeof availObj !== "object" || isNaN(increment)) {
    console.error("Invalid input");
    return result;
  }

  // Extract values from the availability object
  const { start_time, end_time, enabled, day } = availObj;

  // Validate time format
  const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  if (!timeFormatRegex.test(start_time) || !timeFormatRegex.test(end_time)) {
    console.error("Invalid time format");
    return result;
  }

  // Validate day
  if (isNaN(day) || day < 0 || day > 6) {
    console.error("Invalid day");
    return result;
  }

  // Convert start_time and end_time to minutes
  const startTimeInMinutes = convertTimeToMinutes(start_time);
  const endTimeInMinutes = convertTimeToMinutes(end_time);

  // Calculate time increment in minutes
  const incrementInMinutes = increment;

  // Generate availability objects for each increment
  let currentTimeInMinutes = startTimeInMinutes;
  while (currentTimeInMinutes < endTimeInMinutes) {
    const currentEndTimeInMinutes = currentTimeInMinutes + incrementInMinutes;

    // Add the availability object to the result array
    result.push({
      start_time: convertMinutesToTime(currentTimeInMinutes),
      end_time: convertMinutesToTime(currentEndTimeInMinutes),
    });

    // Move to the next increment
    currentTimeInMinutes = currentEndTimeInMinutes;
  }

  return result;
};

const VIRTUAL_OPTIONS = [
  {
    name: "Microsoft Teams",
    value: "teams",
  },
];

const IN_PERSON_OPTIONS = [
  {
    name: "Starbucks",
    value: "starbucks",
  },
  {
    name: "C Store",
    value: "c_store",
  },
  {
    name: "Library Student Center",
    value: "lib_center",
  },
  {
    name: "Campus Dining Hall",
    value: "campus_dining",
  },
];

export const VIRTUAL_OPTION_VALUES = ["zoom", "teams", "webex"];

export const getMeetingTypeDisplayName = (value: string) =>
  VIRTUAL_OPTIONS.find((opt) => opt.value === value)?.name;

export interface AppointmentRequest {
  mentor_id?: string;
  mentee_id?: string;
  date: string;
  start_time?: string;
  end_time?: string;
  time_zone?: string;
  meeting_type?: string;
  meeting_link?: string;
  phone_number?: string;
  meeting_details?: string;
  meeting_location?: string;
}

export const AppointmentForm = ({
  availability,
  date,
  handleSubmit,
  isSubmitting,
  isRequestSent,
}: {
  availability: Availability;
  date: Date;
  handleSubmit: (any) => void;
  isSubmitting: boolean;
  isRequestSent: boolean;
}) => {
  const [meetingType, setMeetingType] = useState("");
  const [meetingLink, setMeetingLink] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [meetingDetails, setMeetingDetails] = useState("");
  const [meetingLocation, setMeetingLocation] = useState("");

  const [appointmentAvailability, setAppointmentAvailability] = useState<
    AvailabilityGroup[]
  >([]);

  const doBlockSubmit = () => {
    if (!meetingDetails) return true;
    if (VIRTUAL_OPTION_VALUES.includes(meetingType)) {
      if (meetingLink) return false;
    }

    if (meetingType === "phone") {
      if (phoneNumber) return false;
    }

    if (meetingType === "inPerson") {
      if (meetingLocation) return false;
    }

    return true;
  };

  const doSubmitRequest = () => {
    handleSubmit({
      meeting_type: meetingType,
      meeting_link: meetingLink,
      phone_number: phoneNumber,
      meeting_details: meetingDetails,
      meeting_location: meetingLocation,
      date: date,
      time_zone: getTimezoneAbbreviation(),
      start_time: availability.start_time,
      end_time: availability.end_time,
    });
  };

  return (
    <Form>
      <div className='mt-4'>
        {isRequestSent ? (
          <div
            className='mt-4'
            style={{
              height: "50px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h5 className='mr-4'>Request Submitted</h5>
              <FontAwesomeIcon
                style={{ fontSize: 30, color: "green" }}
                icon={faCircleCheck}
              />
            </div>
            <div className='text-center'>
              <p>An email has been sent for confirmation.</p>
            </div>
          </div>
        ) : isSubmitting ? (
          <div
            className='mt-4'
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50px",
            }}
          >
            <h5 className='mr-4'>Submitting request</h5>
            <Spinner />
          </div>
        ) : (
          <div>
            <h6>Complete the form below to submit a request</h6>
            <p>
              Note that the user you are requesting an appointment with must
              approve and accept the terms of the requested appointment.
            </p>
          </div>
        )}
        <Form.Group controlId='meetingType' style={{ margin: "20px 10px" }}>
          <Form.Label>Meeting Type</Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => setMeetingType(e.target.value)}
            disabled={isRequestSent || isSubmitting}
          >
            <option value=''>Select Meeting Type</option>
            {VIRTUAL_OPTIONS.map((opt) => (
              <option value={opt.value}>{opt.name}</option>
            ))}
            <option value='phone'>Phone</option>
            <option value='inPerson'>In Person</option>
          </Form.Control>
        </Form.Group>
        {VIRTUAL_OPTION_VALUES.includes(meetingType) && (
          <Form.Group controlId='meetingLink' style={{ margin: "20px 10px" }}>
            <Form.Label>
              {getMeetingTypeDisplayName(meetingType)} Link
            </Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='Meeting Link'
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              disabled={isRequestSent || isSubmitting}
            />
          </Form.Group>
        )}
        {meetingType === "phone" && (
          <Form.Group controlId='phoneNumber' style={{ margin: "20px 10px" }}>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type='tel'
              placeholder=''
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={isRequestSent || isSubmitting}
            />
          </Form.Group>
        )}
        {meetingType === "inPerson" && (
          <Form.Group
            controlId='meetingDetails'
            style={{ margin: "20px 10px" }}
          >
            <Form.Label>Meeting Location</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setMeetingLocation(e.target.value)}
              disabled={isRequestSent || isSubmitting}
            >
              <option value=''>Select Meeting Location</option>
              {IN_PERSON_OPTIONS.map((opt) => (
                <option value={opt.value}>{opt.name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        <Form.Group controlId='meetingDetails' style={{ margin: "20px 10px" }}>
          <Form.Label>Meeting Details</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            disabled={isRequestSent || isSubmitting}
            placeholder='Enter Meeting Details'
            value={meetingDetails}
            onChange={(e) => setMeetingDetails(e.target.value)}
          />
        </Form.Group>

        <Form.Group className='text-right'>
          <Button
            variant='primary'
            disabled={doBlockSubmit() || isRequestSent}
            onClick={doSubmitRequest}
          >
            Request a Session
          </Button>
        </Form.Group>
      </div>
    </Form>
  );
};
