/**
 * Sign Up and Verified Users Metrics.
 *
 * created by Ty D'Angelo DeMarcus Kennedy 10/4/2022
 */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  BarChart,
  Bar,
  AreaChart,
  Area,
  Text,
} from "recharts";
import { NavBarAlt } from "../ui/navbar/NavbarAlt";
import { NavbarAnalytics } from "../ui/navbar/NavbarAnalytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faFolderClosed,
  faFolderPlus,
  faGlobe,
  faHandshake,
  faUserAlt,
  faUserAstronaut,
  faUserCheck,
  faUserCog,
  faUserGroup,
  faUserSecret,
  faUsers,
  faUsersBetweenLines,
  faUsersRays,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import {
  faConnectdevelop,
  faInternetExplorer,
} from "@fortawesome/free-brands-svg-icons";
import { Session } from "../ui/table/SessionRequestContainer";
import ConnectionsByStateMap from "../ui/map/ConnectionsByStateMap";

interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  bio: null | string;
  created_date: string;
  last_edited_date: string;
  profile_image: string;
  type: null | string;
  password: string;
  middle_initial: string;
  state: null | string;
  zip_code: null | string;
  major: string;
  classification: string;
  gender: string;
  phone_number: string;
  first_generation: null | boolean;
  adult_learner: null | boolean;
  military: null | boolean;
  date_of_birth: null | string;
  signed_up: boolean;
  student_id: string;
  race: string;
  account_verified: boolean;
  date_of_sign_up: null | string;
  date_of_verified: null | string;
  alumni: boolean;
  country: null | string;
  approved: null | boolean;
}

interface Connection {
  id: string;
  mentor_id: string;
  mentee_id: string;
  created_date: string;
  last_edited_date: string;
  status: string;
}

interface CompletedAppointment {
  id: string;
  mentee_id: string;
  mentor_id: string;
  status: string;
  mentor_first_name: string;
  mentor_last_name: string;
}

interface SignupVerifiedMetric {
  allUserData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  signedUpData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  signedUpMentorData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  signedUpMenteeData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentor_first_gen: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentor_military: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentor_stem: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_stem: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentor_adult_learner: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentor_alumni: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_first_gen: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_military: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_adult_learner: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_freshmen: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_sophomore: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_junior: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_senior: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedData_mentee_graduate: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  mentorData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  menteeData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedMentorData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  verifiedMenteeData: {
    success: boolean;
    data: [
      {
        count: string;
      }
    ];
  };
  signedUpMonthly: {
    [key: number]: User[];
  };
  verifiedMonthly: {
    [key: number]: User[];
  };
  mentorsSignedUpMonthly: {
    [key: number]: User[];
  };
  menteesSignedUpMonthly: {
    [key: number]: User[];
  };
  mentorsVerifiedMonthly: {
    [key: number]: User[];
  };
  menteesVerifiedMonthly: {
    [key: number]: User[];
  };
}

export interface ConnectionMetric {
  approvedMonthly: {
    [key: number]: Connection[];
  };
  deniedMonthly: {
    [key: number]: Connection[];
  };
}

interface SessionMetric {
  completedSessionsMonthly: {
    [key: number]: Session[];
  };
  allCompletedSessions: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsFreshmen: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsSophomore: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsJunior: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsSenior: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsGraduate: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsAlumni: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsMilitary: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsAdultLearner: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsStem: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
  allCompletedSessionsFirstGen: {
    success: boolean;
    data: [
      {
        count: number;
      }
    ];
  };
}

function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default function Analytics() {
  const [isLoading, setIsLoading] = useState(false);
  const [signUpVerifiedMetrics, setSignUpVerifiedMetrics] = useState<
    SignupVerifiedMetric | undefined
  >(undefined);
  const [connectionMetrics, setConnectionMetrics] = useState<
    ConnectionMetric | undefined
  >(undefined);
  const [sessionMetrics, setSessionMetrics] = useState<
    SessionMetric | undefined
  >(undefined);
  const [completedAppointments, setCompletedAppointments] = useState<
    CompletedAppointment[] | undefined
  >(undefined);

  const handleFetchSignupVerifiedMetrics = async () => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/api/metrics/signedup-verified",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);

      setSignUpVerifiedMetrics(data);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleFetchConnectionMetrics = async () => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/api/metrics/connections-approved-denied",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);

      setConnectionMetrics(data);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleFetchSessionMetrics = async () => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/api/metrics/sessions",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);

      setIsLoading(false);

      setSessionMetrics(data);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  const handleFetchCompletedAppointmentMetrics = async () => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/api/appointment/completed",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Completed Appointments " + JSON.stringify(data, null, 2));
      setIsLoading(false);
      setCompletedAppointments(data);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!isLoading) handleFetchSignupVerifiedMetrics();
    handleFetchConnectionMetrics();
    handleFetchSessionMetrics();
    handleFetchCompletedAppointmentMetrics();
  }, []);

  const pieChartData = [
    {
      name: "Students",
      value: parseInt(
        signUpVerifiedMetrics?.signedUpMenteeData?.data[0].count ?? "0",
        10
      ),
    },
    {
      name: "Mentors",
      value: parseInt(
        signUpVerifiedMetrics?.signedUpMentorData?.data[0].count ?? "0",
        10
      ),
    },
    {
      name: "All Users",
      value: parseInt(
        signUpVerifiedMetrics?.allUserData.data[0].count ?? "0",
        10
      ),
    },
  ];

  const pieChartData2 = [
    {
      name: "All Users",
      value: parseInt(
        signUpVerifiedMetrics?.allUserData.data[0].count ?? "0",
        10
      ),
    },
    {
      name: "Mentors",
      value: parseInt(
        signUpVerifiedMetrics?.verifiedMentorData?.data[0].count ?? "0",
        10
      ),
    },
    {
      name: "Students",
      value: parseInt(
        signUpVerifiedMetrics?.verifiedMenteeData?.data[0].count ?? "0",
        10
      ),
    },
  ];

  const pieChartData3 = [
    {
      name: "All Users",
      value: parseInt(
        signUpVerifiedMetrics?.allUserData?.data[0].count ?? "0",
        10
      ),
    },
    {
      name: "Mentors",
      value: parseInt(
        signUpVerifiedMetrics?.mentorData?.data[0].count ?? "0",
        10
      ),
    },
    {
      name: "Mentees",
      value: parseInt(
        signUpVerifiedMetrics?.menteeData?.data[0].count ?? "0",
        10
      ),
    },
  ];

  const lineChartSignUpData = [
    // {
    //   name: "Feb",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[2]?.length ?? 0,
    // },
    // {
    //   name: "Apr 2024",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[3]?.length ?? 0,
    // },
    {
      name: "May 2024",
      value: signUpVerifiedMetrics?.signedUpMonthly?.[4]?.length ?? 0,
    },
    {
      name: "Jun 2024",
      value: signUpVerifiedMetrics?.signedUpMonthly?.[5]?.length ?? 0,
    },
    {
      name: "Jul 2024",
      value: signUpVerifiedMetrics?.signedUpMonthly?.[6]?.length ?? 0,
    },
    {
      name: "Aug 2024",
      value: signUpVerifiedMetrics?.signedUpMonthly?.[7]?.length ?? 0,
    },
    // {
    //   name: "Sep 2023",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[8]?.length ?? 0,
    // },
    // {
    //   name: "Oct 2023",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[9]?.length ?? 0,
    // },
    // {
    //   name: "Nov 2023",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[10]?.length ?? 0,
    // },
    // {
    //   name: "Dec 2023",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[11]?.length ?? 0,
    // },
    // {
    //   name: "Jan 2024",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb 2024",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   value: signUpVerifiedMetrics?.signedUpMonthly?.[2]?.length ?? 0,
    // },
  ];

  const lineChartVerifiedData1 = [
    // {
    //   name: "Jan",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[2]?.length ?? 0,
    // },
    // {
    //   name: "Apr 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[3]?.length ?? 0,
    // },
    {
      name: "May 2024",
      value: signUpVerifiedMetrics?.verifiedMonthly?.[4]?.length ?? 0,
    },
    {
      name: "Jun 2024",
      value: signUpVerifiedMetrics?.verifiedMonthly?.[5]?.length ?? 0,
    },
    {
      name: "Jul 2024",
      value: signUpVerifiedMetrics?.verifiedMonthly?.[6]?.length ?? 0,
    },
    {
      name: "Aug 2024",
      value: signUpVerifiedMetrics?.mentorsVerifiedMonthly?.[7]?.length ?? 0,
    },
    // {
    //   name: "Sep 2023",
    //   value: signUpVerifiedMetrics?.mentorsVerifiedMonthly?.[8]?.length ?? 0,
    // },
    // {
    //   name: "Oct 2023",
    //   value: signUpVerifiedMetrics?.mentorsVerifiedMonthly?.[9]?.length ?? 0,
    // },
    // {
    //   name: "Nov 2023",
    //   value: signUpVerifiedMetrics?.mentorsVerifiedMonthly?.[10]?.length ?? 0,
    // },
    // {
    //   name: "Dec '23",
    //   value: signUpVerifiedMetrics?.mentorsVerifiedMonthly?.[11]?.length ?? 0,
    // },
    // {
    //   name: "Jan 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[2]?.length ?? 0,
    // },
  ];
  const lineChartVerifiedData2 = [
    // {
    //   name: "Jan",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[2]?.length ?? 0,
    // },
    // {
    //   name: "Apr 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[3]?.length ?? 0,
    // },
    {
      name: "May 2024",
      value: signUpVerifiedMetrics?.verifiedMonthly?.[4]?.length ?? 0,
    },
    {
      name: "Jun 2024",
      value: signUpVerifiedMetrics?.verifiedMonthly?.[5]?.length ?? 0,
    },
    {
      name: "Jul 2024",
      value: signUpVerifiedMetrics?.verifiedMonthly?.[6]?.length ?? 0,
    },
    {
      name: "Aug 2024",
      value: signUpVerifiedMetrics?.menteesVerifiedMonthly?.[7]?.length ?? 0,
    },
    // {
    //   name: "Sep 2023",
    //   value: signUpVerifiedMetrics?.menteesVerifiedMonthly?.[8]?.length ?? 0,
    // },
    // {
    //   name: "Oct 2023",
    //   value: signUpVerifiedMetrics?.menteesVerifiedMonthly?.[9]?.length ?? 0,
    // },
    // {
    //   name: "Nov 2023",
    //   value: signUpVerifiedMetrics?.menteesVerifiedMonthly?.[10]?.length ?? 0,
    // },
    // {
    //   name: "Dec 2023",
    //   value: signUpVerifiedMetrics?.menteesVerifiedMonthly?.[11]?.length ?? 0,
    // },
    // {
    //   name: "Jan 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   value: signUpVerifiedMetrics?.verifiedMonthly?.[2]?.length ?? 0,
    // },
  ];

  const barChartConnectionData = [
    // {
    //   name: "Feb '14",
    //   approved: connectionMetrics?.approvedMonthly?.[1]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   approved: connectionMetrics?.approvedMonthly?.[2]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[2]?.length ?? 0,
    // },
    // {
    //   name: "Apr 2024",
    //   approved: connectionMetrics?.approvedMonthly?.[3]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[3]?.length ?? 0,
    // },
    {
      name: "May 2024",
      approved: connectionMetrics?.approvedMonthly?.[4]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[4]?.length ?? 0,
    },
    {
      name: "Jun 2024",
      approved: connectionMetrics?.approvedMonthly?.[5]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[5]?.length ?? 0,
    },
    {
      name: "Jul 2024",
      approved: connectionMetrics?.approvedMonthly?.[6]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[6]?.length ?? 0,
    },
    {
      name: "Aug 2024",
      approved: connectionMetrics?.approvedMonthly?.[7]?.length ?? 0,
      denied: connectionMetrics?.deniedMonthly?.[7]?.length ?? 0,
    },
    // {
    //   name: "Sep 2023",
    //   approved: connectionMetrics?.approvedMonthly?.[8]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[8]?.length ?? 0,
    // },
    // {
    //   name: "Oct 2023",
    //   approved: connectionMetrics?.approvedMonthly?.[9]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[9]?.length ?? 0,
    // },
    // {
    //   name: "Nov 2023",
    //   approved: connectionMetrics?.approvedMonthly?.[10]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[10]?.length ?? 0,
    // },
    // {
    //   name: "Dec 2023",
    //   approved: connectionMetrics?.approvedMonthly?.[11]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[11]?.length ?? 0,
    // },
    // {
    //   name: "Jan 2024",
    //   approved: connectionMetrics?.approvedMonthly?.[0]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb 2024",
    //   approved: connectionMetrics?.approvedMonthly?.[1]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   approved: connectionMetrics?.approvedMonthly?.[2]?.length ?? 0,
    //   denied: connectionMetrics?.deniedMonthly?.[2]?.length ?? 0,
    // },
  ];

  const data = [];
  const data2 = [];
  const data3 = [
    // {
    //   name: "Jan",
    //   total: sessionMetrics?.completedSessionsMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb",
    //   total: sessionMetrics?.completedSessionsMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   total: sessionMetrics?.completedSessionsMonthly?.[2]?.length ?? 0,
    // },
    // {
    //   name: "Apr 2024",
    //   total: sessionMetrics?.completedSessionsMonthly?.[3]?.length ?? 0,
    // },
    {
      name: "May 2024",
      total: sessionMetrics?.completedSessionsMonthly?.[4]?.length ?? 0,
    },
    {
      name: "Jun 2024",
      total: sessionMetrics?.completedSessionsMonthly?.[5]?.length ?? 0,
    },
    {
      name: "Jul 2024",
      total: sessionMetrics?.completedSessionsMonthly?.[6]?.length ?? 0,
    },
    {
      name: "Aug 2024",
      total: sessionMetrics?.completedSessionsMonthly?.[7]?.length ?? 0,
    },

    // {
    //   name: "Sep 2023",
    //   total: sessionMetrics?.completedSessionsMonthly?.[8]?.length ?? 0,
    // },
    // {
    //   name: "Oct 2023",
    //   total: sessionMetrics?.completedSessionsMonthly?.[9]?.length ?? 0,
    // },
    // {
    //   name: "Nov 2023",
    //   total: sessionMetrics?.completedSessionsMonthly?.[10]?.length ?? 0,
    // },
    // {
    //   name: "Dec 2023",
    //   total: sessionMetrics?.completedSessionsMonthly?.[11]?.length ?? 0,
    // },
    // {
    //   name: "Jan 2024",
    //   total: sessionMetrics?.completedSessionsMonthly?.[0]?.length ?? 0,
    // },
    // {
    //   name: "Feb 2024",
    //   total: sessionMetrics?.completedSessionsMonthly?.[1]?.length ?? 0,
    // },
    // {
    //   name: "Mar 2024",
    //   total: sessionMetrics?.completedSessionsMonthly?.[2]?.length ?? 0,
    // },
  ];

  const rand = 300;
  for (let i = 0; i < 7; i++) {
    let d = {
      year: 2023 + i,
      value: Math.random() * (rand + 50) + 100,
    };

    data.push(d);
  }
  for (let i = 0; i < 7; i++) {
    let d = {
      year: 2023 + i,
      value: Math.random() * (rand + 50) + 100,
    };

    data2.push(d);
  }
  const getConnectionMetricsCount = (type: string) => {
    if (type === "approved" && connectionMetrics?.approvedMonthly) {
      const keys = Object.keys(connectionMetrics?.approvedMonthly);
      const count = keys.reduce((acc, key) => {
        return acc + connectionMetrics?.approvedMonthly[key].length;
      }, 0);

      return count;
    } else if (connectionMetrics?.deniedMonthly) {
      const keys = Object.keys(connectionMetrics?.deniedMonthly);
      const count = keys.reduce((acc, key) => {
        return acc + connectionMetrics?.deniedMonthly[key].length;
      }, 0);

      return count;
    }
    return "---";
  };

  const COLORS = ["#ff7701", "#1170b8", "#a3e4ff"];

  // In Kind Hours
  const mentorData = completedAppointments?.reduce((acc, curr) => {
    const mentorName = `${curr.mentor_first_name} ${curr.mentor_last_name}`;
    if (!acc[curr.mentor_id]) {
      acc[curr.mentor_id] = { name: mentorName, inKindDollars: 0 };
    }
    acc[curr.mentor_id].inKindDollars += 25; // makes sure in hours x $25
    return acc;
  }, {});

  const completedAppointmentChartData = Object.values(mentorData ?? {});

  return (
    <>
      <NavbarAnalytics />
      <div style={{ overflowX: "hidden", backgroundColor: "rgb(240,240,240)" }}>
        <PageContainer fluid>
          <Row style={{ margin: "25px 0px" }}>
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <img src={logo} width="300" height="300" alt="frontend-app" /> */}
                <div>
                  <h1>Site Analytics</h1>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={8}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StatCardLong>
                <div style={{ margin: "20px 0px" }}>
                  <h5>Sessions Completed</h5>
                  <p>Sessions completed between Students and Mentors</p>
                </div>
                <FlexItemsCenter>
                  <FlexItemsCenter>
                    <CardIconContainer>
                      <FontAwesomeIcon
                        icon={faGlobe}
                        fontSize={55}
                        color='#1170b8'
                      />
                    </CardIconContainer>
                    <h2>
                      {sessionMetrics?.allCompletedSessions.data[0]?.count ??
                        111}
                    </h2>
                  </FlexItemsCenter>
                  <div style={{ height: 400, width: 650, marginLeft: "auto" }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={300}
                        // barChart
                        data={data3}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='total' stackId='a' fill='#1170b8' />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </FlexItemsCenter>

                <ChartTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableHeaderStyle>21+</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>First Generation</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Military Affiliated</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>STEM Affiliated</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Freshmen</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Sophomore</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Junior</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Senior</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Graduate</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Alumni</TableHeaderStyle>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsAdultLearner
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsFirstGen.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsMilitary.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsStem.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsFreshmen.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsSophomore
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsJunior.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsSenior.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsGraduate.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            sessionMetrics?.allCompletedSessionsAlumni.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ChartTable>
              </StatCardLong>
              <StatCardLong>
                <div style={{ margin: "20px 0px" }}>
                  <h5>Verified Mentors</h5>
                  <p>
                    Users who have signed up and verified their account
                    information
                  </p>
                </div>
                <FlexItemsCenter>
                  <FlexItemsCenter>
                    <CardIconContainer>
                      <FontAwesomeIcon
                        icon={faUserCheck}
                        fontSize={55}
                        color='#1170b8'
                      />
                    </CardIconContainer>
                    <h1>
                      {signUpVerifiedMetrics?.verifiedMentorData.data[0]?.count}
                    </h1>
                  </FlexItemsCenter>
                  <div style={{ height: 400, width: 650, marginLeft: "auto" }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <AreaChart
                        width={500}
                        height={300}
                        data={lineChartVerifiedData1}
                        syncId='lineChartVerifiedData'
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <Tooltip />
                        <Area
                          type='monotone'
                          dataKey='value'
                          stroke='#1170b8'
                          fill='#69a7d4'
                        />
                        <Tooltip />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </FlexItemsCenter>

                <ChartTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableHeaderStyle>Alumni</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>First Generation</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Military Affiliated</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>STEM Affiliated</TableHeaderStyle>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentor_alumni
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentor_first_gen
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentor_military
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentor_stem
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ChartTable>
              </StatCardLong>
              <StatCardLong>
                <div style={{ margin: "20px 0px" }}>
                  <h5>Verified Students</h5>
                  <p>
                    Users who have signed up and verified their account
                    information
                  </p>
                </div>
                <FlexItemsCenter>
                  <FlexItemsCenter>
                    <CardIconContainer>
                      <FontAwesomeIcon
                        icon={faUserCheck}
                        fontSize={55}
                        color='#1170b8'
                      />
                    </CardIconContainer>
                    <h1>
                      {signUpVerifiedMetrics?.verifiedMenteeData.data[0]?.count}
                    </h1>
                  </FlexItemsCenter>
                  <div style={{ height: 400, width: 650, marginLeft: "auto" }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <AreaChart
                        width={500}
                        height={300}
                        data={lineChartVerifiedData2}
                        syncId='lineChartVerifiedData'
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <Tooltip />
                        <Area
                          type='monotone'
                          dataKey='value'
                          stroke='#1170b8'
                          fill='#69a7d4'
                        />
                        <Tooltip />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </FlexItemsCenter>

                <ChartTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableHeaderStyle>21+</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>First Generation</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Military Affiliated</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>STEM Affiliated</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Freshmen</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Sophomore</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Junior</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Senior</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Graduate</TableHeaderStyle>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics
                              ?.verifiedData_mentee_adult_learner.data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_first_gen
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_military
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_stem
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_freshmen
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_sophomore
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_junior
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_senior
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.verifiedData_mentee_graduate
                              .data[0]?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ChartTable>
              </StatCardLong>
              <StatCardLong>
                <div style={{ margin: "20x 0px" }}>
                  <h5>Connections</h5>
                  <p>Connections made between Students and Mentors</p>
                </div>
                <FlexItemsCenter>
                  <FlexItemsCenter>
                    <CardIconContainer>
                      <FontAwesomeIcon
                        icon={faHandshake}
                        fontSize={55}
                        color='#1170b8'
                      />
                    </CardIconContainer>
                    <div style={{ width: 150 }}>
                      <h3>{getConnectionMetricsCount("approved")} Approved</h3>
                      <h3>{getConnectionMetricsCount("denied")} Denied</h3>
                    </div>
                  </FlexItemsCenter>
                  <div
                    style={{
                      height: 400,
                      width: 650,
                      marginLeft: "auto",
                      position: "relative",
                      left: -25,
                    }}
                  >
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        width={500}
                        height={300}
                        data={barChartConnectionData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey='approved' stackId='a' fill='#1170b8' />
                        <Bar dataKey='denied' stackId='a' fill='#ff7701' />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </FlexItemsCenter>
              </StatCardLong>
              <StatCardLong>
                <div style={{ margin: "20px 0px" }}>
                  <h5>Signed-Up Users</h5>
                  <p>
                    Users who signed up but have not verified their account
                    information
                  </p>
                </div>
                <FlexItemsCenter>
                  <FlexItemsCenter>
                    <CardIconContainer>
                      <FontAwesomeIcon
                        icon={faUserGroup}
                        fontSize={55}
                        color='#1170b8'
                      />
                    </CardIconContainer>
                    <h1>
                      {signUpVerifiedMetrics?.signedUpData.data[0]?.count}
                    </h1>
                  </FlexItemsCenter>
                  <div style={{ height: 400, width: 650, marginLeft: "auto" }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <AreaChart
                        width={500}
                        height={300}
                        data={lineChartSignUpData}
                        syncId='lineChartSignUpData'
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                      >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='name' />
                        <Tooltip />
                        <Area
                          type='monotone'
                          dataKey='value'
                          stroke='#1170b8'
                          fill='#69a7d4'
                        />
                        <Tooltip />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </FlexItemsCenter>

                <ChartTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableHeaderStyle>Mentors</TableHeaderStyle>
                      </TableCell>
                      <TableCell>
                        <TableHeaderStyle>Students</TableHeaderStyle>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.signedUpMentorData.data[0]
                              ?.count
                          }
                        </TableDataStyle>
                      </TableCell>
                      <TableCell>
                        <TableDataStyle>
                          {
                            signUpVerifiedMetrics?.signedUpMenteeData.data[0]
                              ?.count
                          }{" "}
                        </TableDataStyle>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ChartTable>
              </StatCardLong>
            </Col>
            <Col lg={4}>
              <StatCardShort
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: 50,
                }}
              >
                <div style={{ marginTop: 15 }}>
                  <h4>Verified Accounts</h4>
                  <p>Users who have verified their account</p>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PieChart width={450} height={500}>
                      <Pie
                        data={pieChartData2}
                        innerRadius={160}
                        outerRadius={200}
                        fill='#8884d8'
                        paddingAngle={5}
                        dataKey='value'
                        width={500}
                      >
                        {pieChartData2.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              entry.name === "Mentors"
                                ? "#1170b8"
                                : entry.name === "Students"
                                ? "rgb(163 228 255)"
                                : "#ff7701"
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <text
                        x='50%'
                        y='50%'
                        textAnchor='middle'
                        dominantBaseline='middle'
                        fill='#777'
                        style={{ fontWeight: 400, fontSize: "1.5em" }}
                      >
                        {pieChartData2[1].value + pieChartData2[2].value}{" "}
                        Verified
                      </text>
                    </PieChart>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      fontSize: "1.1em",
                      color: "#111",
                    }}
                  >
                    <div style={{ marginRight: 25 }}>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        All Users in System
                      </div>
                      <div> {pieChartData2[0].value}</div>
                    </div>
                    <div style={{ marginRight: 25 }}>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        Mentors
                      </div>
                      <div> {pieChartData2[1].value}</div>
                    </div>
                    <div>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        Students
                      </div>
                      <div> {pieChartData2[2].value}</div>
                    </div>
                  </div>
                </div>
              </StatCardShort>
              <StatCardShort>
                <div style={{ marginTop: 15 }}>
                  <h4>Unverified Users</h4>
                  <p>
                    Users who have signed up but have not verified their account
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PieChart width={600} height={500}>
                      <Pie
                        data={pieChartData}
                        innerRadius={160}
                        outerRadius={200}
                        fill='#8884d8'
                        paddingAngle={5}
                        dataKey='value'
                        width={200}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              entry.name === "Mentors"
                                ? "#1170b8"
                                : entry.name === "Students"
                                ? "rgb(163 228 255)"
                                : "#ff7701"
                            }
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <text
                        x='50%'
                        y='50%'
                        textAnchor='middle'
                        dominantBaseline='middle'
                        fill='#777'
                        style={{ fontWeight: 400, fontSize: "1.5em" }}
                      >
                        {pieChartData[0].value + pieChartData[1].value} Need
                        Verification
                      </text>
                    </PieChart>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      fontSize: "1.1em",
                      color: "#111",
                    }}
                  >
                    <div style={{ marginRight: 25 }}>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        All Users in System
                      </div>
                      <div> {pieChartData[2].value}</div>
                    </div>
                    <div style={{ marginRight: 25 }}>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        Mentors
                      </div>
                      <div> {pieChartData[0].value}</div>
                    </div>
                    <div>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        Students
                      </div>
                      <div> {pieChartData[0].value}</div>
                    </div>
                  </div>
                </div>
              </StatCardShort>
              <StatCardShort
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginBottom: 50,
                }}
              >
                <div style={{ marginTop: 15 }}>
                  <h4>Mentors and Students</h4>
                  <p>
                    Mentors and Students who have signed up or verified their
                    account
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PieChart width={450} height={500}>
                      <Pie
                        data={pieChartData3}
                        innerRadius={160}
                        outerRadius={200}
                        fill='#8884d8'
                        paddingAngle={5}
                        dataKey='value'
                        width={500}
                      >
                        {pieChartData3.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                      <text
                        x='50%'
                        y='45%'
                        textAnchor='middle'
                        dominantBaseline='middle'
                        fill='#777'
                        style={{ fontWeight: 400, fontSize: "1.5em" }}
                      >
                        {pieChartData3[1].value} Mentors
                      </text>
                      <text
                        x='50%'
                        y='55%'
                        textAnchor='middle'
                        dominantBaseline='middle'
                        fill='#777'
                        style={{ fontWeight: 400, fontSize: "1.5em" }}
                      >
                        {pieChartData3[2].value} Students
                      </text>
                    </PieChart>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      fontSize: "1.1em",
                      color: "#111",
                    }}
                  >
                    <div style={{ marginRight: 25 }}>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        All Users in System
                      </div>
                      <div> {pieChartData3[0].value}</div>
                    </div>
                    <div style={{ marginRight: 25 }}>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        Mentors
                      </div>
                      <div> {pieChartData3[1].value}</div>
                    </div>
                    <div>
                      <div
                        style={{
                          color: "#777",
                        }}
                      >
                        Students
                      </div>
                      <div> {pieChartData3[2].value}</div>
                    </div>
                  </div>
                </div>
              </StatCardShort>
            </Col>
          </Row>

          <StatCardLong>
            <div style={{ margin: "20px 0px" }}>
              <h5>In Kind Hours</h5>
              <p>1 completed session = $25</p>
            </div>
            <FlexItemsCenter>
              <FlexItemsCenter>
                <CardIconContainer>
                  <FontAwesomeIcon
                    icon={faCalendarCheck}
                    fontSize={55}
                    color='#1170b8'
                  />
                </CardIconContainer>
              </FlexItemsCenter>
              <div
                style={{
                  height: 400,
                  width: 650,
                  marginLeft: "auto",
                  overflow: "visible",
                }}
              >
                <ResponsiveContainer width='100%' height='100%'>
                  <BarChart
                    width={500}
                    height={300}
                    data={completedAppointmentChartData}
                    layout='vertical'
                    margin={{
                      top: 20,
                      right: 30,
                      left: 30,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis type='number' />
                    <YAxis type='category' dataKey='name' />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey='inKindDollars' stackId='a' fill='#1170b8' />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </FlexItemsCenter>
            <ChartTable>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableHeaderStyle>In Kind Hours</TableHeaderStyle>
                  </TableCell>
                  <TableCell>
                    <TableHeaderStyle>In Kind Dollars</TableHeaderStyle>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <TableDataStyle>
                      {completedAppointments?.length}
                    </TableDataStyle>
                  </TableCell>
                  <TableCell>
                    <TableDataStyle>
                      ${(completedAppointments?.length || 0) * 25}{" "}
                    </TableDataStyle>
                  </TableCell>
                </TableRow>
              </TableBody>
            </ChartTable>
          </StatCardLong>

          <Row>
            <StatCardLong>
              <div style={{ margin: "20x 0px" }}>
                <h5>Connections by State</h5>
                <p>
                  Connections made between Students and Mentors from the same
                  state
                </p>
              </div>
              <div>
                <ConnectionsByStateMap />
              </div>
            </StatCardLong>
          </Row>
        </PageContainer>
      </div>
    </>
  );
}

// Styled Components
const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
  padding: 110px 50px 110px 50px;
`;

const StatCardLong = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  background-color: #fff;
  padding: 10px 10px 10px 25px;
  border-color: #ddd;
  border-style: solid;
  border-width: 2px;
  &:hover {
    box-shadow: 1px 2px 2px 2px #ccc;
    -webkit-box-shadow: 1px 2px 2px 2px #ccc;
    -moz-box-shadow: 1px 2px 2px 2px #ccc;
  }
`;

const StatCardShort = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
  background-color: #fff;
  padding: 10px 10px 10px 10px;
  border-color: #ddd;
  border-style: solid;
  border-width: 2px;
  &:hover {
    box-shadow: 1px 2px 2px 2px #ccc;
    -webkit-box-shadow: 1px 2px 2px 2px #ccc;
    -moz-box-shadow: 1px 2px 2px 2px #ccc;
  }
`;

const FlexItemsCenter = styled("div")`
  display: flex;
  align-items: center;
`;

const CardIconContainer = styled("div")`
  background-color: rgb(163 228 255);
  display: flex;
  align-items: center;
  border-radius: 360px;
  height: 130px;
  width: 130px;
  justify-content: center;
  margin-right: 20px;
`;

const TableHeaderStyle = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableDataStyle = styled("p")`
  text-align: center;
  margin: 0px;
  font-size: 1.6em;
`;

const ChartTable = styled(Table)`
  margin-top: 10px;
`;
const CardIconContainer2 = styled("div")`
  background-color: rgb(255 119 1);
  display: flex;
  align-items: center;
  border-radius: 360px;
  height: 80px;
  width: 80px;
  justify-content: center;
  margin-right: 50px;
`;
