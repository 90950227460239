import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import logo from "../navbar/main-logo.png";
import { deleteCookie } from "../../utils/cookies";
import { useData } from "../../utils/context/DataContext";

export const NavBarAlt = () => {
  const navigate = useNavigate();
  const { data } = useData();

  const doLogout = async () => {
    await deleteCookie("mentor-token");
    navigate("/");
  };

  const links: { displayName: string; path: string; alt?: boolean }[] = [
    { displayName: "Dashboard", path: "dashboard" },
    { displayName: "Sessions", path: "sessions" },
    { displayName: "Connections", path: "connections" },
    {
      displayName: "Profile",
      alt: true,
      path: `/profile/${data?.id}`,
    },
  ];

  return (
    <StyledNavbarContainer>
      <StyledNavbar expand="lg" variant="light" style={{ zIndex: "10000000" }}>
        <ContainerSection>
          <Navbar.Brand
            href="#home"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link to={"/dashboard"}>
              <NavBarLogoImg src={logo} />
            </Link>
          </Navbar.Brand>
          <ToggleContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </ToggleContainer>
          <Navbar.Collapse id="basic-navbar-nav">
            <NavText>Res Non Verba. "Deeds Not Words."</NavText>

            <Nav className="ml-auto">
              {links.map((navLink) => (
                <ToggleContentContainer>
                  {navLink.alt ? (
                    <NavCustomLinkStyle
                      href={navLink.path}
                      style={{
                        borderBottomStyle: location.pathname.includes(
                          navLink.path
                        )
                          ? "solid"
                          : "none",
                      }}
                    >
                      {navLink.displayName}
                    </NavCustomLinkStyle>
                  ) : (
                    <NavLinkStyle
                      style={{
                        borderBottomStyle: location.pathname.includes(
                          navLink.path
                        )
                          ? "solid"
                          : "none",
                      }}
                      to={`/${navLink.path}`}
                    >
                      {navLink.displayName}
                    </NavLinkStyle>
                  )}
                </ToggleContentContainer>
              ))}
              <ToggleContentContainer>
                <NavCustomLinkStyle onClick={doLogout}>
                  Log Out
                </NavCustomLinkStyle>
              </ToggleContentContainer>
            </Nav>
          </Navbar.Collapse>
        </ContainerSection>
      </StyledNavbar>
    </StyledNavbarContainer>
  );
};
const StyledNavbar = styled(Navbar)`
  position: absolute;
  top: 8%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  @media (max-width: 1400px) {
    position: absolute;
    width: 90%;
  }
  @media (max-width: 768px) {
    position: fixed;
    z-index: 100000000;
    width: 100%;
    top: 0%;
  }
`;
const StyledNavbarContainer = styled("div")`
  display: flex;
  z-index: 100000000;
`;

const ContainerSection = styled(Container)`
  background-color: rgb(250 250 250 / 99%) !important;
  color: black;
  border-radius: 9px;
  padding: 10px;
  border-style: solid;
  border-color: #bbb;
  @media (max-width: 768px) {
    position: fixed;
    z-index: 100000000;
    width: 100%;
    top: 0%;
  }
`;

const NavBarLogoImg = styled("img")`
  height: 70px;
  width: 125px;
  margin-left: 20px;
  @media (max-width: 768px) {
    height: 40px;
    width: 50px;
  }
`;

const NavLinkStyle = styled(Link)`
  text-decoration: none;
  color: rgb(0, 53, 100);
  margin: 0px 10px;
  font-weight: 800;
  cursor: pointer;
  letter-spacing: 2px;
  border-color: #ff7701;
  border-bottom-width: 2px;
  border-bottom-style: none;
  @media (max-width: 1200px) {
    font-size: 0.7em;
  }
`;

const NavCustomLinkStyle = styled("a")`
  text-decoration: none;
  color: rgb(0, 53, 100) !important;
  margin: 0px 10px;
  font-weight: 800;
  cursor: pointer;
  letter-spacing: 2px;
  border-color: #ff7701;
  border-bottom-width: 2px;
  border-bottom-style: none;
  @media (max-width: 1200px) {
    font-size: 0.7em;
  }
`;

const ToggleContainer = styled("div")`
  margin-right: 20px;
`;

const ToggleContentContainer = styled("div")`
  margin-left: 20px;
  @media (max-width: 1200px) {
    margin: 0px;
  }
`;

const NavText = styled("h1")`
  font-size: 1.2em;
  font-weight: 300;
  font-style: italic;
  padding: 10px;
  margin: 0px;
  color: #1170b8;
  @media (max-width: 1200px) {
    font-size: 0.7em;
  }
`;
