import React from "react";
import { AvailableDayCard } from "./AvailableDayCard";
import { Availability } from "./interfaces";
import styled from "styled-components";

export const AvailableDayCardContainer = ({
  availability,
}: {
  availability: Availability[];
}) => {
  return (
    <AvailableDayCardContainerStyle>
      <AvailabilityContainer>
        {availability
          ?.filter((dayBlock) => dayBlock.enabled)
          ?.map((dayBlock) => (
            <AvailableDayCard availability={dayBlock} />
          ))}
      </AvailabilityContainer>
    </AvailableDayCardContainerStyle>
  );
};

const AvailabilityContainer = styled("div")`
  @media (max-width: 768px) {
  }
`;

const AvailableDayCardContainerStyle = styled("div")`
  display: flex;
`;
