import React from "react";
import { Modal, Button } from "react-bootstrap";
import { ConnectionByState } from "../map/ConnectionsByStateMap";
import ConnectionCard from "../../Connections/ConnectionCard";

interface ConnectionModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  connectionsInState: ConnectionByState[];
}

export function ConnectionModal(props: ConnectionModalProps) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          backgroundColor: "rgb(163, 228, 255)",
          color: "rgb(0, 119, 181)",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.connectionsInState.map((connection, i) => {
          return (
            <ConnectionCard
              key={i}
              mentor_id={connection.mentor_id}
              mentee_id={connection.mentee_id}
            />
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            backgroundColor: "rgb(0, 119, 181)",
          }}
          onClick={props.onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
