import axios from "axios";
interface UserConnections {}

/***
 * Get Availability for a mentor user
 */
const getUserAvailability = async (userId: string) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/availability/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

/***
 * set Availability for a mentor user
 */
const setUserAvailability = async (userId: string, availability) => {
  const { data, headers } = await axios(
    `https://mentor-portal-backend.azurewebsites.net/api/currentUser/availability`,
    {
      method: "POST",
      data: {
        mentor_id: userId,
        availability: availability,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return data;
};

export { getUserAvailability, setUserAvailability };
