import styled from "styled-components";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useData } from "../utils/context/DataContext";
import { deleteCookie, getCookie, setCookie } from "../utils/cookies";
import { decodeJWTBearer } from "../utils/decodeJWT";
import axios, { AxiosError } from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { faRedo, faStar, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStartOutline } from "@fortawesome/free-regular-svg-icons";
import logo from "./logo.png";
import { NavBarAlt } from "../ui/navbar/NavbarAlt";
import { NavbarAdmin } from "../ui/navbar/NavbarAdmin";
import { getConnections, getConnection } from "../../apis/Connection";
import { UserData, ConnectionData } from "../Mentor/interfaces";
import { PDFViewer } from "../Common/PDFViewer";
import PersonalFormEdit from "../SignUp/form/PersonalFormEdit";
import SchoolFormEdit from "../SignUp/form/SchoolFormEdit";
import { FileUploader } from "../Common/FileUploader";
import { getUserInterests } from "../../apis/Interests";
import { Interests } from "../Interests/Interests";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faFile,
  faHandshake,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { DisplayClassification } from "../Common/DisplayClassification";
import { SetAvailability } from "../SetAvailability";

import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Availability } from "../SetAvailability/interfaces";
import { getUserAvailability } from "../../apis/Availability";
import { INIT_AVAILABILITY_STATE } from "../SetAvailability/constants";
import { formatTime } from "../SetAvailability/SetAvailability";
import { GetDayOfWeekDisplayName } from "../SetAvailability/AvailableDayCard";
import { AvailableDayRow } from "../SetAvailability/AvailableDayRow";
import { AvailableTimeRow } from "../SetAvailability/AvailableTimeRow";
import { PushMessage } from "../Admin/PushMessage";
import {
  getIsUserFavorite,
  removeUserFavorite,
  setUserFavorite,
} from "../../apis/User";
import { ImageCropper } from "./Verification";

export interface AvailabilityTimeBlock {
  start_time: string;
  end_time: string;
}
export interface AvailabilityGroup {
  date: string;
  availability: AvailabilityTimeBlock[];
}

const PushMessageModal = ({
  showModal,
  handleClose,
  userEmail,
}: {
  showModal: boolean;
  handleClose: () => void;
  userEmail: string;
}) => {
  return (
    <Modal
      style={{ zIndex: 100000000, top: 100 }}
      show={showModal}
      onHide={handleClose}
      dialogClassName='modal-lg'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <PushMessage predefinedEmail={userEmail} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ResumeModal = ({
  showModal,
  handleClose,
  user,
}: {
  showModal: boolean;
  handleClose: () => void;
  user: UserData | null;
}) => {
  if (!user) return;
  return (
    <Modal
      style={{ zIndex: 100000000, top: 100 }}
      show={showModal}
      onHide={handleClose}
      dialogClassName='modal-lg'
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <PDFViewer id={user.id} fileLink={user.resume_link} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ReplaceProfileImageModal = ({
  showModal,
  handleClose,
  user,
  setImage,
}: {
  showModal: boolean;
  handleClose: () => void;
  user: UserData | null;
  setImage: (image) => void;
}) => {
  const { setData, data } = useData();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageFile, setSelectedImageFile] = useState<string | null>(
    null
  );
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelectedImage = async () => {
    try {
      setErrorMessage("");
      setIsLoadingImage(true);
      const { data: profileData } = await axios.post(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${user.id}/upload/profile`,
        selectedImageFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setImage(`${profileData.profile_image}?${Math.random()}`);
      handleClose();
    } catch (e) {
      setErrorMessage("error uploading profile picture");
      console.log(e);
      setIsLoadingImage(false);
    }
  };

  if (!user) return;
  return (
    <Modal
      style={{ zIndex: 100000000, top: 100 }}
      show={showModal}
      onHide={handleClose}
      dialogClassName='modal-lg'
    >
      <Modal.Header closeButton className='text-center'>
        <div style={{ flex: 1 }}>
          <h3>Upload profile picture</h3>
          <h5>Please ensure the picture is clear and professional</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col
              lg={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <CropContainer>
                <ImageCropper
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  setSelectedImageFile={setSelectedImageFile}
                />
              </CropContainer>
            </Col>
            <Col>
              {isLoadingImage && (
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Uplaoding Image{" "}
                  <div className='ml-3 spinner-border text-primary'></div>
                </Col>
              )}
              {selectedImage && !isLoadingImage && (
                <Col className='text-center'>
                  <Button
                    className='mx-3'
                    onClick={() => {
                      setSelectedImage(null);
                    }}
                    variant='danger'
                  >
                    Redo <FontAwesomeIcon icon={faRedo} />
                  </Button>
                  <Button onClick={handleSelectedImage} className='mx-3'>
                    Upload <FontAwesomeIcon icon={faThumbsUp} />
                  </Button>
                </Col>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface ActionButtonProps {
  isLoading: boolean;
  loggedInUserData: UserData;
  userProfileData: UserData;
  connectionData: ConnectionData | null;
  handlePatchConnectionRequest: any;
  handleDisconnectRequest: any;
  handleConnectionRequest: any;
  isProfileOwner: boolean;
  toggleIsEditing: () => void;
  isEditing: boolean;
}

export const ActionButton = ({
  isLoading,
  loggedInUserData,
  userProfileData,
  connectionData,
  handlePatchConnectionRequest,
  handleDisconnectRequest,
  handleConnectionRequest,
  isProfileOwner,
  toggleIsEditing,
  isEditing = true,
}: ActionButtonProps) => {
  if (isProfileOwner)
    return (
      <div style={{ margin: "0px 20px" }}>
        <Button
          onClick={toggleIsEditing}
          style={{ backgroundColor: "rgba(55, 133, 191, 0.94)" }}
        >
          {isEditing ? "Done" : "Edit Account"}
        </Button>
      </div>
    );
  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0px 20px",
        }}
      >
        <div className='spinner-border text-primary'></div>
      </div>
    );

  if (loggedInUserData && userProfileData) {
    if (loggedInUserData.type === "MENTOR") {
      if (connectionData) {
        if (connectionData.status === "REQUESTED") {
          return (
            <div style={{ margin: "0px 20px" }}>
              {loggedInUserData.id !== userProfileData?.id && (
                <>
                  <Button
                    style={{ margin: "0px 5px" }}
                    variant='outline-danger'
                    onClick={() => {
                      handlePatchConnectionRequest("DENIED");
                    }}
                  >
                    Deny
                  </Button>
                  <Button
                    style={{ margin: "0px 5px" }}
                    onClick={() => {
                      handlePatchConnectionRequest("ACCEPTED");
                    }}
                  >
                    Accept
                  </Button>
                </>
              )}
            </div>
          );
        } else if (connectionData.status === "DENIED") {
          return (
            <div style={{ margin: "0px 20px" }}>
              {loggedInUserData.id !== userProfileData?.id && (
                <>
                  <span className='mr-4'>Connection Denied</span>
                  <Button
                    variant='outline-warning'
                    onClick={handleDisconnectRequest}
                  >
                    undo
                  </Button>
                </>
              )}
            </div>
          );
        } else {
          return (
            <div style={{ margin: "0px 20px" }}>
              {loggedInUserData.id !== userProfileData?.id && (
                <>
                  <span className='mr-4'>Connected</span>
                  <Button
                    variant='outline-danger'
                    className='text-dark'
                    onClick={handleDisconnectRequest}
                  >
                    Disconnect
                  </Button>
                </>
              )}
            </div>
          );
        }
      }
    } else {
      if (connectionData) {
        if (connectionData.status === "REQUESTED") {
          return (
            <div style={{ margin: "0px 20px" }}>
              {loggedInUserData.id !== userProfileData?.id && (
                <div>
                  <span className='mr-2 text-success'>
                    Connection Requested
                  </span>
                  <Button
                    variant='outline-danger'
                    onClick={handleDisconnectRequest}
                  >
                    undo
                  </Button>
                </div>
              )}
            </div>
          );
        } else if (connectionData.status === "DENIED") {
          return (
            <div style={{ margin: "0px 20px" }}>
              {loggedInUserData.id !== userProfileData?.id && (
                <Button disabled variant='outline-danger'>
                  Connection Denied
                </Button>
              )}
            </div>
          );
        } else {
          return (
            <div style={{ margin: "0px 20px" }}>
              {loggedInUserData.id !== userProfileData?.id && (
                <>
                  <Button
                    variant='outline-danger'
                    className='text-dark'
                    onClick={handleDisconnectRequest}
                  >
                    Remove Connection
                  </Button>
                </>
              )}
            </div>
          );
        }
      } else {
        return (
          <div style={{ margin: "0px 20px" }}>
            <div>
              <Button
                onClick={handleConnectionRequest}
                style={{ backgroundColor: "rgba(55, 133, 191, 0.94)" }}
              >
                Request Connection
              </Button>
            </div>
          </div>
        );
      }
    }
  } else {
    return null;
  }
};

export const Connections = ({
  connections,
  userType,
  filter,
  isLoading,
}: {
  connections: ConnectionData[];
  userType: string;
  filter: string;
  isLoading: boolean;
}) => {
  const filteredConnections = connections.filter((connection) => {
    return connection.status === filter;
  });

  const handleClick = (userId) => {
    window.location.href = `/profile/${userId}`;
  };

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        <div className='spinner-border text-primary'></div>
      </div>
    );

  if (filteredConnections.length > 0) {
    return filteredConnections.map((connection) => {
      const connectionPhoto =
        userType === "MENTEE"
          ? connection.mentor_profile_image
          : connection.mentee_profile_image;

      const userId =
        userType === "MENTEE" ? connection.mentor_id : connection.mentee_id;

      const connectionName =
        userType === "MENTEE"
          ? `${connection.mentor_first_name} ${connection.mentor_last_name}`
          : `${connection.mentee_first_name} ${connection.mentee_last_name}`;

      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "10px 0px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleClick(userId);
          }}
          key={userId}
        >
          <SmallImageContainer>
            <SmallProfileImg src={connectionPhoto ?? logo} alt='frontend-app' />
          </SmallImageContainer>
          <div style={{ marginLeft: 15 }}>
            <h6>{connectionName}</h6>
          </div>
        </div>
      );
    });
  } else {
    return <p>None at this time</p>;
  }
};

export default function UserProfile() {
  const { setData, data } = useData();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState({ error: false, code: 200 });
  const [isLoadingConnection, setIsLoadingConnection] = useState(true);
  const [connectionRequestError, setConnectionRequestError] = useState({
    error: false,
    code: 200,
  });
  const [currentUserProfileData, setCurrentUserProfileData] =
    useState<UserData | null>(null);
  const [
    currentUserProfileConnectionData,
    setCurrentUserProfileConnectionData,
  ] = useState<ConnectionData | null>(null);
  const [
    currentUserProfileConnectionsData,
    setCurrentUserProfileConnectionsData,
  ] = useState([]);
  const [
    isLoadingCurrentUserProfileConnectionsData,
    setIsLoadingCurrentUserProfileConnectionsData,
  ] = useState(true);

  const [isProfileOwner, setIsProfileOwner] = useState(true);
  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [key, setKey] = useState("personal");

  const [showModal, setShowModal] = useState(false);

  const [showResumeModal, setShowResumeModal] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);

  const [selectedAvailabilityDay, setSelectedAvailabilityDay] =
    useState<Availability | null>();

  const toggleSelectedAvailabilityDay = (day: Availability) => {
    if (!selectedAvailabilityDay) {
      setSelectedAvailabilityDay(day);
    } else if (
      JSON.stringify(day) === JSON.stringify(selectedAvailabilityDay)
    ) {
      setSelectedAvailabilityDay(null);
    } else {
      setSelectedAvailabilityDay(day);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [favoriteData, setFavoriteData] = useState<{
    isLoading: boolean;
    isFavorite: boolean;
    error: boolean;
  }>({ isLoading: true, isFavorite: false, error: false });

  const [selectedFileFormData, setSelectedFileFormData] = useState(null);
  const [userResumeLink, setUserResumeLink] = useState<string | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const [availability, setAvailability] = useState<Availability[]>([]);

  const [fetchedImageUrl, setFetchedImageUrl] = useState("");

  const [currentUserInterests, setCurrentUserInterests] = useState<
    | {
        id: string;
        name: string;
        user_id: string;
      }[]
  >([]);

  const handleUploadFile = async (resumeFile) => {
    try {
      setIsUploadingFile(true);
      const { data: profileData } = await axios.post(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${currentUserProfileData?.id}/upload/resume`,
        resumeFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUserResumeLink(profileData.profile_resume);
      const cloneData = {
        ...currentUserProfileData,
        resume_link: profileData.profile_resume,
      };
      setCurrentUserProfileData(cloneData as UserData);
      setIsUploadingFile(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleRemoveResume = async (resumeFile) => {
    try {
      setIsUploadingFile(true);
      const { data: profileData } = await axios.delete(
        `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${currentUserProfileData?.id}/upload/resume`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setUserResumeLink(null);
      setSelectedFile(null);
      setSelectedFileFormData(null);
      setIsUploadingFile(false);
      const cloneData = {
        ...currentUserProfileData,
        resume_link: null,
      };
      setCurrentUserProfileData(cloneData as unknown as UserData);
    } catch (e) {
      console.log(e);
    }
  };

  const isValidUUID = (value) => {
    // Regular expression for UUID validation
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    // Test the value against the regex pattern
    return uuidRegex.test(value);
  };

  const handleIsSignedIn = async () => {
    if (data) {
      setData(data);
      if (data.resume_link) {
        setUserResumeLink(data.resume_link);
      }
      handleParams();
      if (id) {
        const getConnectionsData = await getConnections(id);
        handleSetAvailabilityState();
        setCurrentUserProfileConnectionsData(getConnectionsData);
        setIsLoadingCurrentUserProfileConnectionsData(false);
        handleFetchUserInterests(id);
      }
      return;
    }

    const mentorToken = await getCookie("mentor-token");

    if (mentorToken) {
      try {
        const jwtUser = decodeJWTBearer(mentorToken);
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${jwtUser.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(userData);
        setTimeout(async () => {
          if (userData.isAdmin) {
            handleParams();
            const getConnectionsData = await getConnections(id);
            setCurrentUserProfileConnectionsData(getConnectionsData);
            setIsLoadingCurrentUserProfileConnectionsData(false);
            return;
          }
          if (!userData.account_verified) {
            navigate("/");
          } else {
            handleParams();

            if (id) {
              const getConnectionsData = await getConnections(id);
              setCurrentUserProfileConnectionsData(getConnectionsData);
              setIsLoadingCurrentUserProfileConnectionsData(false);
              handleFetchUserInterests(id);
            }
          }
        }, 500);
      } catch (e) {
        console.log("error using mentor token");
        setIsLoading(false);
        deleteCookie("mentor-token");
        navigate("/");
      }
    } else {
      setTimeout(() => {
        setIsLoading(false);
        navigate("/");
      }, 600);
    }
  };

  const handleUserCurrentUserConnection = async () => {
    if (data && currentUserProfileData) {
      if (!data.isAdmin) {
        if (currentUserProfileData.id === data.id) {
          setIsProfileOwner(true);
          return;
        } else {
          setIsProfileOwner(false);
        }
        try {
          const postData =
            data.type === "MENTOR"
              ? { mentor_id: data.id, mentee_id: currentUserProfileData?.id }
              : { mentor_id: currentUserProfileData?.id, mentee_id: data.id };
          const connectionData = await getConnection(postData);
          setCurrentUserProfileConnectionData(connectionData);
          setIsLoadingConnection(false);
        } catch (err) {
          console.log(err);
          // need error handling here
          setIsLoadingConnection(false);
        }
      }
    }
  };

  const handleConnectionRequest = async () => {
    try {
      const postData =
        data.type === "MENTOR"
          ? { mentor_id: data.id, mentee_id: currentUserProfileData?.id }
          : { mentor_id: currentUserProfileData?.id, mentee_id: data.id };

      const { data: connectionPostData } = await axios(
        `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest`,
        {
          method: "POST",
          data: postData,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setCurrentUserProfileConnectionData(connectionPostData);
    } catch (err) {
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  const handleDisconnectRequest = async () => {
    const oldCurrentUserProfileConnectionData =
      currentUserProfileConnectionData;
    try {
      const answer = confirm(
        "Are you sure you want to remove this connection?"
      );
      if (answer) {
        setCurrentUserProfileConnectionData(null);
        const { data: connectionRequestData } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${currentUserProfileConnectionData?.id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (id) {
          const connectionsGetData = await getConnections(id);
          await setCurrentUserProfileConnectionsData(connectionsGetData);
        }
      }
    } catch (err) {
      console.log(err);
      setCurrentUserProfileConnectionData(oldCurrentUserProfileConnectionData);
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  const handlePatchConnectionRequest = async (status) => {
    const oldCurrentUserProfileConnectionData =
      currentUserProfileConnectionData;
    try {
      const patchType = status === "DENIED" ? "deny" : "accept";
      const answer = confirm(
        `Are you sure you want to ${patchType} this connection?`
      );
      if (answer) {
        // setCurrentUserProfileConnectionData(null);
        const { data: connectionRequestData } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/connectionRequest/${currentUserProfileConnectionData?.id}`,
          {
            method: "PATCH",
            data: {
              status,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(connectionRequestData);
        setCurrentUserProfileConnectionData(connectionRequestData);
        if (id) {
          const connectionsGetData = await getConnections(id);
          await setCurrentUserProfileConnectionsData(connectionsGetData);
        }
      }
    } catch (err) {
      console.log(err);
      setCurrentUserProfileConnectionData(oldCurrentUserProfileConnectionData);
      if (err instanceof AxiosError) {
        console.log(err);
        if (err.response?.status === 404) {
          setConnectionRequestError({ error: true, code: 404 });
        } else if (err.response?.status === 409) {
          setConnectionRequestError({ error: true, code: 409 });
        } else {
          setConnectionRequestError({ error: true, code: 400 });
        }
      } else {
        setConnectionRequestError({ error: true, code: 400 });
      }
    }
  };

  const handleFetchUserInterests = async (id) => {
    try {
      const data = await getUserInterests(id);
      setCurrentUserInterests(data);
    } catch (e) {
      console.log(e);
      console.log("Error fetching user interests");
    }
  };

  const handleParams = async () => {
    if (isValidUUID(id)) {
      try {
        const { data: userData, headers } = await axios(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setCurrentUserProfileData(userData);
        if (userData.resume_link) {
          setUserResumeLink(userData.resume_link);
        }
        setIsLoading(false);
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 404) {
            setPageError({ error: true, code: 404 });
          } else {
            setPageError({ error: true, code: 400 });
          }
          setIsLoading(false);
        } else {
          setPageError({ error: true, code: 400 });
          setIsLoading(false);
        }
      }
    } else {
      setPageError({ error: true, code: 404 });
      setIsLoading(false);
    }
  };

  const handleSetAvailabilityState = async () => {
    if (currentUserProfileData) {
      let availData = null;
      // if (isProfileOwner) {
      //   availData = await getCookie(`avail-state-${currentUserProfileData.id}`);
      // }
      if (!availData) {
        try {
          const fetchedUserAvailability: Availability[] =
            await getUserAvailability(currentUserProfileData.id);
          const tempStateFromAvalibility = INIT_AVAILABILITY_STATE;

          fetchedUserAvailability.forEach((userAval): void => {
            tempStateFromAvalibility[userAval.day] = {
              start_time: formatTime(userAval.start_time),
              end_time: formatTime(userAval.end_time),
              enabled: userAval.enabled,
              time_zone: userAval.time_zone,
            };
          });

          // setCookie(
          //   `avail-state-${currentUserProfileData.id}`,
          //   fetchedUserAvailability
          // );

          setAvailability(fetchedUserAvailability);
        } catch (e) {
          console.log(e);
        }
      } else {
        setAvailability(availData);
      }
    }
  };

  const handleIsFavorite = async () => {
    console.log(data);
    console.log(currentUserProfileData);
    console.log(currentUserProfileData?.type);
    console.log(isProfileOwner);
    if (
      data &&
      currentUserProfileData &&
      currentUserProfileData.type === "MENTOR" &&
      !isProfileOwner
    ) {
      try {
        setFavoriteData({ ...favoriteData, isLoading: true });
        const isFavorite = await getIsUserFavorite(
          data.id,
          currentUserProfileData.id
        );
        setFavoriteData({ isLoading: false, isFavorite, error: false });
      } catch (e) {
        console.log(e);
        console.log("Error fetching user favorites");
        setFavoriteData({ isLoading: false, isFavorite: false, error: true });
      }
    }
  };

  const handleStarClick = async (faved: boolean) => {
    if (data && currentUserProfileData && !isProfileOwner) {
      try {
        setFavoriteData({ isLoading: false, isFavorite: faved, error: false });
        const mentor_id = currentUserProfileData.id;
        const mentee_id = data.id;

        // isFavorite does nto need to be used as its just a function call
        const isFavorite = faved
          ? await setUserFavorite(mentee_id, mentor_id)
          : await removeUserFavorite(mentee_id, mentor_id);
      } catch (e) {
        console.log(e);
        console.log("Error fetching user favorites");
        setFavoriteData({ ...favoriteData, isLoading: false, error: true });
      }
    }
  };

  const getImageUrl = async () => {
    if (currentUserProfileData) {
      try {
        const imagesCached = await getCookie("cache-images");
        if (imagesCached) {
          console.log("Cached");
          setFetchedImageUrl(currentUserProfileData.profile_image);
        } else {
          console.log("not Cached");
          const expirationTime = new Date(Date.now() + 30 * 60 * 1000); // 30 minutes from now
          await setCookie("cache-images", "cached", expirationTime);
          setFetchedImageUrl(
            `${currentUserProfileData.profile_image}?${Math.random()}`
          );
        }
      } catch (e) {
        console.log("cache-images error");
        console.log(e);
        setFetchedImageUrl(
          `${currentUserProfileData.profile_image}?${Math.random()}`
        );
      }
    }
  };

  useEffect(() => {
    handleIsSignedIn();
  }, []);

  useEffect(() => {
    handleUserCurrentUserConnection();
    handleSetAvailabilityState();
    handleIsFavorite();
    getImageUrl();
  }, [currentUserProfileData, isProfileOwner]);

  useEffect(() => {
    if (selectedFile && selectedFileFormData) {
      handleUploadFile(selectedFileFormData);
    }
  }, [selectedFile, selectedFileFormData]);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file, `image-${data.id}.png`);
      const reader = new FileReader();
      reader.onloadend = async () => {
        const { data: profileData } = await axios.post(
          `https://mentor-portal-backend.azurewebsites.net/api/currentUser/${currentUserProfileData.id}/upload/profile`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const newProfileData = {
          ...currentUserProfileData,
          profile_image: profileData.profile_image,
        } as unknown as UserData;
        setCurrentUserProfileData({ ...newProfileData });
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    handleSetAvailabilityState();
  }, [isEditingAccount]);

  if (pageError.error) {
    return (
      <>
        <NavBarAlt />
        <Container fluid style={{ marginTop: 110 }}>
          <Row>
            <Col md={4}></Col>
            <Col md={4} style={{ marginTop: 250 }}>
              <h4>404</h4>
              <h1>Can't find the user you are looking for...</h1>
              <h6>
                The user may not exist. Visit the{" "}
                <a href='/dashboard'>Dashboard</a>
              </h6>
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container>
      </>
    );
  }
  if (isLoading) return null;
  return (
    <>
      {data?.isAdmin ? <NavbarAdmin /> : <NavBarAlt />}

      <PageContainer fluid>
        {currentUserProfileData && !isLoading && (
          <>
            <Row1>
              {/* Dashboard intro */}

              <Col md={12}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {/* <img src={logo} width="300" height="300" alt="frontend-app" /> */}
                  <div style={{ marginTop: 15, display: "flex" }}>
                    <Link
                      to={data?.isAdmin ? "/" : "/dashboard"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <h4>Mentors /</h4>
                    </Link>
                    <h4
                      style={{ color: "#0077b5" }}
                      className='ml-2'
                    >{`${currentUserProfileData.first_name} ${currentUserProfileData.last_name}`}</h4>
                  </div>
                </div>
              </Col>
            </Row1>
            <Row2>
              <ImageContainerContainer lg={12}>
                <div>
                  <ImageContainer>
                    {currentUserProfileData.profile_image ? (
                      <ProfileImg src={fetchedImageUrl} alt='frontend-app' />
                    ) : (
                      <img
                        style={{ marginTop: 10 }}
                        src={logo}
                        width={"75%"}
                        height={"75%"}
                      />
                    )}
                  </ImageContainer>

                  <div
                    style={{
                      display: "flex",
                      height: isEditingAccount ? 40 : 0,
                    }}
                  >
                    {isEditingAccount && (
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          style={{
                            // position: "absolute",
                            top: 0,
                            left: 0,
                            width: 250,
                            height: "100%",
                            zIndex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          variant='outline-danger'
                          onClick={() => {
                            setShowImageModal(true);
                          }}
                        >
                          {/* Add any custom text or icon you want to display instead of "No file chosen" */}
                          Replace
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ margin: "20px 10px", flex: 1 }}>
                  <div style={{ height: "1.5em" }}>
                    {currentUserProfileData &&
                      currentUserProfileData.type === "MENTOR" &&
                      !favoriteData.isLoading &&
                      !favoriteData.error && (
                        <FontAwesomeIcon
                          fontSize={"1.2em"}
                          color={
                            favoriteData.isFavorite ? "#ff7701" : "#1170b8"
                          }
                          icon={
                            favoriteData.isFavorite ? faStar : faStartOutline
                          }
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleStarClick(!favoriteData.isFavorite)
                          }
                        />
                      )}
                  </div>
                  <div style={{ margin: "10px 0px" }}>
                    {currentUserProfileData?.alumni ? (
                      <div style={{ margin: "0px 0px", display: "flex" }}>
                        <h6>
                          Alumni
                          {currentUserProfileData.linkedin_url && (
                            <>
                              {" | "}
                              <a
                                target='_blank'
                                href={currentUserProfileData.linkedin_url}
                              >
                                <FontAwesomeIcon
                                  color='#0077b5'
                                  size='xl'
                                  icon={faLinkedin}
                                />
                              </a>
                            </>
                          )}
                        </h6>
                      </div>
                    ) : (
                      <div style={{ margin: "0px 0px" }}>
                        <h6>
                          <DisplayClassification
                            userData={currentUserProfileData}
                          />{" "}
                          {currentUserProfileData.linkedin_url && (
                            <>
                              {" | "}
                              <a
                                target='_blank'
                                href={currentUserProfileData.linkedin_url}
                              >
                                <FontAwesomeIcon
                                  color='#0077b5'
                                  size='xl'
                                  icon={faLinkedin}
                                />
                              </a>
                            </>
                          )}
                        </h6>
                      </div>
                    )}
                    {data.isAdmin && currentUserProfileData.email && (
                      <div style={{ fontSize: ".8em", marginBottom: "10px" }}>
                        {currentUserProfileData.email}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        borderTopStyle: "solid",
                        borderWidth: 1,
                        borderBottomStyle: "solid",
                        padding: "10px 0px",
                      }}
                    >
                      {currentUserProfileData?.state && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            borderColor: "rgb(0, 119, 181)",
                            color: "rgb(0, 119, 181)",
                            backgroundColor: "white",
                            padding: 3,
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{data?.state}</h6>
                        </Button>
                      )}
                      {currentUserProfileData?.military && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            backgroundColor: "#ff7701",
                            padding: 3,
                            color: "white",
                            borderColor: "#eee",
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{`Military Affiliate`}</h6>
                        </Button>
                      )}
                      {currentUserProfileData?.first_generation && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            backgroundColor: "#eee",
                            color: "#0077b5",
                            padding: 5,
                          }}
                        >
                          <h6
                            style={{ margin: 0 }}
                          >{`First Generation Student`}</h6>
                        </Button>
                      )}
                      {currentUserProfileData?.adult_learner && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            color: "white",
                            backgroundColor: "#0077b5",
                            borderColor: "#0077b5",
                            padding: 5,
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{`21+`}</h6>
                        </Button>
                      )}
                      {currentUserProfileData?.stem && (
                        <Button
                          style={{
                            margin: "5px 20px 5px 0px",
                            color: "white",
                            backgroundColor: "#007711",
                            borderColor: "#007711",
                            padding: 5,
                          }}
                        >
                          <h6 style={{ margin: 0 }}>{`STEM`}</h6>
                        </Button>
                      )}
                    </div>
                  </div>
                  <div>
                    <h6>{currentUserProfileData?.bio}</h6>
                  </div>
                  {currentUserProfileData?.resume_link && (
                    <div style={{ margin: "15px 0px" }}>
                      <Button
                        variant='outline-link'
                        style={{ color: "#1170b8" }}
                        onClick={() => setShowResumeModal(true)}
                      >
                        Resume <FontAwesomeIcon icon={faFile} />
                      </Button>
                    </div>
                  )}
                </div>
              </ImageContainerContainer>
            </Row2>
            {isEditingAccount ? (
              <EditAccountRow>
                <Col style={{ display: "flex" }}>
                  <h3 style={{ marginBottom: 25 }}>Account Information</h3>
                  <Button
                    className='ml-auto'
                    style={{
                      margin: "5px 20px 5px 0px",
                      backgroundColor: "#ff7701",
                      padding: 5,
                      color: "white",
                      borderColor: "#eee",
                      borderRadius: 9,
                    }}
                    onClick={() => {
                      setIsEditingAccount(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} />
                    <span className='ml-1'>close</span>
                  </Button>
                </Col>
                <Col lg={12}>
                  <Tabs
                    id='controlled-tab-example'
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className='mb-3'
                  >
                    <Tab eventKey='personal' title='Personal Details'>
                      <PersonalFormEdit
                        setKey={setKey}
                        data={data}
                        isLoading={true}
                        handleChange={() => {}}
                        handleUserUpdate={(newData) => {
                          setCurrentUserProfileData({ ...data, ...newData });
                          setData({ ...data, ...newData });
                        }}
                      />
                    </Tab>
                    <Tab eventKey='school' title='Profile Information'>
                      <SchoolFormEdit
                        setKey={setKey}
                        data={data}
                        isLoading={true}
                        handleUserUpdate={(newData) => {
                          setCurrentUserProfileData({ ...data, ...newData });
                          setData({ ...data, ...newData });
                        }}
                      />
                    </Tab>
                    <Tab eventKey='resume' title='Resume'>
                      <div style={{ margin: "40px 0px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <h5>
                            <i>Resume</i>{" "}
                          </h5>

                          {userResumeLink && (
                            <Button
                              onClick={handleRemoveResume}
                              variant='outline-danger ml-3'
                            >
                              remove
                            </Button>
                          )}
                        </div>
                        <div style={{ backgroundColor: "#eee", padding: 20 }}>
                          {userResumeLink ? (
                            <PDFViewer
                              id={currentUserProfileData.id}
                              fileLink={userResumeLink}
                            />
                          ) : (
                            <FileUploader
                              data={currentUserProfileData}
                              selectedFile={selectedFile}
                              setSelectedFile={setSelectedFile}
                              setSelectedFileFormData={setSelectedFileFormData}
                              isLoading={isUploadingFile}
                            />
                          )}
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey='interests' title='Interests'>
                      <Interests
                        interests={currentUserInterests}
                        setInterests={setCurrentUserInterests}
                        userId={currentUserProfileData.id}
                      />
                    </Tab>
                    {currentUserProfileData.type === "MENTOR" &&
                      isProfileOwner && (
                        <Tab eventKey='Set Availability' title='Availability'>
                          <SetAvailability userData={data} />
                        </Tab>
                      )}
                  </Tabs>
                </Col>
              </EditAccountRow>
            ) : (
              <Row3>
                <Col>
                  <Row>
                    <ProfileDetailsLeft lg={7}>
                      <RightContentSection>
                        {availability.filter((a) => a.enabled).length > 0 &&
                          !isProfileOwner &&
                          data.type === "MENTEE" && (
                            <FloatingContainer>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    borderBottomStyle: "solid",
                                  }}
                                >
                                  <h6 className='m-0'>
                                    <i>Schedule Session 1:1 Availability</i>
                                  </h6>
                                  <div
                                    style={{
                                      marginLeft: 10,
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {availability
                                      .filter((a) => a.enabled)
                                      .map((dayAvailability) => (
                                        <Button
                                          style={{ margin: "5px" }}
                                          onClick={() =>
                                            toggleSelectedAvailabilityDay(
                                              dayAvailability
                                            )
                                          }
                                          variant={
                                            JSON.stringify(
                                              selectedAvailabilityDay
                                            ) ===
                                            JSON.stringify(dayAvailability)
                                              ? "primary"
                                              : "outline-primary"
                                          }
                                        >
                                          {
                                            GetDayOfWeekDisplayName(
                                              dayAvailability
                                            ).display_name
                                          }
                                        </Button>
                                      ))}
                                  </div>
                                </div>
                                {selectedAvailabilityDay ? (
                                  <div
                                    style={{
                                      padding: 20,
                                      backgroundColor: "#eee",
                                    }}
                                  >
                                    <AvailableTimeRow
                                      availability={selectedAvailabilityDay}
                                      mentee_id={data.id}
                                      mentor_id={currentUserProfileData.id}
                                    />
                                  </div>
                                ) : (
                                  <div style={{}}>
                                    {availability
                                      .filter((a) => a.enabled)
                                      .map((dayAvailability) => (
                                        <AvailableDayRow
                                          availability={dayAvailability}
                                          toggleAvailabilityDay={
                                            toggleSelectedAvailabilityDay
                                          }
                                        />
                                      ))}
                                  </div>
                                )}
                              </div>
                            </FloatingContainer>
                          )}
                        <FloatingContainer>
                          <h5>
                            <i>Interests</i>
                          </h5>
                          <div
                            style={{
                              padding: 20,
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {currentUserInterests.length > 0 ? (
                              currentUserInterests.map((interest) => {
                                return (
                                  <div
                                    style={{
                                      margin: "5px 20px 5px 0px",
                                      minWidth: 100,
                                      padding: 5,
                                      backgroundColor: "#eee",
                                      color: "#0077b5",
                                      borderColor: "rgba(55, 133, 191, 0.94)",
                                      borderStyle: "solid",
                                      borderRadius: 15,
                                      cursor: "pointer",
                                      borderWidth: 1,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <h6 style={{ margin: 0 }}>
                                      {interest.name}
                                    </h6>
                                  </div>
                                );
                              })
                            ) : (
                              <p>Nothing to see here...</p>
                            )}
                          </div>
                        </FloatingContainer>
                        <FloatingContainer>
                          <h5>
                            <i>Reviews</i>
                          </h5>
                          <div style={{ padding: 20 }}>
                            <p>
                              <i>
                                This user does not have any reviews at this
                                time...
                              </i>
                            </p>
                          </div>
                        </FloatingContainer>
                      </RightContentSection>
                    </ProfileDetailsLeft>
                    <Col lg={5}>
                      <RightContentSection>
                        {/* {availability.filter((a) => a.enabled).length > 0 && (
                          <div style={{ margin: "40px 0px" }}>
                            <h5>
                              <i>Availability</i>
                            </h5>
                            <div
                              style={{ padding: 20, backgroundColor: "#eee" }}
                            >
                              <p>
                                <AvailableDayCardContainer
                                  availability={availability}
                                />
                              </p>
                            </div>
                          </div>
                        )} */}
                        <LeftContentSection>
                          <FloatingContainer>
                            <div style={{ padding: 15 }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className='mr-1'
                                  style={{ fontSize: "1.3em" }}
                                >
                                  <CardIconContainer className='mr-2'>
                                    <FontAwesomeIcon
                                      icon={faHandshake}
                                      color='#1170b8'
                                      style={{ fontSize: 38 }}
                                    />
                                  </CardIconContainer>
                                </div>
                                <h5
                                  className='m-0'
                                  style={{
                                    borderColor: "rgb(0, 119, 181)",
                                    color: "rgb(0, 119, 181)",
                                    padding: 5,
                                    borderRadius: 9,
                                    borderWidth: 1,
                                    fontSize: "1.5em",
                                    borderStyle: "solid",
                                    backgroundColor: "white",
                                  }}
                                >
                                  {
                                    currentUserProfileConnectionsData.filter(
                                      (connection) =>
                                        connection.status === "ACCEPTED"
                                    ).length
                                  }{" "}
                                  Network Connections
                                </h5>
                              </div>
                              <div style={{ padding: 15 }}>
                                <Connections
                                  connections={
                                    currentUserProfileConnectionsData
                                  }
                                  userType={currentUserProfileData.type}
                                  filter='ACCEPTED'
                                  isLoading={
                                    isLoadingCurrentUserProfileConnectionsData
                                  }
                                />
                              </div>
                            </div>
                          </FloatingContainer>
                          {isProfileOwner && (
                            <FloatingContainer>
                              <div style={{ padding: 15 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className='mr-1'
                                    style={{ fontSize: "1.3em" }}
                                  >
                                    <CardIconContainer className='mr-2'>
                                      <FontAwesomeIcon
                                        icon={faUsers}
                                        color='#1170b8'
                                      />
                                    </CardIconContainer>
                                  </div>
                                  <h5
                                    className='m-0'
                                    style={{
                                      borderColor: "rgb(0, 119, 181)",
                                      color: "rgb(0, 119, 181)",
                                      padding: 5,
                                      borderRadius: 9,
                                      borderWidth: 1,
                                      fontSize: "1.5em",
                                      borderStyle: "solid",
                                      backgroundColor: "white",
                                    }}
                                  >
                                    {
                                      currentUserProfileConnectionsData.filter(
                                        (connection) =>
                                          connection.status === "REQUESTED"
                                      ).length
                                    }{" "}
                                    Connection Requests
                                  </h5>
                                </div>
                                <div style={{ padding: 15 }}>
                                  <Connections
                                    connections={
                                      currentUserProfileConnectionsData
                                    }
                                    userType={currentUserProfileData.type}
                                    filter='REQUESTED'
                                    isLoading={
                                      isLoadingCurrentUserProfileConnectionsData
                                    }
                                  />
                                </div>
                              </div>
                            </FloatingContainer>
                          )}
                        </LeftContentSection>

                        {/* <div style={{ margin: "40px 0px" }}>
                          <h5>
                            <i>Resume</i>
                          </h5>
                          <div style={{ backgroundColor: "#eee", padding: 20 }}>
                            {currentUserProfileData?.resume_link ? (
                              <PDFViewer
                                id={currentUserProfileData.id}
                                fileLink={currentUserProfileData.resume_link}
                              />
                            ) : (
                              <p>No resume found</p>
                            )}
                          </div>
                        </div> */}
                        <FloatingContainer>
                          <ActionButton
                            handleConnectionRequest={handleConnectionRequest}
                            handleDisconnectRequest={handleDisconnectRequest}
                            handlePatchConnectionRequest={
                              handlePatchConnectionRequest
                            }
                            isProfileOwner={isProfileOwner}
                            isLoading={isLoadingConnection}
                            loggedInUserData={data}
                            connectionData={currentUserProfileConnectionData}
                            userProfileData={currentUserProfileData}
                            isEditing={isEditingAccount}
                            toggleIsEditing={() => {
                              setIsEditingAccount(!isEditingAccount);
                            }}
                          />
                        </FloatingContainer>

                        {data.isAdmin && (
                          <FloatingContainer>
                            <div>
                              <Button
                                style={{ margin: "0px 5px" }}
                                variant='light'
                                onClick={() => {
                                  setShowModal(true);
                                }}
                              >
                                Push Admin Email
                              </Button>
                              <PushMessageModal
                                showModal={showModal}
                                handleClose={() => {
                                  setShowModal(false);
                                }}
                                userEmail={currentUserProfileData.email}
                              />
                            </div>
                          </FloatingContainer>
                        )}
                      </RightContentSection>
                    </Col>
                  </Row>
                </Col>
              </Row3>
            )}
          </>
        )}
        <ResumeModal
          showModal={showResumeModal}
          handleClose={() => setShowResumeModal(false)}
          user={currentUserProfileData}
        />
        <ReplaceProfileImageModal
          showModal={showImageModal}
          handleClose={() => setShowImageModal(false)}
          user={data}
          setImage={async (image) => {
            await deleteCookie("cache-images");
            const newProfileData = {
              ...currentUserProfileData,
              profile_image: image,
            } as unknown as UserData;

            setCurrentUserProfileData(newProfileData);
          }}
        />
      </PageContainer>
    </>
  );
}

const PageContainer = styled(Container)`
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: #006B1;
  background-image: url(${process.env.PUBLIC_URL}/Site_Backdrop.png);
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  min-height: 100vh;
`;

const ImageCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ImageContainer = styled("div")`
  height: 250px;
  width: 250px;
  background-color: #eee;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border-style: solid;
  border-color: black;
  border-width: 3px;
  @media (max-width: 858px) {
    justify-content: center;
    margin-left: 0px !important;
  }
`;

const SmallImageContainer = styled("div")`
  height: 75px;
  width: 75px;
  background-color: black;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-style: solid;
  border-color: black;
  border-width: 1px;
`;

const SmallProfileImg = styled("img")`
  min-height: 85px;
  min-width: 75px;
  object-fit: cover;
`;

const RightContentContainer = styled("div")`
  margin: 20px 25px;
`;
const RightContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const ImageContainerContainer = styled(Col)`
  display: flex;
  justify-content: center;
  @media (max-width: 868px) {
    display: flex;
    justify-content: center;
  }
`;

const ProfileDetailsLeft = styled(Col)`
  border-right-style: solid;
  border-color: #111;
  border-width: 1px;
  @media (max-width: 768px) {
    border-right-style: none;
  }
`;

const UserMetricsRow = styled(Row)`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;
const ProfileImg = styled("img")`
  height: 100%;
  min-height: 275px;
  min-width: 249px;
  object-fit: cover;
`;

const LeftContentSection = styled("div")`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightContentSection = styled("div")`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const EditImageButtonContainer = styled("div")`
  width: 250px;
`;

const Row1 = styled(Row)`
  border-radius: 9px;
  margin: 10px 20%;
  padding: 20px 0px;
  @media (max-width: 858px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

const Row2 = styled(Row)`
  background-color: rgb(238 238 238 / 92%);
  border-radius: 9px;
  margin: 10px 20%;
  padding: 20px 0px;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
  @media (max-width: 858px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

const Row3 = styled(Row)`
  border-radius: 9px;
  margin: 10px 19.3%;
  padding: 20px 0px;
  @media (max-width: 858px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

const EditAccountRow = styled(Row)`
  border-radius: 9px;
  margin: 50px 20%;
  padding: 50px 100px;
  background-color: #eee;

  background-color: #eee;
  padding: 10px;
  border-radius: 9px;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 93%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
  @media (max-width: 858px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

const FloatingContainer = styled("div")`
  margin: 40px 0px;
  background-color: #eee;
  padding: 10px;
  border-radius: 9px;
  border-color: rgba(55, 133, 191, 0.94);
  border-width: 2px;
  border-radius: 9px;
  background-color: rgb(238 238 238 / 93%);
  color: black;
  box-shadow: -1px 2px 2px 2px #888;
  -webkit-box-shadow: -1px 2px 2px 2px #888;
  -moz-box-shadow: -1px 2px 3px 3px #888;
`;

const CardIconContainer = styled("div")`
  background-color: rgb(163 228 255);
  display: flex;
  align-items: center;
  border-radius: 360px;
  height: 63px;
  width: 63px;
  justify-content: center;
  margin-right: 20px;
`;

const TimeChoice = styled("div")`
  margin: 5px 0px 5px 0px;
  padding: 10px;
  background-color: #fff;
  color: rgba(55, 133, 191, 0.94);
  cursor: pointer;
  width: 100px;
  &:hover {
    color: #fff;
    background-color: rgba(55, 133, 191, 0.94);
  }
`;

const CropContainer = styled("div")`
  width: 350px;
  height: 350px;
  display: flex;
  align-items: center;
`;
