/**
 * Connections Requests Table.
 *
 * created by Ty D'Angelo 10/19/23
 */

import { Col, Container, Row, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import DeleteConnectionButton from "../ui/button/DeleteConnectionButton";
import ConnectionStatusButton from "../ui/button/ConnectionStatusButton";
import { getUserNonVerified } from "../../apis/Verified";
import { PushMessage } from "./PushMessage";

enum UserType {
  MENTOR = "MENTOR",
  MENTEE = "MENTEE",
}

interface ConnectionRequestTableProps {
  userId?: number;
  userType?: UserType;
  isAdmin?: boolean;
}

interface Connection {
  id: string;
  mentor_id: string;
  mentee_id: string;
  created_date: string;
  last_edited_date: string;
  status: string;
  mentee_first_name: string;
  mentee_last_name: string;
  mentee_email: string;
  mentee_profile_image: string;
  mentor_first_name: string;
  mentor_last_name: string;
  mentor_email: string;
  mentor_profile_image: string;
}

export default function VerifiedList(p: ConnectionRequestTableProps) {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchConnectionsForUser = async (id: number | undefined) => {
    setIsLoading(true);
    try {
      const connectionData = await getUserNonVerified();
      setConnections(connectionData);
      setIsLoading(false);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };
  const reFetchConnections = (connectionId: string) => {
    const updatedConnections = connections.filter(
      (connection) => connection.id !== connectionId
    );
    setConnections(updatedConnections);
  };

  useEffect(() => {
    if ((!isLoading && p.userId) || (!isLoading && p.isAdmin))
      handleFetchConnectionsForUser(p.userId);
  }, [p.userId]);

  const mentorUsers = connections.filter(
    (connection) => connection?.type === "MENTOR"
  );
  const menteeUsers = connections.filter(
    (connection) => connection?.type === "MENTEE"
  );
  const deniedConnections = connections.filter(
    (connection) => connection?.status === "DENIED"
  );

  const [value, setValue] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDropdownChange = (event) => {
    setValue(event.target.value);

    if (event.target.value === "Date") {
      const sortedData = [...connections].sort((a, b) => {
        return sortAscending
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      });
      setConnections(sortedData);
    }
  };

  const displayName = (connection: Connection) => {
    if (p.isAdmin) {
      return (
        <>
          <div style={{ margin: 5 }}>
            <span
              style={{
                backgroundColor: "#eee",
                padding: "3px 5px",
                marginRight: 10,
                fontWeight: 900,
                fontSize: ".8rem",
                cursor: "pointer",
              }}
            >
              <a
                target='_blank'
                style={{ textDecoration: "none", color: "red" }}
                href={`/profile/${connection.mentor_id}`}
              >
                MENTOR
              </a>
            </span>
            {connection?.mentor_first_name + " " + connection?.mentor_last_name}
          </div>
          <div style={{ margin: 5 }}>
            <span
              style={{
                backgroundColor: "#eee",
                padding: "3px 5px",
                marginRight: 10,
                fontWeight: 900,
                fontSize: ".8rem",
                cursor: "pointer",
              }}
            >
              <a
                target='_blank'
                style={{ textDecoration: "none", color: "green" }}
                href={`/profile/${connection.mentee_id}`}
              >
                MENTEE
              </a>
            </span>
            {connection?.mentee_first_name + " " + connection?.mentee_last_name}
          </div>
        </>
      );
    }
    if (p.userType === UserType.MENTOR) {
      return connection?.mentee_first_name + " " + connection?.mentee_last_name;
    } else {
      return connection?.mentor_first_name + " " + connection?.mentor_last_name;
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Container fluid>
        <div>
          <div style={{ marginBottom: 20 }}>
            <h3>Totals</h3>
            <h6>
              ({menteeUsers.length + mentorUsers.length}) Users who have signed
              up but have not completed verification
            </h6>
          </div>
          <div>
            <h5>
              {mentorUsers.length} Mentors have not completed verification
            </h5>
          </div>
          <div>
            <h5>
              {menteeUsers.length} Students have not completed verification
            </h5>
          </div>
        </div>
        <div style={{ marginTop: 50 }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label='Mentors' />
            <Tab label='Students' />
            <Tab label='Push Message' />
          </Tabs>
          {selectedTab === 0 && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={nameColumnStyle}>
                      Mentor / Mentee
                    </TableCell>

                    <TableCell style={emailColumnStyle}>Email</TableCell>
                    <TableCell style={emailColumnStyle}>Signed Up</TableCell>
                    <TableCell style={sortColumnTitleStyle}>
                      <span>Sort: </span>
                      <select
                        style={selectStyle}
                        value={value}
                        onChange={handleDropdownChange}
                      >
                        <option value=''></option>
                        <option value='Date'>Date</option>
                      </select>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mentorUsers.map((connection, index) => (
                    <TableRow key={index}>
                      <TableCell style={nameColumnStyle}>
                        {`${connection.first_name} ${connection.last_name}`}

                        <div style={{ margin: 5 }}>
                          <span
                            style={{
                              backgroundColor: "#eee",
                              padding: "3px 5px",
                              marginRight: 10,
                              fontWeight: 900,
                              fontSize: ".8rem",
                              cursor: "pointer",
                            }}
                          >
                            <a
                              target='_blank'
                              style={{
                                textDecoration: "none",
                                color:
                                  connection.type === "MENTEE"
                                    ? "green"
                                    : "red",
                              }}
                              href={`/profile/${connection.mentor_id}`}
                            >
                              {connection.type}
                            </a>
                          </span>
                        </div>
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>{connection?.email}</div>
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>
                          {connection?.date_of_sign_up
                            ? new Date(
                                connection?.date_of_sign_up
                              ).toLocaleDateString()
                            : "---"}
                        </div>
                      </TableCell>
                      <TableCell style={buttonColumnStyle}>
                        {/* <DeleteConnectionButton
                          removeConnection={reFetchConnections}
                          connectionId={connection?.id}
                        /> */}
                      </TableCell>
                      <TableCell style={editColumnStyle}></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          {selectedTab === 1 && (
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={nameColumnStyle}>Mentee</TableCell>

                    <TableCell style={emailColumnStyle}>Email</TableCell>
                    <TableCell style={emailColumnStyle}>Signed Up</TableCell>
                    <TableCell style={sortColumnTitleStyle}>
                      <span>Sort: </span>
                      <select
                        style={selectStyle}
                        value={value}
                        onChange={handleDropdownChange}
                      >
                        <option value=''></option>
                        <option value='Date'>Date</option>
                      </select>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {menteeUsers.map((connection, index) => (
                    <TableRow key={index}>
                      <TableCell style={nameColumnStyle}>
                        {`${connection.first_name} ${connection.last_name}`}

                        <div style={{ margin: 5 }}>
                          <span
                            style={{
                              backgroundColor: "#eee",
                              padding: "3px 5px",
                              marginRight: 10,
                              fontWeight: 900,
                              fontSize: ".8rem",
                              cursor: "pointer",
                            }}
                          >
                            <a
                              target='_blank'
                              style={{
                                textDecoration: "none",
                                color:
                                  connection.type === "MENTEE"
                                    ? "green"
                                    : "red",
                              }}
                              href={`/profile/${connection.mentor_id}`}
                            >
                              {connection.type}
                            </a>
                          </span>
                        </div>
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>{connection?.email}</div>
                      </TableCell>
                      <TableCell style={emailColumnStyle}>
                        <div>
                          {connection?.date_of_sign_up
                            ? new Date(
                                connection?.date_of_sign_up
                              ).toLocaleDateString()
                            : "---"}
                        </div>
                      </TableCell>
                      <TableCell style={buttonColumnStyle}>
                        {/* <DeleteConnectionButton
                          removeConnection={reFetchConnections}
                          connectionId={connection?.id}
                        /> */}
                      </TableCell>
                      <TableCell style={editColumnStyle}></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          {selectedTab === 2 && <PushMessage />}
        </div>
      </Container>
    </>
  );
}

// Styled Components
const columnStyle = {
  padding: "20px", // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const nameColumnStyle = {
  color: "grey",
  paddingRight: "50px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const dateColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const emailColumnStyle = {
  paddingRight: "120px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const buttonColumnStyle = {
  paddingRight: "90px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const editColumnStyle = {
  paddingLeft: "75px",
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnStyle = {
  // padding: '20px', // You can adjust the padding as needed
  // border: '1px solid #ccc', // Optional border for visual clarity
};

const sortColumnTitleStyle = {
  paddingLeft: "60px",
  // border: '1px solid #ccc', // Optional border for visual clarity
  fontSize: "12px",
};

const selectStyle = {
  border: "none",
  padding: "0px",
  width: "50px",
  color: "blue",
};
