import React, { memo, useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { usStates, getStateIds, stateCentroids } from "./statesUtil";
import axios from "axios";
import { Title } from "../BaseComponents";
import { ConnectionModal } from "../modal/ConnectionModal";

export interface ConnectionByState {
  id: number;
  mentor_id: number;
  mentee_id: number;
  status: string;
  state: string;
}

function ConnectionsByStateMap(p: { viewOnly?: boolean }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ConnectionByState[]>([]);
  const [hoveredState, setHoveredState] = useState(0);
  const [popoverState, setPopoverState] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedConnectionsInState, setSelectedConnectionsInState] = useState<
    ConnectionByState[]
  >([]);
  const [modalShow, setModalShow] = useState(false);
  const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

  const handleFetchConnectionsByState = async () => {
    try {
      setIsLoading(true);
      const { data, headers } = await axios(
        "https://mentor-portal-backend.azurewebsites.net/api/connection",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);

      setData(data);
    } catch (err) {
      let message;
      if (err instanceof Error) {
        message = err.message;
        console.log(err);
        setIsLoading(false);
      } else {
        message = String(err);
        console.log(err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    handleFetchConnectionsByState();
  }, []);

  const countConnectionsByState = (data) => {
    return data.reduce((acc, connection) => {
      const state = connection.state;
      if (!acc[state]) {
        acc[state] = 0;
      }
      acc[state]++;
      return acc;
    }, {});
  };

  const stateConnectionCounts = countConnectionsByState(data);
  const stateIds = getStateIds(data, usStates);

  // Unique set of state IDs
  const uniqueStateIds = [...new Set(stateIds)];

  const handleMarkerClick = (geo) => {
    setSelectedState(geo.properties.name);
    const connectionsInState = data?.filter(
      (connection) => connection.state === geo.properties.name
    );
    setPopoverState(null); // Hide the popover
    setSelectedConnectionsInState(connectionsInState);
    setModalShow(true);
  };

  if (isLoading) {
    return <Spinner animation='border' />;
  }

  return (
    <>
      <ComposableMap projection='geoAlbersUsa' data-tip=''>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = uniqueStateIds.includes(geo.id);

              return (
                <>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: isHighlighted ? "rgb(0, 119, 181)" : "#D6D6DA",
                        outline: "none",
                        stroke: "#AEB7B3",
                      },
                      hover: {
                        fill: "rgb(0, 119, 181)",
                        outline: "none",
                        stroke: "#AEB7B3",
                      },
                      pressed: {
                        fill: "rgb(0, 119, 181)",
                        outline: "none",
                        stroke: "#AEB7B3",
                      },
                    }}
                    data-for='map-tooltip'
                  />
                  {isHighlighted && stateCentroids[geo.id] && (
                    <Marker
                      coordinates={stateCentroids[geo.id]}
                      // onMouseEnter={() => setHoveredState(geo.id)}
                      // onMouseLeave={() => setHoveredState(0)}
                    >
                      <OverlayTrigger
                        trigger={["click"]}
                        placement='top'
                        onToggle={() => {
                          setPopoverState(geo.id);
                        }}
                        show={popoverState === geo.id && !modalShow}
                        overlay={
                          <Popover
                            id='popover-trigger-click-root-close'
                            title={geo.properties.name}
                          >
                            <Title
                              style={{
                                margin: 0,
                                padding: ".25em",
                                backgroundColor: "rgb(163, 228, 255)",
                                color: "rgb(0, 119, 181)",
                              }}
                            >
                              <h6 style={{ margin: ".2em" }}>
                                {geo.properties.name}
                              </h6>
                            </Title>
                            <div style={{ padding: "1em" }}>
                              {p.viewOnly ? (
                                <span>
                                  {stateConnectionCounts[geo.properties.name] ||
                                    0}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    color: "rgb(0, 119, 181)",
                                    fontSize: "bold",
                                    textDecorationLine: "underline",
                                    textDecorationColor: "rgb(0, 119, 181)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleMarkerClick(geo);
                                  }}
                                >
                                  {stateConnectionCounts[geo.properties.name] ||
                                    0}
                                </span>
                              )}{" "}
                              connections
                            </div>
                          </Popover>
                        }
                      >
                        <circle
                          r={3}
                          fill='red'
                          style={{ cursor: "pointer" }}
                        />
                      </OverlayTrigger>
                    </Marker>
                  )}
                </>
              );
            })
          }
        </Geographies>
      </ComposableMap>
      {selectedState && (
        <ConnectionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          title={`${selectedState} Connections`}
          connectionsInState={selectedConnectionsInState}
        />
      )}
    </>
  );
}

export default memo(ConnectionsByStateMap);
