import { AvailabilityState } from "./interfaces";

export const DAYS_OF_WEEK = [
  { name: "Mon", value: 1, display_name: "Monday" },
  { name: "Tues", value: 2, display_name: "Tuesday" },
  { name: "Wed", value: 3, display_name: "Wednesday" },
  { name: "Thurs", value: 4, display_name: "Thursday" },
  { name: "Fri", value: 5, display_name: "Friday" },
  { name: "Sat", value: 6, display_name: "Saturday" },
  { name: "Sun", value: 0, display_name: "Sunday" },
];

export const INIT_AVAILABILITY_STATE: AvailabilityState = {
  1: { start_time: "", end_time: "", enabled: false },
  2: { start_time: "", end_time: "", enabled: false },
  3: { start_time: "", end_time: "", enabled: false },
  4: { start_time: "", end_time: "", enabled: false },
  5: { start_time: "", end_time: "", enabled: false },
  6: { start_time: "", end_time: "", enabled: false },
  0: { start_time: "", end_time: "", enabled: false },
};
