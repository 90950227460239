import React, { ChangeEvent, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { SpaceBetweenContainer } from "../../ui/BaseComponents";
import { SchoolFormProps } from "./interfaces";
import TabButtonBack from "../button/TabButtonBack";
import { Button } from "react-bootstrap";
import { FileUploader } from "../../Common/FileUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { getCookie } from "../../utils/cookies";
import {
  getDirivedUserType,
  UserTypeButton,
  UserTypeButtonLong,
} from "./SignUpForm";
import {
  faGraduationCap,
  faSchool,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const menteeClassificationOptions = [
  "Freshman",
  "Sophomore",
  "Junior",
  "Senior",
  "Graduate",
];

const mentorClassificationOptions = ["Graduate", "Mentor"];

const ProfessionalMentorOptions = [
  "Alumni",
  "Subject Matter Expert",
  "Faculty and Staff",
];

const StudentMentorOptions = ["Junior", "Senior", "Graduate"];

// const menteeClassificationOptions = [
//   "Freshman",
//   "Sophomore",
//   "Junior",
//   "Senior",
//   "Graduate",
// ];

// const mentorClassificationOptions = ["Graduate", "Mentor"];

export default function SchoolForm(p: SchoolFormProps) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileFormData, setSelectedFileFormData] = useState(null);

  const [showSMESubject, setShowSMESubject] = useState(false);
  const [bio, setBio] = useState(p.data.bio);
  const [blurb, setBlurb] = useState(p.data.blurb);
  const [classification, setClassification] = useState(p.data.classification);
  const [major, setMajor] = useState(p.data.major);
  const [entrepreneur, setEntrepreneur] = useState(p.data.entrepreneur);
  const [subjectExpert, setSubjectExpert] = useState(p.data.subject_expert);
  const [subject, setSubject] = useState(p.data.subject);
  const [adultLearner, setAdultLearner] = useState(p.data.adult_learner);
  const [military, setMilitary] = useState(p.data.military);
  const [alumni, setAlumni] = useState(p.data.alumni);
  const [signUpType, setSignUpType] = useState("");
  const [firstGeneration, setFirstGeneration] = useState(
    p.data.first_generation
  );
  const [stem, setStem] = useState(p.data.stem);
  const [linkedinUrl, setLinkedinUrl] = useState(p.data.linkedin_url);

  useEffect(() => {
    if (selectedFile && selectedFileFormData) {
      p.setResumeFile(selectedFileFormData);
    }
  }, [selectedFile, selectedFileFormData]);

  const handleBlurbChange = (e) => {
    setBlurb(e.target.value);
    p.handleChange(e);
  };
  const handleBioChange = (e) => {
    setBio(e.target.value);
    p.handleChange(e);
  };

  const isMissingBlurb = () => {
    return blurb === "" || !blurb || blurb?.length < 50 || blurb?.length > 150;
  };

  const validateForm = () => {
    //basic check

    if (signUpType === null) {
      alert("Please Complete All Fields");
      return;
    }

    if (signUpType === "Professional Mentor") {
      if (alumni === null) {
        alert("Please Complete All Fields");
        return;
      }
    } else {
      if (classification === null || major === null) {
        alert("Please Complete All Fields");
        return;
      }
    }

    if (getDirivedUserType(signUpType) === "MENTOR" && isMissingBlurb()) {
      alert("Please Complete All Fields");
      return;
    }

    if (
      bio === "" ||
      !bio ||
      bio?.length < 50 ||
      bio?.length > 500 ||
      adultLearner === null ||
      military === null ||
      firstGeneration === null ||
      entrepreneur === null ||
      subjectExpert === null ||
      stem === null
    ) {
      alert("Please Complete All Fields");

      return;
    }
    // mentor check
    if (subjectExpert === true && subject === null) {
      alert("Please Complete All Fields");
      return;
    }
    p.setUserType(getDirivedUserType(signUpType));

    p.setKey("consent");
  };

  const handleSignupType = async () => {
    try {
      const data = await getCookie("signup-type");
      setSignUpType(data);
    } catch (e) {}
  };

  const getClassificationOptions = () => {
    let options: any = [];
    if (!signUpType) {
      options =
        p.data.type === "MENTOR"
          ? mentorClassificationOptions.map((classif) => (
              <option value={classif}>{classif}</option>
            ))
          : menteeClassificationOptions.map((classif) => (
              <option value={classif}>{classif}</option>
            ));
    }

    if (signUpType === "Professional Mentor") {
      options = ProfessionalMentorOptions.map((classif) => (
        <option value={classif}>{classif}</option>
      ));
    }

    if (signUpType === "Student Mentor") {
      options = StudentMentorOptions.map((classif) => (
        <option value={classif}>{classif}</option>
      ));
    }

    if (signUpType === "Collegiate Student looking for mentorship") {
      options = menteeClassificationOptions.map((classif) => (
        <option value={classif}>{classif}</option>
      ));
    }
    options.unshift(
      <option disabled selected>
        Choose...
      </option>
    );
    return options;
  };

  const doShowMajor = () => {
    return (
      (signUpType &&
        (signUpType === "Student Mentor" ||
          signUpType === "Collegiate Student looking for mentorship")) ||
      p.data.type === "MENTEE"
    );
  };

  useEffect(() => {
    handleSignupType();
  }, []);

  const clearStudentFields = () => {
    setClassification(null);
    setMajor(null);
    setAlumni(null);
  };

  useEffect(() => {
    clearStudentFields();
  }, [signUpType]);

  return (
    <>
      <Form>
        <Row>
          <Col lg={12}>
            <Form.Group className='mb-3' controlId='formBasicClassification'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                User Type
              </Form.Label>
              <br />
              <Form.Label
                style={{
                  fontSize: ".8em",
                  fontWeight: "700",
                  lineHeight: "1.5em",
                }}
              >
                Please provide a short elevator pitch describing yourself.
              </Form.Label>
              <div
                style={{
                  marginTop: "1em",
                  display: "flex",
                }}
              >
                <UserTypeButton
                  style={
                    signUpType === "Professional Mentor"
                      ? { backgroundColor: "#e7f0fe" }
                      : {}
                  }
                  onClick={() => {
                    setSignUpType("Professional Mentor");
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faGraduationCap} color='orange' />
                  </div>
                  <div className='ml-2'>
                    <h6 className='mb-0'>Professional Mentor</h6>
                  </div>
                </UserTypeButton>
                <UserTypeButton
                  style={
                    signUpType === "Student Mentor"
                      ? { backgroundColor: "#e7f0fe" }
                      : {}
                  }
                  onClick={() => {
                    setSignUpType("Student Mentor");
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faSchool} color='purple' />
                  </div>
                  <div className='ml-2'>
                    <h6 className='mb-0'>Student Mentor</h6>
                  </div>
                </UserTypeButton>
                <UserTypeButtonLong
                  style={
                    signUpType === "Collegiate Student looking for mentorship"
                      ? { backgroundColor: "#e7f0fe" }
                      : {}
                  }
                  onClick={() => {
                    setSignUpType("Collegiate Student looking for mentorship");
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faUser} color='green' />
                  </div>
                  <div className='ml-2'>
                    <h6 className='mb-0'>
                      Collegiate Student looking for mentorship
                    </h6>
                  </div>
                </UserTypeButtonLong>

                {/* <Form.Group className="mb-3" controlId="formAdultLearner">
          <Form.Label
            style={{
              fontSize: "1em",
              fontWeight: "400",
              lineHeight: "1.5em",
            }}
          >
            Student (Mentee) or Alumni (Mentor)?
          </Form.Label>
          <div style={{ display: "flex", marginLeft: 10 }}>
            <div style={{ marginLeft: 10, marginRight: 50 }}>
              <label htmlFor="yes" style={{ marginRight: 10 }}>
                Mentor
              </label>
              <input
                type="radio"
                id="yes"
                name="type"
                value="MENTOR"
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              />
            </div>
            <div>
              <label htmlFor="no" style={{ marginRight: 10 }}>
                Student
              </label>
              <input
                type="radio"
                id="no"
                name="type"
                value="MENTEE"
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              />
            </div>
          </div>
        </Form.Group> */}
              </div>
            </Form.Group>
          </Col>
        </Row>

        {signUpType !== "Professional Mentor" && (
          <Row>
            <Col lg={6}>
              <Form.Group className='mb-3' controlId='formBasicClassification'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Classification
                </Form.Label>
                <Form.Select
                  style={{ height: "3.5em" }}
                  defaultValue={classification}
                  name='classification'
                  onChange={(e) => {
                    setClassification(e.target.value);
                    p.handleChange(e);
                  }}
                >
                  {getClassificationOptions()}

                  {/* Add more country options as needed */}
                </Form.Select>
              </Form.Group>
            </Col>
            {doShowMajor() && (
              <Col lg={6}>
                <Form.Group className='mb-3' controlId='major'>
                  <Form.Label
                    style={{
                      fontSize: "1em",
                      fontWeight: "400",
                      lineHeight: "1.5em",
                    }}
                  >
                    Major
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Major'
                    name='major'
                    style={{ height: "3.5em" }}
                    defaultValue={major}
                    onChange={(e) => {
                      setMajor(e.target.value);
                      p.handleChange(e);
                    }}
                    disabled={p.isLoading}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
        )}

        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formEntrepreneur'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you an entrepreneur?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_e' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_e'
                    name='entrepreneur'
                    value='true'
                    onChange={(e) => {
                      setEntrepreneur(true);
                      p.handleChange(e);
                    }}
                    checked={entrepreneur === true}
                    disabled={p.isLoading}
                  />
                </div>
                <div>
                  <label htmlFor='no_e' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_e'
                    name='entrepreneur'
                    value='false'
                    onChange={(e) => {
                      setEntrepreneur(false);
                      p.handleChange(e);
                    }}
                    checked={entrepreneur === false}
                    disabled={p.isLoading}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formSubject_expert'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you a subject matter expert?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_s' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_s'
                    name='subject_expert'
                    value='true'
                    onChange={(e) => {
                      setSubjectExpert(true);
                      setShowSMESubject(true);
                      p.handleChange(e);
                    }}
                    checked={subjectExpert === true}
                    disabled={p.isLoading}
                  />
                </div>
                <div>
                  <label htmlFor='no_s' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_s'
                    name='subject_expert'
                    value='false'
                    onChange={(e) => {
                      setSubjectExpert(false);
                      setShowSMESubject(false);
                      p.handleChange(e);
                    }}
                    checked={subjectExpert === false}
                    disabled={p.isLoading}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>

        {showSMESubject && (
          <Row>
            <Col lg={6}>
              <Form.Group className='mb-3' controlId='major'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Below, please provide the subject of expertise
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder=''
                  name='subject'
                  style={{ height: "3.5em" }}
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                    p.handleChange(e);
                  }}
                  disabled={p.isLoading}
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Form.Group className='mb-3' controlId='formAdultLearner'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Adult Learner (are you over the age of 21?)
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_a' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_a'
                    name='adult_learner'
                    value='true'
                    onChange={(e) => {
                      setAdultLearner(true);
                      p.handleChange(e);
                    }}
                    checked={adultLearner === true}
                  />
                </div>
                <div>
                  <label htmlFor='no_a' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_a'
                    name='adult_learner'
                    value='false'
                    onChange={(e) => {
                      setAdultLearner(false);
                      p.handleChange(e);
                    }}
                    checked={adultLearner === false}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-3' controlId='formMilitary'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you Active Military or a Military Dependant?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_m' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_m'
                    name='military'
                    value='true'
                    onChange={(e) => {
                      setMilitary(true);
                      p.handleChange(e);
                    }}
                    checked={military === true}
                  />
                </div>
                <div>
                  <label htmlFor='no_m' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_m'
                    name='military'
                    value='false'
                    onChange={(e) => {
                      setMilitary(false);
                      p.handleChange(e);
                    }}
                    checked={military === false}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Form.Group className='mb-3' controlId='formFirstGeneration'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you a first-generation student?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_f' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_f'
                    name='first_generation'
                    value='true'
                    onChange={(e) => {
                      setFirstGeneration(true);
                      p.handleChange(e);
                    }}
                    checked={firstGeneration === true}
                  />
                </div>
                <div>
                  <label htmlFor='no_f' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_f'
                    name='first_generation'
                    value='false'
                    onChange={(e) => {
                      setFirstGeneration(false);
                      p.handleChange(e);
                    }}
                    checked={firstGeneration === false}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className='mb-3' controlId='formStem'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Are you in any STEM related major or program?
              </Form.Label>
              <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                <div style={{ marginLeft: 10, marginRight: 50 }}>
                  <label htmlFor='yes_stem' style={{ marginRight: 10 }}>
                    Yes
                  </label>
                  <input
                    type='radio'
                    id='yes_stem'
                    name='stem'
                    value='true'
                    onChange={(e) => {
                      setStem(true);
                      p.handleChange(e);
                    }}
                    checked={stem === true}
                  />
                </div>
                <div>
                  <label htmlFor='no_stem' style={{ marginRight: 10 }}>
                    No
                  </label>
                  <input
                    type='radio'
                    id='no_stem'
                    name='stem'
                    value='false'
                    onChange={(e) => {
                      setStem(false);
                      p.handleChange(e);
                    }}
                    checked={stem === false}
                  />
                </div>
              </div>
            </Form.Group>
          </Col>
          {signUpType === "Professional Mentor" && (
            <Col lg={6}>
              <Form.Group className='mb-3' controlId='formFirstGeneration'>
                <Form.Label
                  style={{
                    fontSize: "1em",
                    fontWeight: "400",
                    lineHeight: "1.5em",
                  }}
                >
                  Are you a University Alumni?
                </Form.Label>
                <div style={{ display: "flex", marginLeft: 10, marginTop: 10 }}>
                  <div style={{ marginLeft: 10, marginRight: 50 }}>
                    <label htmlFor='yes_al' style={{ marginRight: 10 }}>
                      Yes
                    </label>
                    <input
                      type='radio'
                      id='yes_al'
                      name='alumni'
                      value='true'
                      onChange={(e) => {
                        setAlumni(true);
                        p.handleChange(e);
                      }}
                      checked={alumni === true}
                    />
                  </div>
                  <div>
                    <label htmlFor='no_al' style={{ marginRight: 10 }}>
                      No
                    </label>
                    <input
                      type='radio'
                      id='no_al'
                      name='alumni'
                      value='false'
                      onChange={(e) => {
                        setAlumni(false);
                        p.handleChange(e);
                      }}
                      checked={alumni === false}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          )}
        </Row>
        {getDirivedUserType(signUpType) === "MENTOR" && (
          <Row>
            <Form.Group className='mb-3' controlId='formBlurb'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Short Blurb
              </Form.Label>
              <br />
              <Form.Label
                style={{
                  fontSize: ".8em",
                  fontWeight: "700",
                  lineHeight: "1.5em",
                }}
              >
                Please provide a short elevator pitch describing yourself.
              </Form.Label>
              <Form.Control
                as='textarea'
                rows={2}
                type='text'
                placeholder=''
                name='blurb'
                value={blurb}
                onChange={handleBlurbChange}
              />
            </Form.Group>
            <p
              style={{
                color:
                  blurb?.length > 50 && blurb?.length < 151 ? "black" : "red",
              }}
            >
              50{"/"}
              {blurb?.length ?? 0}
              {"/"}150
            </p>
          </Row>
        )}
        <Row>
          <Form.Group className='mb-3' controlId='formDetailedBio'>
            <Form.Label
              style={{
                fontSize: "1em",
                fontWeight: "400",
                lineHeight: "1.5em",
              }}
            >
              Bio
            </Form.Label>
            <br />
            <Form.Label
              style={{
                fontSize: ".8em",
                fontWeight: "700",
                lineHeight: "1.5em",
              }}
            >
              Let's get to know you. Provide a detailed message about yourself.
            </Form.Label>
            <Form.Control
              as='textarea'
              rows={6}
              type='text'
              placeholder='bio'
              name='bio'
              value={bio}
              onChange={handleBioChange}
            />
          </Form.Group>
          <p
            style={{
              color: bio?.length > 50 && bio?.length < 500 ? "black" : "red",
            }}
          >
            50{"/"}
            {bio?.length ?? 0}
            {"/"}500
          </p>
        </Row>
        <Row>
          <Col>
            <Form.Group className='mb-5' controlId='formLinked'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                LinkedIn Profile{" "}
                <FontAwesomeIcon color='#0077b5' icon={faLinkedin} />
              </Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                name='linkedin_url'
                style={{ height: "3.5em" }}
                value={linkedinUrl}
                onChange={(e) => {
                  setLinkedinUrl(e.target.value);
                  p.handleChange(e);
                }}
                disabled={p.isLoading}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='mb-5' controlId='formResume'>
              <Form.Label
                style={{
                  fontSize: "1em",
                  fontWeight: "400",
                  lineHeight: "1.5em",
                }}
              >
                Upload Resume
              </Form.Label>
              <FileUploader
                data={p.data}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                setSelectedFileFormData={setSelectedFileFormData}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <SpaceBetweenContainer>
        <TabButtonBack
          height='3.75em'
          width='10em'
          backgroundColor='#FFFFFF'
          color='#2091F9'
          title='Back'
          setKey={p.setKey}
        />
        <Button
          style={{
            width: "10em",
            height: "3.75em",
            backgroundColor: "#2091F9",
          }}
          onClick={() => validateForm()}
        >
          Continue
        </Button>
      </SpaceBetweenContainer>
    </>
  );
}
